import { FC } from 'react'

import { Flex, Square, Tooltip } from '@chakra-ui/react'
import { isTablet } from 'react-device-detect'

const SubToolbarToggle: FC<{
  value: string
  label: string
  onChange: (value: string) => void
  values: {
    icon: React.ReactElement
    value: string
    color: string
  }[]
}> = ({ value, label, onChange, values }) => {
  const findIndex = (currentValue: string) => {
    const selectedIndex = values.findIndex((v) => v.value === currentValue)
    if (selectedIndex < 0) {
      return 0
    }
    return selectedIndex
  }
  const toggle = () => {
    const selectedIndex = findIndex(value)
    const newIndex = selectedIndex + 1
    const index = newIndex >= values.length ? 0 : newIndex
    onChange(values[index].value)
  }

  return (
    <Tooltip hasArrow placement="bottom" label={label} p={2} fontSize="xs" fontWeight="normal" isDisabled={isTablet}>
      <Flex>
        <Square
          backgroundColor={values[findIndex(value)].color}
          color="white"
          fontSize="xl"
          size={isTablet ? 12 : 8}
          onClick={toggle}
          data-testid={`sub-toolbar-toggle-${values[findIndex(value)].value}`}
        >
          {values[findIndex(value)].icon}
        </Square>
      </Flex>
    </Tooltip>
  )
}

export default SubToolbarToggle
