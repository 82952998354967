import { FC, useState } from 'react'

import { IconButton, IconButtonProps, Tooltip } from '@chakra-ui/react'
import { isTablet } from 'react-device-detect'
import { useTranslation } from 'react-i18next'

import { ImportIcon } from 'assets/icons'

import XMLImportModal from './XMLImportModal'

const XMLImportButton: FC<{
  props: IconButtonProps
  position: number
  disabled: boolean
  inspectionSheetId: string
  onConfirm: (result: boolean) => void
}> = ({ props, position, disabled, inspectionSheetId, onConfirm }) => {
  const { t } = useTranslation(['projects'])
  const [isImportModalOpen, setIsImportModalOpen] = useState(false)

  return (
    <>
      <Tooltip
        hasArrow
        placement="right"
        label={t('main_canvas.inspection_sheet.import_xml', { ns: 'projects' })}
        fontSize="xs"
        fontWeight="normal"
        isDisabled={isTablet}
      >
        <IconButton
          {...props}
          position="absolute"
          top={position + 40}
          left={position}
          icon={<ImportIcon />}
          color="white"
          backgroundColor="primary"
          colorScheme="primary"
          disabled={disabled}
          onClick={() => setIsImportModalOpen(true)}
        />
      </Tooltip>
      <XMLImportModal
        isOpen={isImportModalOpen}
        inspectionSheetId={inspectionSheetId}
        onConfirm={(result) => {
          setIsImportModalOpen(false)
          onConfirm(!!result)
        }}
      />
    </>
  )
}

export default XMLImportButton
