import { FC, useEffect, useRef, useState } from 'react'

import {
  Flex,
  PlacementWithLogical,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Square,
  StackDivider,
  Tooltip,
  VStack,
  useDisclosure,
} from '@chakra-ui/react'
import { isTablet } from 'react-device-detect'

import useLongPress from 'hooks/LongPress'

import { Z_INDEX } from 'config/constants'

import { Timeout } from 'interfaces/attribute'

const ToolbarButton: FC<{
  icon: React.ReactElement
  label: string
  testId: string
  toolType: string
  selectedTool: string
  changeTool: (tool: string) => void
  toolTipPlacement?: PlacementWithLogical
  variants?: {
    icon: React.ReactElement
    toolType: string
    label: string
    testId?: string
  }[]
  buttonSize: number
}> = ({ icon, label, testId, toolType, selectedTool, changeTool, toolTipPlacement, variants, buttonSize }) => {
  const buttonRef = useRef<HTMLDivElement | null>(null)
  const { isOpen, onToggle, onClose } = useDisclosure()

  const [selectedVariant, setSelectedVariant] = useState(toolType)
  const [toggleTimeout, setToggleTimeout] = useState<Timeout>()

  useEffect(() => {
    if (variants && variants.some((variant) => variant.toolType === selectedTool)) {
      setSelectedVariant(selectedTool)
    }
  }, [selectedTool, variants])

  const onLongPress = () => {
    onToggle()
  }
  const onClick = (e: React.MouseEvent<HTMLDivElement> | React.TouchEvent<HTMLDivElement>) => {
    e.stopPropagation()
    changeTool(selectedVariant)

    // Clicking also opens the variant popup, which makes user easier interact on iPad
    // However, opening the variant popup in that way only last for 3 seconds
    onToggle()
    const timeout = setTimeout(() => {
      onClose()
    }, 3000)
    if (toggleTimeout) {
      clearTimeout(toggleTimeout)
      setToggleTimeout(undefined)
    }
    setToggleTimeout(timeout)
  }

  const mouseLongPress = useLongPress({ onLongPress, onClick }, { delay: 1200, shouldPreventDefault: true })

  return (
    <>
      <Tooltip
        hasArrow
        placement={toolTipPlacement || 'right'}
        label={label}
        p={2}
        fontSize="xs"
        fontWeight="normal"
        isDisabled={isTablet}
      >
        <Flex userSelect="none">
          <Square
            data-testid={testId}
            ref={buttonRef}
            {...mouseLongPress}
            position="relative"
            backgroundColor={selectedTool === selectedVariant ? 'gray.600' : 'transparent'}
            color="white"
            fontSize="xl"
            size={buttonSize}
          >
            {variants?.find((v) => v.toolType === selectedVariant)?.icon || icon}
            {variants && (
              <Square
                position="absolute"
                bottom={0}
                right={0}
                size={2}
                borderBottomColor="secondary.400"
                borderRightColor="secondary.400"
                borderTopColor="transparent"
                borderLeftColor="transparent"
                borderWidth={4}
              />
            )}
          </Square>
        </Flex>
      </Tooltip>
      {variants && (
        <Portal>
          <Popover returnFocusOnClose={false} isOpen={isOpen} onClose={onClose} placement="right-start" closeOnBlur>
            <PopoverTrigger>
              <Square
                position="absolute"
                size={buttonSize}
                pointerEvents="none"
                backgroundColor="transparent"
                top={(buttonRef.current?.offsetTop || 0) + (isTablet ? 77 : 43)}
                zIndex={Z_INDEX.common.toolbar_button}
              />
            </PopoverTrigger>
            <PopoverContent w={buttonSize} borderWidth={0} backgroundColor="transparent">
              <PopoverBody
                p={0}
                backgroundColor="secondary.800"
                borderColor="secondary.700"
                borderWidth={1}
                overflow="hidden"
                borderRadius="md"
              >
                <VStack spacing={0} divider={<StackDivider borderColor="whiteAlpha.200" />}>
                  {variants.map((variant) => (
                    <Tooltip
                      key={variant.toolType}
                      hasArrow
                      placement="right"
                      label={variant.label}
                      p={2}
                      fontSize="xs"
                      fontWeight="normal"
                      isDisabled={isTablet}
                    >
                      <Square
                        userSelect="none"
                        position="relative"
                        backgroundColor={selectedVariant === variant.toolType ? 'secondary.600' : 'transparent'}
                        color="white"
                        fontSize="xl"
                        size={buttonSize}
                        data-testid={variant.testId}
                        onClick={(e: React.MouseEvent<HTMLDivElement> | React.TouchEvent<HTMLDivElement>) => {
                          e.stopPropagation()
                          setSelectedVariant(variant.toolType)
                          changeTool(variant.toolType)
                          onToggle()
                        }}
                      >
                        {variant.icon}
                      </Square>
                    </Tooltip>
                  ))}
                </VStack>
              </PopoverBody>
              <PopoverArrow
                left={12}
                backgroundColor="secondary.800"
                borderColor="secondary.700"
                boxShadow="-1px 1px 1px 0 var(--chakra-colors-gray-600) !important"
              />
            </PopoverContent>
          </Popover>
        </Portal>
      )}
    </>
  )
}

// Handle typing for props that is not required
ToolbarButton.defaultProps = {
  variants: undefined,
}

export default ToolbarButton
