import { FC, useRef } from 'react'

import { Box } from '@chakra-ui/react'
import { Html } from '@react-three/drei'
import { useFrame } from '@react-three/fiber'
import { BoxGeometry, EdgesGeometry, EllipseCurve, Group, Line, LineSegments, Plane, Quaternion, Vector3 } from 'three'
import { ArcballControls } from 'three-stdlib'

import usePointScale from 'hooks/PointScale'

import {
  DIAMETERS,
  EDITOR_BASE_LINE_THICKNESS,
  EDITOR_MEASURE_KEYS,
  EDITOR_REQUIRED_ANCHORS,
  EDITOR_SHAPE_KEYS,
  Z_INDEX_RANGE,
} from 'config/constants'
import { EDITOR_FRAME_COLORS } from 'config/styles'

import { AnchorPoints, CuboidAnchor, FocusedPoint, MeasureKey, PointArray, ShapeKey } from 'interfaces/interfaces'

import { calculateCenterAndDistance } from 'services/Editor'
import {
  findCircle,
  findMissingVertexParallelogram,
  fixVertexOnNormal,
  getCuboidFromPoints,
  pointsToVector3s,
} from 'services/Points'
import { getDistanceLabel, millimeterToMeter } from 'services/Util'

import PointMesh from './PointMesh'

export const Frame: FC<{
  framePoints: Vector3[]
  color: string
  needFillMissingPoint: boolean
  needClosePoint?: boolean
}> = ({ framePoints, color, needFillMissingPoint, needClosePoint }) => {
  const ref = useRef<Line>()

  if (framePoints.length === 3 && needFillMissingPoint) {
    // add missing vertex and the 0th vertex
    // length: 3 -> 5
    framePoints.push(findMissingVertexParallelogram(framePoints))
    framePoints.push(framePoints[0].clone())
  }

  if (needClosePoint) {
    // add 0th vertex to make a circular edges
    framePoints.push(framePoints[0].clone())
  }

  useFrame(() => {
    if (ref.current) {
      ref.current.geometry.setFromPoints(framePoints)
      ref.current.computeLineDistances()
    }
  })

  return (
    // ref must be any
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-explicit-any
    <line ref={ref as any}>
      <bufferGeometry />
      <lineDashedMaterial
        transparent
        depthTest={false}
        depthWrite={false}
        color={color}
        dashSize={EDITOR_BASE_LINE_THICKNESS}
        gapSize={EDITOR_BASE_LINE_THICKNESS}
      />
    </line>
  )
}

const CircularFrame: FC<{
  framePoints: Vector3[]
  color: string
}> = ({ framePoints, color }) => {
  const ref = useRef<Line>()
  const groupRef = useRef<Group>()
  const [center, radius] = findCircle(framePoints)

  // Create line segments with calculated radius
  const curve = new EllipseCurve(0, 0, radius, radius, 0.0, 2.0 * Math.PI, false, 0)
  const circularPoints = curve.getSpacedPoints(60)

  const planeVector = new Plane()
    .setFromCoplanarPoints(framePoints[0], framePoints[1], framePoints[2])
    .normal.normalize()

  // Rotate the circle along with the plane of picked 3 points
  const newDirection = new Vector3(planeVector.x, planeVector.y, planeVector.z)
  const anchorPosition = new Vector3().addVectors(newDirection, center)
  groupRef.current?.lookAt(anchorPosition)

  useFrame(() => {
    if (ref.current) {
      ref.current.geometry.setFromPoints(circularPoints)
      ref.current.computeLineDistances()
    }
  })

  return (
    <group ref={groupRef} position={center}>
      {/* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-explicit-any */}
      <line ref={ref as any}>
        <bufferGeometry />
        <lineDashedMaterial
          transparent
          depthTest={false}
          depthWrite={false}
          color={color}
          dashSize={EDITOR_BASE_LINE_THICKNESS}
          gapSize={EDITOR_BASE_LINE_THICKNESS}
        />
      </line>
    </group>
  )
}

const CuboidFrame: FC<{
  framePoints: Vector3[]
  color: string
  baseDiameter: number
}> = ({ framePoints, color, baseDiameter }) => {
  const ref = useRef<LineSegments>()

  const fixedPoints = [...fixVertexOnNormal([framePoints[0], framePoints[1], framePoints[2]]), framePoints[3]]
  const { width, depth, height, pose } = getCuboidFromPoints(
    fixedPoints,
    millimeterToMeter(baseDiameter || DIAMETERS.D10)
  )

  const position = new Vector3().setFromMatrixPosition(pose)
  const quaternion = new Quaternion().setFromRotationMatrix(pose)
  // use for drawing bounding box
  const edgesGeometry = new EdgesGeometry(new BoxGeometry(width, height, depth))

  useFrame(() => {
    if (ref.current) {
      ref.current.computeLineDistances()
    }
  })

  return (
    <lineSegments
      // ref must be any
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-explicit-any
      ref={ref as any}
      quaternion={quaternion}
      position={position}
      geometry={edgesGeometry}
    >
      <lineDashedMaterial
        transparent
        depthTest={false}
        depthWrite={false}
        color={color}
        dashSize={EDITOR_BASE_LINE_THICKNESS}
        gapSize={EDITOR_BASE_LINE_THICKNESS}
      />
    </lineSegments>
  )
}

const AnchorFrames: FC<{
  anchors: AnchorPoints[]
  processingAnchor?: PointArray
  selectedPoint?: FocusedPoint
  arcballControls: ArcballControls | null
  shapeKey: ShapeKey | MeasureKey
}> = ({ anchors, processingAnchor, selectedPoint, arcballControls, shapeKey }) => {
  const lastAnchor = anchors.length ? anchors[anchors.length - 1] : null
  const { scale } = usePointScale(arcballControls)
  const getAnchorKey = (anchorIndex: number) => `${shapeKey}-frame-${anchorIndex}`
  const getPointKey = (anchorIndex: number, pointIndex: number) => `${getAnchorKey(anchorIndex)}-${pointIndex}`

  const distanceLabel = (
    center: PointArray | undefined,
    distance: number | undefined,
    focused: boolean,
    isHighlighting?: boolean,
    isInverted?: boolean
  ) => (
    <Html
      position={center || 0}
      style={{ transform: 'translateX(-50%) translateY(-50%)' }}
      zIndexRange={Z_INDEX_RANGE.default}
    >
      <Box
        backgroundColor={focused ? 'yellow' : EDITOR_FRAME_COLORS[shapeKey]}
        px={2}
        fontSize="80%"
        fontWeight="bold"
        color={focused || isInverted ? 'black' : 'white'}
        className={isHighlighting ? 'animation-blinking-primary' : ''}
      >
        {getDistanceLabel(distance || 0)}
      </Box>
    </Html>
  )

  const anchorFrames = anchors.map((anchor, anchorIndex) => {
    if (anchor.invisible || anchor.deleted || !anchor.points?.length) {
      return null
    }

    return (
      <group renderOrder={1} key={getAnchorKey(anchorIndex)}>
        {anchor.points.map((point, pointIndex) => (
          <PointMesh
            key={getPointKey(anchorIndex, pointIndex)}
            point={point}
            scale={scale}
            anchorIndex={anchorIndex}
            pointIndex={pointIndex}
            shapeKey={shapeKey}
          />
        ))}
        {anchor.points.length === 3 && shapeKey === EDITOR_SHAPE_KEYS.TORI && (
          <CircularFrame framePoints={pointsToVector3s(anchor.points)} color={EDITOR_FRAME_COLORS[shapeKey]} />
        )}
        {anchor.points.length >= 2 &&
          shapeKey !== EDITOR_SHAPE_KEYS.TORI &&
          shapeKey !== EDITOR_MEASURE_KEYS.SPACER_ANNOTATION && (
            <Frame
              framePoints={fixVertexOnNormal(pointsToVector3s(anchor.points))}
              color={EDITOR_FRAME_COLORS[shapeKey]}
              needFillMissingPoint={shapeKey === EDITOR_SHAPE_KEYS.PLANES}
            />
          )}
        {anchor.points.length >= 2 &&
          shapeKey !== EDITOR_SHAPE_KEYS.TORI &&
          shapeKey === EDITOR_MEASURE_KEYS.SPACER_ANNOTATION && (
            <Frame
              framePoints={pointsToVector3s(anchor.points)}
              color={EDITOR_FRAME_COLORS[shapeKey]}
              needFillMissingPoint={false}
              needClosePoint={anchor.points.length === EDITOR_REQUIRED_ANCHORS.spacerAnnotation}
            />
          )}
        {(Object.values(EDITOR_MEASURE_KEYS) as string[]).includes(shapeKey) &&
          anchor.center &&
          anchor.points.length === 2 &&
          distanceLabel(
            anchor.center,
            anchor.distance,
            selectedPoint?.anchorIndex === anchorIndex && selectedPoint?.shapeKey === shapeKey,
            anchor.isHighlighting
          )}
        {(Object.values(EDITOR_MEASURE_KEYS) as string[]).includes(shapeKey) &&
          anchor.centers?.length &&
          anchor.centers.length === anchor.distances?.length &&
          anchor.centers.map((center, index) =>
            distanceLabel(
              center,
              anchor.distances?.[index],
              selectedPoint?.anchorIndex === anchorIndex && selectedPoint?.shapeKey === shapeKey,
              anchor.isHighlighting,
              true
            )
          )}
      </group>
    )
  })

  const liveDistanceFrame = () => {
    if (shapeKey !== EDITOR_MEASURE_KEYS.DISTANCE || !processingAnchor || lastAnchor?.points?.length !== 1) {
      return null
    }
    const distanceFactors = calculateCenterAndDistance({
      ...lastAnchor,
      points: [...lastAnchor.points, processingAnchor],
    })
    return distanceLabel(distanceFactors?.[0], distanceFactors?.[1], true)
  }

  return (
    <>
      {anchorFrames}
      {processingAnchor && lastAnchor?.points.length === 2 && shapeKey === EDITOR_SHAPE_KEYS.TORI && (
        <CircularFrame
          framePoints={pointsToVector3s([...lastAnchor.points, processingAnchor])}
          color={EDITOR_FRAME_COLORS[shapeKey]}
        />
      )}
      {processingAnchor && lastAnchor?.points?.length && (
        <Frame
          framePoints={fixVertexOnNormal(pointsToVector3s([...lastAnchor.points, processingAnchor]))}
          color={EDITOR_FRAME_COLORS[shapeKey]}
          needFillMissingPoint={shapeKey === EDITOR_SHAPE_KEYS.PLANES}
        />
      )}
      {liveDistanceFrame()}
    </>
  )
}

// Handle typing for props that is not required
AnchorFrames.defaultProps = {
  processingAnchor: undefined,
  selectedPoint: undefined,
}

export const CuboidAnchorFrames: FC<{
  anchor?: CuboidAnchor
  processingAnchor?: PointArray
  arcballControls: ArcballControls | null
  shapeKey: ShapeKey
  baseDiameter: number
  frameColor?: string
}> = ({ anchor, processingAnchor, arcballControls, shapeKey, baseDiameter, frameColor }) => {
  const { scale } = usePointScale(arcballControls)
  const getAnchorKey = () => `${shapeKey}-cuboid-frame`
  const getPointKey = (pointIndex: number) => `${getAnchorKey()}-${pointIndex}`

  if (!anchor) {
    return null
  }

  const points = [...(anchor?.points.map((a) => a.toArray()) || [])]

  if (processingAnchor) {
    points.push(processingAnchor)
  }

  if (!points.length) {
    return null
  }

  return (
    <group renderOrder={1} key={getAnchorKey()}>
      {points.map((point, pointIndex) => (
        <PointMesh
          key={getPointKey(pointIndex)}
          point={point}
          scale={scale}
          anchorIndex={0}
          pointIndex={pointIndex}
          shapeKey={shapeKey}
        />
      ))}
      {points.length <= 3 && (
        <Frame
          framePoints={fixVertexOnNormal(pointsToVector3s(points))}
          color={EDITOR_FRAME_COLORS[shapeKey] || frameColor || 'white'}
          needFillMissingPoint
        />
      )}
      {points.length === EDITOR_REQUIRED_ANCHORS.cuboid && (
        <CuboidFrame
          framePoints={pointsToVector3s(points)}
          color={EDITOR_FRAME_COLORS[shapeKey] || frameColor || 'white'}
          baseDiameter={baseDiameter}
        />
      )}
    </group>
  )
}

// Handle typing for props that is not required
CuboidAnchorFrames.defaultProps = {
  anchor: undefined,
  processingAnchor: undefined,
}

export default AnchorFrames
