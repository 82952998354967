import { FC, useContext, useEffect, useState } from 'react'

import { Button, Container, FormControl, FormLabel, HStack, Input, Text, VStack } from '@chakra-ui/react'
import Navbar from 'components/Navbar/Navbar'
import PageHeading from 'components/PageHeading'
import mixpanel from 'mixpanel-browser'
import { useTranslation } from 'react-i18next'
import { Link as RouterLink, useLocation } from 'react-router-dom'

import { CheckCircleIcon } from 'assets/icons'

import { GlobalModalContext } from 'contexts/GlobalModal'

import { CONTAINER_MAX_WIDTH } from 'config/styles'

import { registerUser } from 'services/Users'
import { isValidEmail } from 'services/Validation'

const Register: FC = () => {
  const { search } = useLocation()
  const { t, i18n } = useTranslation(['register'])

  const parameters = new URLSearchParams(search)
  const email_address = parameters.get('email_address')

  const { showErrorModal } = useContext(GlobalModalContext)

  const [emailAddress, setEmailAddress] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [isCreated, setIsCreated] = useState(false)

  useEffect(() => {
    setEmailAddress(email_address || '')
    setIsCreated(false)
  }, [email_address])

  const onCreateUser = async () => {
    setIsLoading(true)

    const result = await registerUser(emailAddress, i18n.language, showErrorModal)
    setIsCreated(result)
    setIsLoading(false)

    // track with mixpanel
    mixpanel.track('Create user account', {
      email: emailAddress,
    })

    return true
  }

  return (
    <>
      <Navbar />

      <Container maxW={CONTAINER_MAX_WIDTH}>
        <PageHeading>{t('page_heading', { ns: 'register' })}</PageHeading>
        {!isCreated && (
          <VStack w="50%" alignItems="flex-start" spacing={4}>
            <>
              <Text mt={-4} mb={4}>
                {t('description', { ns: 'register' })}
              </Text>
              <FormControl>
                <FormLabel>{t('email_address', { ns: 'register' })}</FormLabel>
                <Input
                  id="email_address"
                  type="text"
                  value={emailAddress}
                  disabled={isLoading}
                  onChange={(e) => setEmailAddress(e.target.value.trim())}
                />
              </FormControl>
              <Button
                colorScheme="primary"
                minW={20}
                disabled={isLoading || !isValidEmail(emailAddress)}
                onClick={() => onCreateUser()}
                isLoading={isLoading}
                loadingText={t('registering', { ns: 'register' })}
                spinnerPlacement="end"
              >
                {t('register', { ns: 'register' })}
              </Button>
            </>
          </VStack>
        )}
        {isCreated && (
          <VStack alignItems="flex-start" spacing={4}>
            <HStack color="primary.500" fontSize="2xl">
              <CheckCircleIcon />
              <Text fontSize="md" fontWeight="bold">
                {t('register_success', { ns: 'register' })}
              </Text>
            </HStack>
            <Text>
              {t('post_register_message', { ns: 'register' })}
              <Text color="gray.400">({emailAddress})</Text>
            </Text>
            <Button colorScheme="primary" as={RouterLink} to="/dashboard">
              {t('login', { ns: 'register' })}
            </Button>
          </VStack>
        )}
      </Container>
    </>
  )
}

export default Register
