import axios from 'extends/axios'
import { Matrix4Tuple } from 'three'

import { EDITOR_EXTRA_SHAPE_KEYS, EDITOR_SHAPE_KEYS, EDITOR_SHAPE_TEMP_ID_PREFIX } from 'config/constants'
import { API_GATEWAY_URL } from 'config/environments'

import {
  Anchors,
  CADFileType,
  Cuboid,
  CuboidDirection,
  Cylinder,
  CylinderDetectionResult,
  ExtraShapeKey,
  Plane,
  PlaneDetectionResult,
  Project,
  ProjectGroup,
  Rhombus,
  RhombusDetectionResult,
  ShapeKey,
  Shapes,
  ShapesId,
  Torus,
  TorusDetectionResult,
} from 'interfaces/interfaces'

import { ERROR_PROCESS, processErrorHandler } from './ErrorHandler'
import { rotateCuboid } from './Points'
import { millimeterToMeter } from './Util'

const PROJECTS_API_URL = `${API_GATEWAY_URL}/projects`
const PROJECT_GROUPS_API_URL = `${API_GATEWAY_URL}/project-groups`
const GET_SHAPES_API_URL = (projectId: string) => `${PROJECTS_API_URL}/${projectId}/shapes`
const DETECT_SHAPES_API_URL = `${API_GATEWAY_URL}/detect-shapes`
const CREATE_SHAPES_API_URL = (projectId: string) => `${PROJECTS_API_URL}/${projectId}/shapes:batchCreate`
const DELETE_SHAPES_API_URL = (projectId: string) => `${PROJECTS_API_URL}/${projectId}/shapes:batchDelete`
const ORIGIN_FILE_API_URL = `${API_GATEWAY_URL}/origin-files`
const DOWN_SAMPLED_FILES_API_URL = `${API_GATEWAY_URL}/down-sampled-files`
const CAD_FILES_API_URL = `${API_GATEWAY_URL}/cad-files`
const INVITE_USER_API_URL = `${API_GATEWAY_URL}/invite-user`

/* --- プロジェクト操作 --- */

/**
 * get projects in a project group
 * @param {string} access_token access token
 * @param {string} project_group_id Project group id
 * @param {function} showErrorModal function to show error modal
 * @return {Project[]}
 */
export const getProjects = async (
  access_token: string,
  project_group_id: string,
  showErrorModal: (message: string) => void
): Promise<Project[] | null> => {
  const projects = await axios
    .get<{ projects: Project[] }>(`${PROJECT_GROUPS_API_URL}/${project_group_id}/projects`, {
      responseType: 'json',
      headers: { 'X-Authorization': `Bearer ${access_token}` },
    })
    .then((response) => response.data.projects)
    .catch((err) => {
      processErrorHandler(err, ERROR_PROCESS.GET_PROJECTS, showErrorModal)
      return null
    })

  return projects
}

/**
 * get project by project id
 * @param {string} access_token access token
 * @param {string} project_id Project id
 * @param {function} showErrorModal function to show error modal
 * @return {Project | null}
 */
export const getProject = async (
  access_token: string,
  project_id: string,
  showErrorModal: (message: string) => void
): Promise<Project | null> => {
  const project = await axios
    .get<Project>(`${PROJECTS_API_URL}/${project_id}`, {
      responseType: 'json',
      headers: { 'X-Authorization': `Bearer ${access_token}` },
    })
    .then((response) => response.data)
    .catch((err) => {
      processErrorHandler(err, ERROR_PROCESS.GET_PROJECTS, showErrorModal)
      return null
    })

  return project
}

/**
 * get all the invited projects
 * @param {string} access_token access token
 * @param {function} showErrorModal function to show error modal
 * @return {{projects: Project[], total_size: number}} {projects: Project[], total_size: number}
 */
export const getInvitedProjects = async (
  access_token: string,
  showErrorModal: (message: string) => void
): Promise<{ projects: Project[] } | null> => {
  const projects = await axios
    .get<{ projects: Project[] }>(`${PROJECTS_API_URL}?shared=true`, {
      responseType: 'json',
      headers: { 'X-Authorization': `Bearer ${access_token}` },
    })
    .then((response) => ({ projects: response.data.projects }))
    .catch((err) => {
      processErrorHandler(err, ERROR_PROCESS.GET_PROJECTS, showErrorModal)
      return null
    })

  return projects
}

/**
 * create a project in a project group
 * @param {string} access_token access token
 * @param {string} project_group_id project group id
 * @param {string} project_name project name
 * @param {function} showErrorModal function to show error modal
 * @return {Project} project object
 */
export const createProject = async (
  access_token: string,
  project_group_id: string,
  project_name: string,
  showErrorModal: (message: string) => void
): Promise<Project | null> => {
  const project = await axios
    .post<{ message: string; project: Project }>(
      `${PROJECT_GROUPS_API_URL}/${project_group_id}/projects`,
      {
        project_name,
      },
      {
        responseType: 'json',
        headers: { 'X-Authorization': `Bearer ${access_token}` },
      }
    )
    .then((response) => response.data.project)
    .catch((err) => {
      processErrorHandler(err, ERROR_PROCESS.CREATE_PROJECT, showErrorModal)
      return null
    })

  return project
}

/**
 * update project name
 * @param {string} access_token access token
 * @param {string} project_id project ID
 * @param {string} project_name new project name
 * @param {function} showErrorModal function to show error modal
 * @return {Project} project object
 */
export const updateProjectName = async (
  access_token: string,
  project_id: string,
  project_name: string,
  showErrorModal: (message: string) => void
): Promise<Project | null> => {
  const project = await axios
    .patch<Project>(
      `${PROJECTS_API_URL}/${project_id}`,
      {
        project_name,
      },
      {
        responseType: 'json',
        headers: { 'X-Authorization': `Bearer ${access_token}` },
      }
    )
    .then((response) => response.data)
    .catch((err) => {
      processErrorHandler(err, ERROR_PROCESS.UPDATE_PROJECT, showErrorModal)
      return null
    })

  return project
}

/**
 * Update blackboard memo
 * @param {string} access_token access token
 * @param {string} project_id project ID
 * @param {string} blackboard_memo texts to be saved in blackboard memo
 * @param {function} showErrorModal function to show error modal
 * @return {Project} project object
 */
export const updateProjectBlackboardMemo = async (
  access_token: string,
  project_id: string,
  blackboard_memo: string,
  showErrorModal: (message: string) => void
): Promise<Project | null> => {
  const project = await axios
    .patch<Project>(
      `${PROJECTS_API_URL}/${project_id}`,
      {
        blackboard_memo,
      },
      {
        responseType: 'json',
        headers: { 'X-Authorization': `Bearer ${access_token}` },
      }
    )
    .then((response) => response.data)
    .catch((err) => {
      processErrorHandler(err, ERROR_PROCESS.UPDATE_PROJECT, showErrorModal)
      return null
    })

  return project
}

/**
 * update pcd file name in a project object with translation vector
 * @param {string} access_token access token
 * @param {string} project_id project ID
 * @param {string} filename name of pcd file
 * @param {string} translation translation vector to be saved in project and used later
 * @param {function} showErrorModal function to show error modal
 * @return {Project} project object
 */
export const updateProjectFileName = async (
  access_token: string,
  project_id: string,
  filename: string,
  translation: number[],
  showErrorModal: (message: string) => void
): Promise<Project | null> => {
  const project = await axios
    .patch<Project>(
      `${PROJECTS_API_URL}/${project_id}`,
      {
        filename,
        translation,
      },
      {
        responseType: 'json',
        headers: { 'X-Authorization': `Bearer ${access_token}` },
      }
    )
    .then((response) => response.data)
    .catch((err) => {
      processErrorHandler(err, ERROR_PROCESS.UPDATE_PROJECT, showErrorModal)
      return null
    })

  return project
}

/**
 * update registration attribute of pcd file in a project object
 * @param {string} access_token access token
 * @param {string} project_id project ID
 * @param {Matrix4Tuple} transform_public_coordinate transform matrix of pcd file for a public coordinate
 * @param {function} showErrorModal function to show error modal
 * @return {Promise<Project | null>} project object
 */
export const updateProjectIFC = async (
  access_token: string,
  project_id: string,
  transform_public_coordinate: Matrix4Tuple,
  showErrorModal: (message: string) => void
): Promise<Project | null> => {
  const project = await axios
    .patch<Project>(
      `${PROJECTS_API_URL}/${project_id}`,
      {
        transform_public_coordinate,
      },
      {
        responseType: 'json',
        headers: { 'X-Authorization': `Bearer ${access_token}` },
      }
    )
    .then((response) => response.data)
    .catch((err) => {
      processErrorHandler(err, ERROR_PROCESS.UPDATE_PROJECT, showErrorModal)
      return null
    })

  return project
}

/**
 * invite user to project group
 * @param {string} access_token access token
 * @param {string} project_group_id project group ID
 * @param {string} email_address email address of the user to be invited
 * @param {function} showErrorModal function to show error modal
 * @return {Project} project object
 */
export const inviteUserToProjectGroup = async (
  access_token: string,
  project_group_id: string,
  email_address: string,
  showErrorModal: (message: string) => void
): Promise<Project | null> => {
  const project = await axios
    .post<Project>(
      `${INVITE_USER_API_URL}`,
      {
        project_group_id,
        email_address,
      },
      {
        responseType: 'json',
        headers: { 'X-Authorization': `Bearer ${access_token}` },
      }
    )
    .then((response) => response.data)
    .catch((err) => {
      processErrorHandler(err, ERROR_PROCESS.INVITE_USER_TO_PROJECT_GROUP, showErrorModal)
      return null
    })

  return project
}

/**
 * remove invited user from project group
 * @param {string} access_token access token
 * @param {string} project_group_id project group ID
 * @param {string} user_id user ID to be removed
 * @param {function} showErrorModal function to show error modal
 * @return {boolean} success or failure
 */
export const deleteInvitedUserFromProjectGroup = async (
  access_token: string,
  project_group_id: string,
  user_id: string,
  showErrorModal: (message: string) => void
): Promise<boolean> => {
  const result = await axios
    .delete<Project>(`${PROJECT_GROUPS_API_URL}/${project_group_id}/users/${user_id}`, {
      responseType: 'json',
      headers: { 'X-Authorization': `Bearer ${access_token}` },
    })
    .then(() => true)
    .catch((err) => {
      processErrorHandler(err, ERROR_PROCESS.DELETE_INVITED_USER_FROM_PROJECT_GROUP, showErrorModal)
      return false
    })

  return result
}

/**
 * Delete project group
 * @param {string} access_token access token
 * @param {string} projectGroupId project group id
 * @param {function} showErrorModal function to show error modal
 * @return {{projectGroups: ProjectGroup[]}} {projects: Project[]}
 */
export const deleteProjectGroup = async (
  access_token: string,
  projectGroupId: string,
  showErrorModal: (message: string) => void
): Promise<boolean> => {
  const result = await axios
    .delete(`${PROJECT_GROUPS_API_URL}/${projectGroupId}`, { headers: { 'X-Authorization': `Bearer ${access_token}` } })
    .then(() => true)
    .catch((err) => {
      processErrorHandler(err, ERROR_PROCESS.DELETE_PROJECT_GROUP, showErrorModal)
      return false
    })
  return result
}

/**
 * delete project
 * @param {string} access_token access token
 * @param {string} projectId project id
 * @param {function} showErrorModal function to show error modal
 * @return {{projects: Project[], total_size: number}} {projects: Project[], total_size: number}
 */
export const deleteProject = async (
  access_token: string,
  projectId: string,
  showErrorModal: (message: string) => void
): Promise<boolean> => {
  const result = await axios
    .delete(`${PROJECTS_API_URL}/${projectId}`, { headers: { 'X-Authorization': `Bearer ${access_token}` } })
    .then(() => true)
    .catch((err) => {
      processErrorHandler(err, ERROR_PROCESS.DELETE_PROJECT, showErrorModal)
      return false
    })
  return result
}

/* --- 鉄筋操作 --- */

/**
 * get shape objects in a project
 * @param {string} access_token Token
 * @param {string} project_id project ID
 * @param {function} showErrorModal function to show error modal
 * @return {Shapes} Shapes object
 */
export const getShapes = async (
  access_token: string,
  project_id: string,
  showErrorModal: (message: string) => void
): Promise<Shapes | null> => {
  const shapes = await axios
    .get<{ results: Shapes }>(GET_SHAPES_API_URL(project_id), {
      responseType: 'json',
      headers: { 'X-Authorization': `Bearer ${access_token}` },
    })
    .then((response) => response.data.results)
    .catch((err) => {
      processErrorHandler(err, ERROR_PROCESS.GET_SHAPES, showErrorModal)
      return null
    })

  return shapes
}

/**
 * remove shape objects in a project
 * @param {string} access_token token
 * @param {string} project_id project ID
 * @param {ShapesId} shapesId all ids of cylinders, tori, planes
 * @param {function} showErrorModal function to show error modal
 * @return {Boolean} success or failure
 */
export const deleteShapes = async (
  access_token: string,
  project_id: string,
  shapesId: ShapesId,
  showErrorModal: (message: string) => void
): Promise<boolean> => {
  const filteredShapesId = {
    cylinders: shapesId.cylinders.filter((id) => !id.startsWith(EDITOR_SHAPE_TEMP_ID_PREFIX)),
    tori: shapesId.tori.filter((id) => !id.startsWith(EDITOR_SHAPE_TEMP_ID_PREFIX)),
    planes: shapesId.planes.filter((id) => !id.startsWith(EDITOR_SHAPE_TEMP_ID_PREFIX)),
    rhombi: shapesId.rhombi.filter((id) => !id.startsWith(EDITOR_SHAPE_TEMP_ID_PREFIX)),
  }
  // No need to send request if deleting shapes are not saved yet (temporarily detected)
  if (
    !filteredShapesId.cylinders.length &&
    !filteredShapesId.tori.length &&
    !filteredShapesId.planes.length &&
    !filteredShapesId.rhombi.length
  ) {
    return true
  }
  const result = await axios
    .post(
      DELETE_SHAPES_API_URL(project_id),
      { ...filteredShapesId },
      {
        responseType: 'json',
        headers: { 'X-Authorization': `Bearer ${access_token}` },
      }
    )
    .then(() => true)
    .catch((err) => {
      processErrorHandler(err, ERROR_PROCESS.DELETE_SHAPES, showErrorModal)
      return false
    })

  return result
}

/**
 * save shape objects in a project
 * @param {string} access_token token
 * @param {string} project_id project ID
 * @param {ShapeKey | ExtraShapeKey} shape_type cylinders, tori, planes, rhombi
 * @param {
 *    CylinderDetectionResult[] | TorusDetectionResult[] |
 *    PlaneDetectionResult[] | RhombusDetectionResult[]
 * } shapes detected shapes result
 * @param {function} showErrorModal function to show error modal
 * @return {Cylinder[] | Torus[] | Plane[] | Rhombus[]} a Shapes object
 */
export const saveShapes = async (
  access_token: string,
  project_id: string,
  shape_type: ShapeKey | ExtraShapeKey,
  shapes: CylinderDetectionResult[] | TorusDetectionResult[] | PlaneDetectionResult[] | RhombusDetectionResult[],
  showErrorModal: (message: string) => void
): Promise<Cylinder[] | Torus[] | Plane[] | Rhombus[] | null> => {
  const newShapes = await axios
    .post<{
      results: Cylinder[] | Torus[] | Plane[] | Rhombus[]
    }>(
      CREATE_SHAPES_API_URL(project_id),
      {
        shape_type,
        list_shapes: shapes.map((shape) => {
          if (shape_type !== EDITOR_EXTRA_SHAPE_KEYS.RHOMBI) {
            return {
              ...shape,
              registration_result: {
                ...(shape.registration_result || []),
                transformation: Object.values(shape.registration_result?.transformation || {}),
              },
            }
          }
          return {
            parameters_rhombus: (shape as RhombusDetectionResult).parameters_rhombus,
            is_virtual: true,
            transformation: Object.values(shape.registration_result?.transformation || {}),
          }
        }),
      },
      {
        responseType: 'json',
        headers: { 'X-Authorization': `Bearer ${access_token}` },
      }
    )
    .then((response) => response.data.results)
    .catch((err) => {
      processErrorHandler(err, ERROR_PROCESS.SAVE_SHAPES, showErrorModal)
      return null
    })

  return newShapes
}

const getInternalShapeParameters = (diameter: number, shapeKey: ShapeKey) => {
  if (shapeKey === EDITOR_SHAPE_KEYS.CYLINDERS) {
    return { diameter: millimeterToMeter(diameter) }
  }
  if (shapeKey === EDITOR_SHAPE_KEYS.TORI) {
    return { minor_diameter: millimeterToMeter(diameter) }
  }
  return null
}
/**
 * detect shape objects in a project with specified anchors and mask regions
 * @param {string} access_token token
 * @param {string} downSampledFilePath file path
 * @param {string} shape_type cylinders, tori, planes
 * @param {Anchors} anchors all points with diameter
 * @param {Cuboid[]} maskRegions all mask regions
 * @param {boolean} use_outside_masks whether to use points outside of any masks
 * @param {function} showErrorModal function to show error modal
 * @return {string} job's token - used to retrieve job's status
 */
export const detectShapes = async (
  access_token: string,
  downSampledFilePath: string,
  shape_type: ShapeKey,
  anchors: Anchors,
  maskRegions: Cuboid[],
  use_outside_masks: boolean,
  showErrorModal: (message: string) => void
): Promise<string | null> => {
  const requestBodyBase = {
    path_pcd: downSampledFilePath,
    shape_type,
    parameters_for_detecting_shapes: anchors[shape_type]
      .filter((anchor) => !anchor.deleted)
      .map((anchor) => ({
        internal_shape_parameters: getInternalShapeParameters(anchor.diameter || 0, shape_type),
        positions: anchor.points.map((point) => ({ x: point[0], y: point[1], z: point[2] })),
      })),
  }

  let requestBody = {}
  if (!maskRegions.length) {
    requestBody = {
      ...requestBodyBase,
    }
  } else {
    requestBody = {
      ...requestBodyBase,
      masked_regions: maskRegions.map((mask) => ({
        use_mask: !mask.invisible,
        center: mask.center,
        // invert rotation matrix to match the backend convention
        rotation: mask.rotation.clone().invert().toArray(),
        extent: mask.extent,
      })),
      use_outside_masks,
    }
  }

  const token = await axios
    .post<{
      job_token: string
    }>(`${DETECT_SHAPES_API_URL}`, requestBody, {
      responseType: 'json',
      headers: { 'X-Authorization': `Bearer ${access_token}` },
    })
    .then((response) => response.data.job_token)
    .catch((err) => {
      processErrorHandler(err, ERROR_PROCESS.DETECT_SHAPES, showErrorModal)
      return null
    })

  return token
}

/**
 * detect shape objects in a project with specified cuboid and mask regions
 * @param {string} shapeKey cylinders type or torus type
 * @param {string} access_token token
 * @param {string} downSampledFilePath file path
 * @param {string} situation cylinders_on_axis, tori_on_axis, cylinders_on_arc
 * @param {number} diameter diameter
 * @param {Cuboid} cuboid cuboid's size, position, rotation
 * @param {Cuboid[]} maskRegions all mask regions
 * @param {boolean} use_outside_masks whether to use points outside of any masks
 * @param {function} showErrorModal function to show error modal
 * @param {CuboidDirection} cuboidDirection cuboid's direction
 * @return {string} job's token - used to retrieve job's status
 */
export const autoDetectShapes = async (
  shapeKey: ShapeKey,
  access_token: string,
  downSampledFilePath: string,
  situation: string,
  diameter: number,
  cuboid: Cuboid,
  maskRegions: Cuboid[],
  use_outside_masks: boolean,
  showErrorModal: (message: string) => void,
  cuboidDirection?: CuboidDirection
): Promise<string | null> => {
  const bounds = rotateCuboid(cuboid, cuboidDirection)
  const requestBodyBase = {
    path_pcd: downSampledFilePath,
    situation,
    internal_shape_parameters: getInternalShapeParameters(diameter, shapeKey),
    bounds: {
      center: bounds.center,
      extent: bounds.extent,
      // invert rotation matrix to match the backend convention
      rotation: bounds.rotation.invert().toArray(),
    },
  }

  let requestBody = {}
  if (!maskRegions.length) {
    requestBody = {
      ...requestBodyBase,
    }
  } else {
    requestBody = {
      ...requestBodyBase,
      masked_regions: maskRegions.map((mask) => ({
        use_mask: !mask.invisible,
        center: mask.center,
        // invert rotation matrix to match the backend convention
        rotation: mask.rotation.clone().invert().toArray(),
        extent: mask.extent,
      })),
      use_outside_masks,
    }
  }

  const token = await axios
    .post<{
      job_token: string
    }>(`${DETECT_SHAPES_API_URL}/bulk`, requestBody, {
      responseType: 'json',
      headers: { 'X-Authorization': `Bearer ${access_token}` },
    })
    .then((response) => response.data.job_token)
    .catch((err) => {
      processErrorHandler(err, ERROR_PROCESS.DETECT_SHAPES, showErrorModal)
      return null
    })

  return token
}

/**
 * To query the job status of detecting shape(s) either manually of automatically.
 * @param {string} access_token token
 * @param {string} jobToken job's token - get from the detect shape API
 * @param {function} showErrorModal function to show error modal
 * @return {object | null} detection result
 */
export const checkJobStatusForShapeDetection = async (
  access_token: string,
  jobToken: string,
  showErrorModal: (message: string) => void
): Promise<{
  shape_type?: ShapeKey
  list_shapes: CylinderDetectionResult[] | TorusDetectionResult[] | PlaneDetectionResult[]
} | null> => {
  const shapes = await axios
    .get<{
      job_status: string
      results: {
        shape_type: ShapeKey
        list_shapes: CylinderDetectionResult[] | TorusDetectionResult[] | PlaneDetectionResult[]
      }
    }>(`${API_GATEWAY_URL}/job-status?token=${jobToken}`, {
      responseType: 'json',
      headers: { 'X-Authorization': `Bearer ${access_token}` },
    })
    .then((response) => {
      if (response.data.job_status === 'SUCCEEDED') {
        return response.data.results
      }
      if (response.data.job_status === 'FAILED') {
        return { list_shapes: [] }
      }
      return null
    })
    .catch((err) => {
      processErrorHandler(err, ERROR_PROCESS.DETECT_SHAPES, showErrorModal)
      return null
    })
  return shapes
}

/**
 * To query the job status of down-sampling point cloud.
 * @param {string} access_token token
 * @param {string} jobToken job's token - get from the downsample pcd API
 * @param {function} showErrorModal function to show error modal
 * @return {object | null} downsampling result
 */
export const checkJobStatusForDownsampling = async (
  access_token: string,
  jobToken: string,
  showErrorModal: (message: string) => void
): Promise<{
  translation: number[]
  error_object?: string
} | null> => {
  const results = await axios
    .get<{
      job_status: string
      results: {
        translation: number[]
        error_object?: string
      }
    }>(`${API_GATEWAY_URL}/job-status?token=${jobToken}`, {
      responseType: 'json',
      headers: { 'X-Authorization': `Bearer ${access_token}` },
    })
    .then((response) => {
      if (response.data.job_status === 'SUCCEEDED') {
        return response.data.results
      }
      if (response.data.job_status === 'FAILED') {
        return { translation: [], error_object: response.data.results.error_object }
      }
      return null
    })
    .catch((err) => {
      processErrorHandler(err, ERROR_PROCESS.CREATE_PROJECT, showErrorModal)
      return null
    })
  return results
}

/* --- ファイル操作 --- */

/**
 * get signed URL for uploading a file
 * @param {string} access_token access token
 * @param {string} file_name file name
 * @param {string} content_type content type
 * @param {function} showErrorModal function to show error modal
 * @return {string} signed URL
 */
export const getSignedUrlForUploadFile = async (
  access_token: string,
  file_name: string,
  content_type: string,
  showErrorModal: (message: string) => void
): Promise<string | null> => {
  const url = await axios
    .post<{ url: string }>(
      `${ORIGIN_FILE_API_URL}`,
      {
        file_name,
        content_type,
      },
      {
        responseType: 'json',
        headers: { 'X-Authorization': `Bearer ${access_token}` },
      }
    )
    .then((response) => response.data.url)
    .catch((err) => {
      processErrorHandler(err, ERROR_PROCESS.CREATE_PROJECT, showErrorModal)
      return null
    })

  return url
}

/**
 * start down-sampling of a pcd file
 * @param {string} access_token access token
 * @param {string} filename file name
 * @param {function} showErrorModal function to show error modal
 * @return {string} job token
 */
export const startDownSampling = async (
  access_token: string,
  filename: string,
  showErrorModal: (message: string) => void
): Promise<string | null> => {
  const result = await axios
    .post<{ job_token: string }>(
      `${DOWN_SAMPLED_FILES_API_URL}`,
      { filename },
      {
        responseType: 'json',
        headers: { 'X-Authorization': `Bearer ${access_token}` },
      }
    )
    .then((response) => response.data.job_token)
    .catch((err) => {
      processErrorHandler(err, ERROR_PROCESS.CREATE_PROJECT, showErrorModal)
      return null
    })

  return result
}

/**
 * get a signed URL for downloading a downsampled pcd file
 * @param {string} access_token access token
 * @param {string} project_id project ID
 * @param {function} showErrorModal function to show error modal
 * @return {string} signed URL
 */
export const getSignedUrlForGetDownSampledFile = async (
  access_token: string,
  project_id: string,
  showErrorModal: (message: string) => void
): Promise<string | null> => {
  const url = await axios
    .get<{ url: string }>(`${DOWN_SAMPLED_FILES_API_URL}?project_id=${project_id}`, {
      responseType: 'json',
      headers: { 'X-Authorization': `Bearer ${access_token}` },
    })
    .then((response) => response.data.url)
    .catch((err) => {
      processErrorHandler(err, ERROR_PROCESS.CREATE_PROJECT, showErrorModal)
      return null
    })

  return url
}

/**
 * generate a CAD file for shape objects in a project
 * @param {string} access_token access token
 * @param {string} project_id project ID
 * @param {FileType} file_type file extension of CAD file
 * @param {function} showErrorModal function to show error modal
 * @return {Project} project object
 */
export const postCadFiles = async (
  access_token: string,
  project_id: string,
  file_type: CADFileType,
  showErrorModal: (message: string) => void
): Promise<Project | null> => {
  const project = await axios
    .post<{ message: string; project: Project }>(
      CAD_FILES_API_URL,
      { project_id, file_type },
      {
        responseType: 'json',
        headers: { 'X-Authorization': `Bearer ${access_token}` },
      }
    )
    .then((response) => response.data.project)
    .catch((err) => {
      processErrorHandler(err, ERROR_PROCESS.DOWNLOAD_CAD_FILE, showErrorModal)
      return null
    })

  return project
}

/**
 * get signed URL for downloading a CAD file
 * @param {string} access_token access token
 * @param {string} project_id project ID
 * @param {function} showErrorModal function to show error modal
 * @return {string} signed URL
 */
export const getSignedUrlForCadFile = async (
  access_token: string,
  project_id: string,
  showErrorModal: (message: string) => void
): Promise<string | null> => {
  const url = await axios
    .get<{ url: string }>(`${CAD_FILES_API_URL}?project_id=${project_id}`, {
      responseType: 'json',
      headers: { 'X-Authorization': `Bearer ${access_token}` },
    })
    .then((response) => response.data.url)
    .catch((err) => {
      processErrorHandler(err, ERROR_PROCESS.DOWNLOAD_CAD_FILE, showErrorModal)
      return null
    })

  return url
}

/**
 * Get project groups
 * @param {string} access_token access token
 * @param {string | undefined} organization_id organization ID
 * @param {string | undefined} user_id user ID
 * @param {function} showErrorModal function to show error modal
 * @return {{projectGroups: ProjectGroup[], total_size: number}}
 */
export const getProjectGroups = async (
  access_token: string,
  organization_id: string | undefined,
  user_id: string | undefined,
  showErrorModal: (message: string) => void
): Promise<{ projectGroups: ProjectGroup[]; total_size: number } | null> => {
  const organizationParam = organization_id ? `?organization_id=${organization_id}` : ''
  const userParam = user_id ? `?user_id=${user_id}` : ''
  const projectGroups = await axios
    .get<{ results: ProjectGroup[] }>(`${PROJECT_GROUPS_API_URL}${userParam || organizationParam}`, {
      responseType: 'json',
      headers: { 'X-Authorization': `Bearer ${access_token}` },
    })
    .then((response) => {
      const total_size = response.data.results.reduce(
        (_total_size, projectGroup) => _total_size + projectGroup.total_size,
        0
      )
      return {
        projectGroups: response.data.results,
        total_size,
      }
    })
    .catch((err) => {
      processErrorHandler(err, ERROR_PROCESS.GET_PROJECT_GROUPS, showErrorModal)
      return null
    })

  return projectGroups
}

/**
 * Get invited project groups
 * @param {string} access_token access token
 * @param {string | undefined} user_id user ID
 * @param {function} showErrorModal function to show error modal
 * @return {{projectGroups: ProjectGroup[], total_size: number}}
 */
export const getInvitedProjectGroups = async (
  access_token: string,
  user_id: string | undefined,
  showErrorModal: (message: string) => void
): Promise<{ projectGroups: ProjectGroup[] } | null> => {
  const userParam = user_id ? `&user_id=${user_id}` : ''
  const projectGroups = await axios
    .get<{ results: ProjectGroup[] }>(`${PROJECT_GROUPS_API_URL}?shared=true${userParam}`, {
      responseType: 'json',
      headers: { 'X-Authorization': `Bearer ${access_token}` },
    })
    .then((response) => ({ projectGroups: response.data.results }))
    .catch((err) => {
      processErrorHandler(err, ERROR_PROCESS.GET_PROJECT_GROUPS, showErrorModal)
      return null
    })

  return projectGroups
}

/**
 * Create project group
 * @param {string} access_token access token
 * @param {string | undefined} organization_id organization ID
 * @param {string} project_name project name
 * @param {function} showErrorModal function to show error modal
 * @return {Project} project object
 */
export const createProjectGroup = async (
  access_token: string,
  organization_id: string | undefined,
  project_group_name: string,
  showErrorModal: (message: string) => void
): Promise<ProjectGroup | null> => {
  const organizationParam = organization_id ? `/organizations/${organization_id}` : ''
  const projectGroup = await axios
    .post<ProjectGroup>(
      `${API_GATEWAY_URL}${organizationParam}/project-groups`,
      {
        project_group_name,
      },
      {
        responseType: 'json',
        headers: { 'X-Authorization': `Bearer ${access_token}` },
      }
    )
    .then((response) => response.data)
    .catch((err) => {
      processErrorHandler(err, ERROR_PROCESS.CREATE_PROJECT_GROUP, showErrorModal)
      return null
    })

  return projectGroup
}

/**
 * Change project group name
 * @param {string} access_token access token
 * @param {string} project_group_id project group ID
 * @param {string} project_group_name project group name
 * @param {function} showErrorModal function to show error modal
 * @return {ProjectGroup} project group object
 */
export const updateProjectGroupName = async (
  access_token: string,
  project_group_id: string,
  project_group_name: string,
  showErrorModal: (message: string) => void
): Promise<ProjectGroup | null> => {
  const projectGroup = await axios
    .patch<ProjectGroup>(
      `${PROJECT_GROUPS_API_URL}/${project_group_id}`,
      {
        project_group_name,
      },
      {
        responseType: 'json',
        headers: { 'X-Authorization': `Bearer ${access_token}` },
      }
    )
    .then((response) => response.data)
    .catch((err) => {
      processErrorHandler(err, ERROR_PROCESS.UPDATE_PROJECT_GROUP, showErrorModal)
      return null
    })

  return projectGroup
}
