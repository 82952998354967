import axios from 'extends/axios'

import { API_GATEWAY_URL } from 'config/environments'

import { Organization } from 'interfaces/interfaces'

import { ERROR_PROCESS, processErrorHandler } from './ErrorHandler'

/**
 * Rename an organization
 * @param {string} access_token - The access token for authentication.
 * @param {string} organization_id - The organization ID to update.
 * @param {string} organization_name - The new organization name.
 * @param {function} showErrorModal - Function to show error modal.
 * @returns {Organization | null} The updated organization.
 */
export const renameOrganization = async (
  access_token: string,
  organization_id: string,
  organization_name: string,
  showErrorModal: (message: string) => void
): Promise<Organization | null> =>
  axios
    .patch<Organization>(
      `${API_GATEWAY_URL}/organizations/${organization_id}`,
      {
        organization_name,
      },
      {
        responseType: 'json',
        headers: { 'X-Authorization': `Bearer ${access_token}` },
      }
    )
    .then((response) => response.data)
    .catch((err) => {
      processErrorHandler(err, ERROR_PROCESS.UPDATE_ORGANIZATIONS, showErrorModal)
      return null
    })

/**
 * Invite a user to an organization
 * @param {string} access_token - The access token for authentication.
 * @param {string} organization_id - The ID of the organization to invite the user to.
 * @param {string} email_address - The email address of the user to invite.
 * @param {function} showErrorModal - Function to show error modal.
 * @returns {Organization | null} The updated organization.
 */
export const inviteUserToOrganization = async (
  access_token: string,
  organization_id: string,
  email_address: string,
  showErrorModal: (message: string) => void
): Promise<Organization | null> =>
  axios
    .put<Organization | null>(
      `${API_GATEWAY_URL}/organizations/${organization_id}/users/${email_address}`,
      {
        email_address,
      },
      {
        responseType: 'json',
        headers: { 'X-Authorization': `Bearer ${access_token}` },
      }
    )
    .then((response) => response.data)
    .catch((err) => {
      processErrorHandler(err, ERROR_PROCESS.INVITE_USER_TO_ORGANIZATION, showErrorModal)
      return null
    })

/**
 * Remove a user from an organization
 * @param {string} access_token - The access token for authentication.
 * @param {string} organization_id - The ID of the organization to remove the user from.
 * @param {string} user_id - The ID of the user to remove.
 * @param {function} showErrorModal - Function to show error modal.
 * @returns {boolean} True if the user was removed successfully, false otherwise.
 */

export const removeUserFromOrganization = async (
  access_token: string,
  organization_id: string,
  user_id: string,
  showErrorModal: (message: string) => void
): Promise<boolean> =>
  axios
    .delete(`${API_GATEWAY_URL}/organizations/${organization_id}/users/${user_id}`, {
      responseType: 'json',
      headers: { 'X-Authorization': `Bearer ${access_token}` },
    })
    .then(() => true)
    .catch((err) => {
      processErrorHandler(err, ERROR_PROCESS.REMOVE_USER_FROM_ORGANIZATION, showErrorModal)
      return false
    })
