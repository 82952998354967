/* eslint-disable jsx-a11y/no-static-element-interactions */

/* eslint-disable jsx-a11y/click-events-have-key-events */
import { FC } from 'react'

import { Box, Center, IconButton, Tooltip } from '@chakra-ui/react'
import { Html } from '@react-three/drei'
import { Vector3 } from 'three'

import {
  AlertFillIcon,
  CommentDisplayIcon,
  CuboidIcon,
  InputEditorCancelIcon,
  InputEditorConfirmIcon,
} from 'assets/icons'

import { EDITOR_CANVAS_CONTAINER_ID, Z_INDEX, Z_INDEX_RANGE } from 'config/constants'

import { PointArray, Position } from 'interfaces/interfaces'

const ICON_BUTTON_PROPS = {
  size: 'xs',
  variant: 'outline',
  fontSize: 'sm',
  borderWidth: '1px',
  borderRadius: '50%',
  borderColor: 'gray.500',
  padding: '4px',
  backgroundColor: 'white',
  boxShadow: '0 0 3px white',
}

const CommentIcon: FC<{
  unplaced?: boolean
  cartesianPosition?: Position
  cuboidPosition?: PointArray
  disabled: boolean
  visible: boolean
  isMoving: boolean
  isHoldingPlace?: boolean
  onClick?: () => void
  onCancelMove?: () => void
  onConfirmMove?: () => void
}> = ({
  unplaced,
  cartesianPosition,
  cuboidPosition,
  disabled,
  visible,
  isMoving,
  isHoldingPlace,
  onClick,
  onCancelMove,
  onConfirmMove,
}) => {
  const content =
    isMoving && !unplaced ? (
      <Center position="relative" className="comment-icon moving">
        <CommentDisplayIcon />
        <IconButton
          {...ICON_BUTTON_PROPS}
          borderColor="var(--chakra-colors-red-500)"
          color="var(--chakra-colors-red-500)"
          aria-label="Cancel Move"
          // please keep this attribute for moving comment position feature
          aria-controls={EDITOR_CANVAS_CONTAINER_ID}
          icon={<InputEditorCancelIcon pointerEvents="none" />}
          disabled={disabled}
          onClick={(e) => {
            e.stopPropagation()
            if (!disabled && onCancelMove) onCancelMove()
          }}
          position="absolute"
          bottom={-30}
          left={-16}
        />
        <IconButton
          {...ICON_BUTTON_PROPS}
          borderColor="var(--chakra-colors-primary-500)"
          color="var(--chakra-colors-primary-500)"
          aria-label="Confirm Move"
          // please keep this attribute for moving comment position feature
          aria-controls={EDITOR_CANVAS_CONTAINER_ID}
          icon={<InputEditorConfirmIcon pointerEvents="none" />}
          disabled={disabled}
          onClick={(e) => {
            e.stopPropagation()
            if (!disabled && onConfirmMove) onConfirmMove()
          }}
          position="absolute"
          bottom={-30}
          left={16}
        />
      </Center>
    ) : (
      <Tooltip
        hasArrow
        placement="bottom"
        label={!cartesianPosition && !cuboidPosition && !isMoving ? '位置が指定されていません。' : ''}
        p="8px"
        fontWeight="normal"
        className="canvas-tooltip"
      >
        <div
          onClick={() => {
            if (!disabled && onClick) onClick()
          }}
        >
          <CommentDisplayIcon data-testid="comment-icon-main-canvas" />
          {cuboidPosition && (
            <Box position="absolute" top={5} left={7}>
              <CuboidIcon width={11} height={11} />
            </Box>
          )}
          {!cartesianPosition && !cuboidPosition && (
            <Center position="absolute" color="white" zIndex={Z_INDEX.main_canvas.comment_unplaced} top={3} left={5}>
              <AlertFillIcon />
            </Center>
          )}
        </div>
      </Tooltip>
    )

  if (unplaced) {
    return (
      <Box
        cursor={disabled || isHoldingPlace ? 'inherit' : 'pointer'}
        opacity={disabled ? '0.6' : '1'}
        visibility={!visible || isHoldingPlace ? 'hidden' : 'visible'}
        padding="4px"
        pointerEvents={isHoldingPlace ? 'none' : 'auto'}
      >
        <Box width="24px" height="24px" position="relative">
          {content}
        </Box>
      </Box>
    )
  }
  return (
    <Html
      position={
        new Vector3(
          cartesianPosition?.x || cuboidPosition?.[0] || 0,
          cartesianPosition?.y || cuboidPosition?.[1] || 0,
          cartesianPosition?.z || cuboidPosition?.[2] || 0
        )
      }
      style={{
        transform: 'translateY(-100%)',
        width: '24px',
        height: '24px',
        cursor: disabled ? 'inherit' : 'pointer',
        opacity: disabled ? '0.6' : '1',
        visibility: visible ? 'visible' : 'hidden',
        position: 'relative',
      }}
      zIndexRange={isMoving ? Z_INDEX_RANGE.comment_moving : Z_INDEX_RANGE.comment_static}
      className="unprintable"
    >
      {content}
    </Html>
  )
}

export default CommentIcon
