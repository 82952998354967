import { FC, useContext, useRef, useState } from 'react'

import {
  Button,
  FormControl,
  FormLabel,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react'
import mixpanel from 'mixpanel-browser'
import { isMobile } from 'react-device-detect'
import { useTranslation } from 'react-i18next'

import { FolderIcon } from 'assets/icons'

import { GlobalModalContext } from 'contexts/GlobalModal'
import { UserContext } from 'contexts/Users'

import { UPLOAD_LIMIT_FILE_BYTE, UPLOAD_LIMIT_FILE_GIGABYTE } from 'config/constants'

import { ProjectGroup } from 'interfaces/interfaces'

import { uploadFile } from 'services/AWS'
import { getSignedUrlForUploadIFC, upsertIFCFileMetaData } from 'services/IFC'
import { returnContentType } from 'services/Util'

const UploadIFCModal: FC<{
  isOpen: boolean
  projectGroup?: ProjectGroup
  onConfirm: (result?: boolean) => void
}> = ({ isOpen, projectGroup, onConfirm }) => {
  const { t } = useTranslation(['dashboard'])
  const { getAccessToken } = useContext(UserContext)
  const { showErrorModal } = useContext(GlobalModalContext)

  const uploadFileRef = useRef<HTMLInputElement>(null)

  const [file_name, setFileName] = useState('')
  const [upload_file, setUploadFile] = useState<File | null>(null)
  const [uploadPercent, setUploadPercent] = useState(0)
  const [isUploading, setIsUploading] = useState(false)

  const handleFileClick = () => {
    uploadFileRef.current?.click()
  }

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()
    if (!e || !e.target || !e.target.files || !e.target.files.length) return
    const file = e.target.files[0]

    if (file.size > UPLOAD_LIMIT_FILE_BYTE) {
      showErrorModal(
        t('components.upload_ifc_modal.over_filesize_upload_limit', {
          ns: 'dashboard',
          upload_limit_file_byte: UPLOAD_LIMIT_FILE_GIGABYTE,
        })
      )
      setFileName('')
      if (uploadFileRef && uploadFileRef.current) uploadFileRef.current.value = ''
    } else {
      setUploadFile(file)
      setFileName(file.name)
    }
  }

  const resetCreatingStates = () => {
    setUploadPercent(0)
    setIsUploading(false)
  }

  const onUpload = async () => {
    if (!projectGroup || !file_name) {
      return false
    }

    const content_type = returnContentType(file_name)

    if (!content_type) {
      showErrorModal(t('components.upload_ifc_modal.invalid_file_extension', { ns: 'dashboard' }))
      return false
    }
    if (!upload_file) {
      showErrorModal(t('components.upload_ifc_modal.invalid_file', { ns: 'dashboard' }))
      return false
    }
    if (upload_file.size > UPLOAD_LIMIT_FILE_BYTE) {
      showErrorModal(
        t('components.upload_ifc_modal.over_filesize_upload_limit', {
          ns: 'dashboard',
          upload_limit_file_byte: UPLOAD_LIMIT_FILE_GIGABYTE,
        })
      )
      return false
    }

    // Reset state
    setIsUploading(true)

    const token = await getAccessToken()
    if (!token) {
      resetCreatingStates()
      return false
    }

    const signedFile = await getSignedUrlForUploadIFC(token, projectGroup.project_group_id, file_name, showErrorModal)
    if (signedFile?.url) {
      const uploadResult = await uploadFile(
        signedFile.url,
        signedFile.content_type || upload_file.type,
        upload_file,
        null,
        showErrorModal
      )

      if (uploadResult) {
        if (await upsertIFCFileMetaData(token, projectGroup.project_group_id, signedFile, showErrorModal)) {
          onConfirm(true)
        }
      }
    }

    // track event to mixpanel
    mixpanel.track('Import ifc file', {
      'Project ID': projectGroup.project_group_id,
      'Project Name': projectGroup.project_group_name,
      'File size': upload_file.size,
    })

    // Reset states
    setFileName('')
    setUploadFile(null)
    resetCreatingStates()

    if (uploadFileRef && uploadFileRef.current) {
      uploadFileRef.current.value = ''
    }

    onConfirm(true)

    return true
  }

  return (
    <Modal closeOnOverlayClick={!isUploading} isOpen={isOpen} onClose={onConfirm} trapFocus={false}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{t('components.upload_ifc_modal.upload_ifc_file', { ns: 'dashboard' })}</ModalHeader>
        <ModalCloseButton hidden={isUploading} />
        <ModalBody>
          <FormControl mt={4}>
            <FormLabel htmlFor="file">
              {t('components.upload_ifc_modal.select_ifc_file', { ns: 'dashboard' })}
            </FormLabel>
            <InputGroup size="md">
              <Input
                data-testid="file-upload"
                hidden
                ref={uploadFileRef}
                type="file"
                accept={isMobile ? '' : '.ifc'}
                onChange={handleFileChange}
              />
              <Input isReadOnly value={file_name} />
              <InputRightElement w={14}>
                <IconButton
                  aria-label="Upload file"
                  borderColor="inherit"
                  borderWidth="1px"
                  className="upload-file-button"
                  disabled={isUploading}
                  fontSize="lg"
                  icon={<FolderIcon />}
                  onClick={handleFileClick}
                  w="100%"
                />
              </InputRightElement>
            </InputGroup>
          </FormControl>
        </ModalBody>

        <ModalFooter mt={8}>
          <Button disabled={isUploading} me={3} py={2} minW="100px" onClick={() => onConfirm()}>
            {t('components.upload_ifc_modal.cancel', { ns: 'dashboard' })}
          </Button>

          <Button
            colorScheme="primary"
            disabled={!upload_file || isUploading}
            isLoading={isUploading}
            loadingText={t('components.upload_ifc_modal.upload_in_progress', {
              ns: 'dashboard',
              upload_percent: uploadPercent,
            })}
            minW="100px"
            onClick={onUpload}
            py={2}
          >
            {t('components.upload_ifc_modal.upload', { ns: 'dashboard' })}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default UploadIFCModal
