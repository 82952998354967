import { FC, useContext } from 'react'

import { isMobile } from 'react-device-detect'
import { useSelector } from 'react-redux'
import { RootState } from 'store/app'
import { Vector3 } from 'three'

import { EditorContext } from 'contexts/Editor'

import { EDITOR_MEASURE_KEYS, EDITOR_TOOLS } from 'config/constants'
import { EDITOR_NEUTRAL_POINT_COLOR, EDITOR_POINT_COLOR } from 'config/styles'

import { MeasureKey, PointArray, ShapeKey } from 'interfaces/interfaces'

const PointMesh: FC<{
  point: PointArray
  scale: number
  anchorIndex: number
  pointIndex: number
  shapeKey: ShapeKey | MeasureKey
}> = ({ point, scale, anchorIndex, pointIndex, shapeKey }) => {
  const isJobRunning = useSelector((state: RootState) => state.editor.isJobRunning)
  const { selectedTool, isDragging, changeIsDragging, changeHoveredPoint, selectedPoint, hoveredPoint } =
    useContext(EditorContext)

  const isSelected = () => selectedPoint?.shapeKey === shapeKey && selectedPoint?.anchorIndex === anchorIndex

  const isHovered = () =>
    hoveredPoint?.shapeKey === shapeKey &&
    hoveredPoint?.anchorIndex === anchorIndex &&
    hoveredPoint.pointIndex === pointIndex

  const onPointerOver = () => {
    if (selectedTool !== EDITOR_TOOLS.MOVE || isDragging) {
      return
    }

    if (isJobRunning && shapeKey !== EDITOR_MEASURE_KEYS.DISTANCE) {
      return
    }

    changeHoveredPoint({
      anchorIndex,
      pointIndex,
      shapeKey,
    })
  }
  const onPointerOut = () => {
    if (selectedTool !== EDITOR_TOOLS.MOVE || isDragging) {
      return
    }

    changeHoveredPoint(undefined)
  }

  return (
    <>
      <mesh
        position={new Vector3(...point)}
        scale={scale}
        onPointerOver={() => {
          if (!isMobile) {
            onPointerOver()
          }
        }}
        onPointerOut={() => {
          if (!isMobile) {
            onPointerOut()
          }
        }}
        onPointerDown={() => {
          if (isMobile) {
            onPointerOver()
            changeIsDragging(true)
          }
        }}
        onPointerUp={() => {
          if (isMobile) {
            onPointerOut()
            changeIsDragging(false)
          }
        }}
      >
        <sphereGeometry args={[isHovered() || isSelected() ? 0.004 : 0.0035, 32, 16]} />
        <meshStandardMaterial color="white" depthTest={false} depthWrite={false} transparent />
      </mesh>
      <mesh position={new Vector3(...point)} scale={scale}>
        <sphereGeometry args={[0.0025, 32, 16]} />
        <meshStandardMaterial
          color={isHovered() || isSelected() ? EDITOR_POINT_COLOR : EDITOR_NEUTRAL_POINT_COLOR}
          depthTest={false}
          depthWrite={false}
          transparent
        />
      </mesh>
    </>
  )
}

export default PointMesh
