import 'react-day-picker/dist/style.css'

import { FC, useContext, useEffect, useState } from 'react'

import {
  Box,
  Button,
  Center,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  IconButton,
  Input,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  ModalBody,
  ModalFooter,
  NumberInput,
  NumberInputField,
  Stack,
  StackDivider,
  Text,
  Tooltip,
  VStack,
} from '@chakra-ui/react'
import { PDFDownloadLink } from '@react-pdf/renderer'
import { enUS, ja } from 'date-fns/locale'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import mixpanel from 'mixpanel-browser'
import XMLImportButton from 'pages/projects/components/XMLImportButton'
import { useCookies } from 'react-cookie'
import { DayPicker } from 'react-day-picker'
import { useTranslation } from 'react-i18next'
import { Link as RouterLink, useParams } from 'react-router-dom'

import {
  AlertFillIcon,
  ChevronDownIcon,
  DeleteIcon,
  InputEditorCancelIcon,
  InputEditorConfirmIcon,
  InputEditorEditIcon,
  ResetIcon,
} from 'assets/icons'

import { EditorContext, INITIAL_SHAPE_STATE } from 'contexts/Editor'
import { GlobalModalContext } from 'contexts/GlobalModal'
import { UserContext } from 'contexts/Users'

import useInspectionSheetInputSuggestions from 'hooks/InspectionSheetInputSuggestions'

import {
  COOKIE_EXPIRE,
  EDITOR_COVER_DISTANCE_CRITERION_UNIT_INDEX_COOKIE_NAME,
  EDITOR_MEAN_DISTANCE_CRITERION_UNIT_INDEX_COOKIE_NAME,
  EDITOR_SHAPES_SITUATIONS,
  LANGUAGES,
  MODAL_TYPES,
  Z_INDEX,
} from 'config/constants'
import {
  INSPECTION_CONTRACTEE_ERROR_COLOR,
  INSPECTION_CONTRACTOR_ERROR_COLOR,
  INSPECTION_NORMAL_COLOR,
} from 'config/styles'

import {
  InspectionItem,
  InspectionItemEvaluation,
  InspectionItemEvaluationCollection,
  InspectionItemNumberValues,
  InspectionSheet,
  ShapesDistances,
} from 'interfaces/interfaces'

import {
  deleteInspectionItem,
  evaluateValues,
  formatInspectionDifferentValue,
  updateInspectionSheet,
} from 'services/InspectionSheet'
import { getShapeDistancesFromInspectionItem } from 'services/ShapeDistances'
import { findDiameterKeyByValue, meterToMillimeter, millimeterToMeter, sanitizeFilename } from 'services/Util'
import { generateXML } from 'services/exports/XML'

import { generateXLSX } from '../../../../services/exports/XLSX'
import MainSheetPDF from './MainSheetPDF'

dayjs.extend(utc)

const ALERT_COLOR = 'orange'
const ALERT_BACKGROUND_COLOR = 'orange.50'
const LINE_COLOR = 'secondary.200'
const ICON_WIDTH = 6
const INPUT_EDITOR_ICON_PROPS = {
  backgroundColor: 'secondary.200',
  borderColor: 'secondary.00',
  borderWidth: 1,
  borderRadius: ICON_WIDTH * 4,
  minWidth: ICON_WIDTH,
  width: ICON_WIDTH,
  height: ICON_WIDTH,
  padding: 0,
}
const PROFILE_INPUT_WIDTH = 300
const NAME_COLUMN_WIDTH = 165
const VALUE_COLUMN_WIDTH = 200
const RESULT_COLUMN_WIDTH = 170
const VALUE_HEADER_COLOR = 'secondary.500'

const ROW_WIDTH = NAME_COLUMN_WIDTH + VALUE_COLUMN_WIDTH * 4 + RESULT_COLUMN_WIDTH
const MODAL_PADDING = 6
export const MODAL_WIDTH = MODAL_PADDING * 4 + ROW_WIDTH + 79

const ITEMS_ON_FIRST_PAGE = 5
const ITEMS_PER_PAGE = 20

const CRITERION_UNITS = ['Φ', 'mm']

const MainSheetModal: FC<{
  canClose: boolean
  inspectionSheet: InspectionSheet
  inspectionItems: InspectionItem[]
  isLoadingSheet: boolean
  isAllowedDownload: boolean
  isAllowedModify: boolean
  isLoading: boolean
  onOpenItem: (item: InspectionItem) => void
  onClose: () => void
  onSheetUpdated: () => void
  onItemSelected: (item: InspectionItem | null) => void
  onItemAdded: () => void
  onItemRefetch: (item: InspectionItem) => void
  openUpdateProjectModal: () => void
  setShapesDistances: (distances: ShapesDistances) => void
  setIsItemSelected: (state: boolean) => void
  setIsLoading: (state: boolean) => void
  onOpenBlackboard: () => void
}> = ({
  canClose,
  inspectionSheet,
  inspectionItems,
  isLoadingSheet,
  isAllowedDownload,
  isAllowedModify,
  isLoading,
  onOpenItem,
  onClose,
  onSheetUpdated,
  onItemSelected,
  onItemAdded,
  onItemRefetch,
  openUpdateProjectModal,
  setShapesDistances,
  setIsItemSelected,
  setIsLoading,
  onOpenBlackboard,
}) => {
  const { t, i18n } = useTranslation(['projects'])
  const { project_id } = useParams<{ project_id: string }>()
  const { getAccessToken } = useContext(UserContext)
  const { showModal, showErrorModal } = useContext(GlobalModalContext)
  const { project, shapesDistancesVisible } = useContext(EditorContext)
  const [cookies, setCookie] = useCookies([
    EDITOR_MEAN_DISTANCE_CRITERION_UNIT_INDEX_COOKIE_NAME,
    EDITOR_COVER_DISTANCE_CRITERION_UNIT_INDEX_COOKIE_NAME,
  ])

  const inputSuggestions = useInspectionSheetInputSuggestions(project?.project_group_id)

  const [editingField, setEditingField] = useState('')
  const [editingValue, setEditingValue] = useState<string | Date>('')
  const [itemResults, setItemResults] = useState<InspectionItemEvaluationCollection[]>([])
  const [currentPage, setCurrentPage] = useState(0)
  const [totalPage, setTotalPage] = useState(0)
  const [selectedItemIndex, setSelectedItemIndex] = useState(-1)

  // index 0 (corresponding to Φ) is used as default value
  const indexMeanDistanceCriterion = Number(cookies[EDITOR_MEAN_DISTANCE_CRITERION_UNIT_INDEX_COOKIE_NAME] || 0)
  const indexCoverDistanceCriterion = Number(cookies[EDITOR_COVER_DISTANCE_CRITERION_UNIT_INDEX_COOKIE_NAME] || 0)
  let meanDistanceContracteeCriterion: number | undefined = inspectionSheet.criterion_contractee?.mean_distance_ratio
  let meanDistanceContractorCriterion: number | undefined = inspectionSheet.criterion_contractor.mean_distance_ratio
  let coverDistanceContracteeCriterion: number | undefined = inspectionSheet.criterion_contractee?.cover_distance_ratio
  let coverDistanceContractorCriterion: number | undefined = inspectionSheet.criterion_contractor.cover_distance_ratio
  if (indexMeanDistanceCriterion !== 0) {
    // unit conversion (m -> mm) for mean distance criterion (abs) is performed only here
    meanDistanceContracteeCriterion =
      inspectionSheet.criterion_contractee?.mean_distance_abs &&
      meterToMillimeter(inspectionSheet.criterion_contractee?.mean_distance_abs)
    meanDistanceContractorCriterion =
      inspectionSheet.criterion_contractor.mean_distance_abs &&
      meterToMillimeter(inspectionSheet.criterion_contractor.mean_distance_abs)
  }
  if (indexCoverDistanceCriterion !== 0) {
    // unit conversion (m -> mm) for cover distance criterion (abs) is performed only here
    coverDistanceContracteeCriterion =
      inspectionSheet.criterion_contractee?.cover_distance_abs &&
      meterToMillimeter(inspectionSheet.criterion_contractee?.cover_distance_abs)
    coverDistanceContractorCriterion =
      inspectionSheet.criterion_contractor.cover_distance_abs &&
      meterToMillimeter(inspectionSheet.criterion_contractor.cover_distance_abs)
  }

  useEffect(() => {
    setIsItemSelected(selectedItemIndex >= 0)
  }, [selectedItemIndex, setIsItemSelected])

  useEffect(() => {
    setItemResults(
      inspectionItems.map((item) => {
        const diameterResult = evaluateValues(
          item.diameter, // [m]
          t('main_canvas.inspection_sheet', { ns: 'projects', returnObjects: true }),
          inspectionSheet.criterion_contractee?.diameter,
          inspectionSheet.criterion_contractor.diameter,
          item.diameter.specified_value, // TODO: set null
          item.diameter.specified_value, // TODO: set null
          true,
          true
        )
        const numberResult = evaluateValues(
          item.number,
          t('main_canvas.inspection_sheet', { ns: 'projects', returnObjects: true }),
          inspectionSheet.criterion_contractee?.number,
          inspectionSheet.criterion_contractor.number,
          item.diameter.specified_value, // TODO: set null
          item.diameter.specified_value // TODO: set null
        )
        const meanDistanceResult = evaluateValues(
          item.mean_distance, // [m]
          t('main_canvas.inspection_sheet', { ns: 'projects', returnObjects: true }),
          meanDistanceContracteeCriterion, // [phi] or [mm]
          meanDistanceContractorCriterion, // [phi] or [mm]
          indexMeanDistanceCriterion === 0 ? item.diameter.specified_value : undefined, // [m]
          indexMeanDistanceCriterion === 0 ? item.diameter.specified_value : undefined, // [m]
          indexMeanDistanceCriterion === 0, // performs [m] -> [mm] on refer value (diameter)
          true
        )
        const coverDistanceResult = evaluateValues(
          item.cover_distance, // [m]
          t('main_canvas.inspection_sheet', { ns: 'projects', returnObjects: true }),
          coverDistanceContracteeCriterion, // [phi] or [mm]
          coverDistanceContractorCriterion, // [phi] or [mm]
          indexCoverDistanceCriterion === 0 ? item.diameter.specified_value : undefined, // [m]
          indexCoverDistanceCriterion === 0 ? item.diameter.specified_value : undefined, // [m]
          indexCoverDistanceCriterion === 0, // performs [m] -> [mm] on refer value (diameter)
          true,
          true
        )
        const overallResult: InspectionItemEvaluation = {
          qualifiedByContractee:
            diameterResult.qualifiedByContractee &&
            numberResult.qualifiedByContractee &&
            meanDistanceResult.qualifiedByContractee &&
            coverDistanceResult.qualifiedByContractee,
          qualifiedByContractor:
            diameterResult.qualifiedByContractor &&
            numberResult.qualifiedByContractor &&
            meanDistanceResult.qualifiedByContractor &&
            coverDistanceResult.qualifiedByContractor,
          description: '',
        }

        return {
          diameter: diameterResult,
          number: numberResult,
          meanDistance: meanDistanceResult,
          coverDistance: coverDistanceResult,
          overall: overallResult,
        }
      })
    )

    const itemsLeft = inspectionItems.length - ITEMS_ON_FIRST_PAGE
    if (itemsLeft <= 0) {
      setTotalPage(1)
    } else {
      const pageLeft = Math.ceil(itemsLeft / ITEMS_PER_PAGE)
      setTotalPage(pageLeft + 1)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cookies, inspectionItems, inspectionSheet, indexMeanDistanceCriterion, indexCoverDistanceCriterion])

  useEffect(() => {
    if (selectedItemIndex < 0) {
      if (shapesDistancesVisible && inspectionItems.length) {
        setShapesDistances(getShapeDistancesFromInspectionItem(inspectionItems))
      } else {
        setShapesDistances(INITIAL_SHAPE_STATE())
      }
    } else {
      const selectedItem = (filterPaginatedItems(inspectionItems, currentPage) as InspectionItem[])[selectedItemIndex]
      setShapesDistances({
        planes: selectedItem.cylinders_and_plane_individual_distance || [],
        cylinders:
          selectedItem.situation === EDITOR_SHAPES_SITUATIONS.CYLINDERS_ON_ARC ||
          selectedItem.situation === EDITOR_SHAPES_SITUATIONS.CYLINDERS_ON_AXIS
            ? selectedItem.aligned_shapes_individual_distance || []
            : [],
        tori:
          selectedItem.situation === EDITOR_SHAPES_SITUATIONS.TORI_ON_AXIS
            ? selectedItem.aligned_shapes_individual_distance || []
            : [],
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, inspectionItems, selectedItemIndex])

  useEffect(() => {
    if (cookies) {
      // update the cookie expiration date
      setCookie(
        EDITOR_MEAN_DISTANCE_CRITERION_UNIT_INDEX_COOKIE_NAME,
        // index 0 (corresponding to Φ) is used as default value
        cookies[EDITOR_MEAN_DISTANCE_CRITERION_UNIT_INDEX_COOKIE_NAME] || 0,
        {
          expires: COOKIE_EXPIRE,
        }
      )
      setCookie(
        EDITOR_COVER_DISTANCE_CRITERION_UNIT_INDEX_COOKIE_NAME,
        // index 0 (corresponding to Φ) is used as default value
        cookies[EDITOR_COVER_DISTANCE_CRITERION_UNIT_INDEX_COOKIE_NAME] || 0,
        {
          expires: COOKIE_EXPIRE,
        }
      )
    }
  }, [cookies, setCookie])

  const updateSheetValue = (
    key: string,
    value: string | number | null,
    parentKey?: 'construction_properties' | 'criterion_contractee' | 'criterion_contractor'
  ) => {
    if (!project_id) {
      return false
    }

    if (!inspectionSheet) {
      showErrorModal(t('main_canvas.inspection_sheet.message_no_sheet', { ns: 'projects' }))
      return false
    }

    setIsLoading(true)
    const newValues = parentKey
      ? {
          [parentKey]: { ...inspectionSheet[parentKey], [key]: value },
        }
      : { [key]: value }

    const newSheet = {
      ...inspectionSheet,
      ...newValues,
    }

    void (async () => {
      const token = await getAccessToken()
      if (!token) {
        setIsLoading(false)
        return false
      }

      if (await updateInspectionSheet(token, project_id, newSheet, showErrorModal)) {
        onSheetUpdated()
        setIsLoading(false)

        // track with mixpanel
        mixpanel.track('Update inspection sheet', {
          'Inspection area ID': project_id,
          'Updated part': parentKey,
          'Updated subpart': key,
          'New Value': value,
        })

        return true
      }
      setIsLoading(false)
      return false
    })()

    return true
  }

  const deleteItem = (item: InspectionItem) => {
    showModal({
      body: (
        <>
          <Text>{t('main_canvas.inspection_sheet.delete_item_modal.warning_text', { ns: 'projects' })}</Text>
          <Text mt="1" fontWeight="semibold">
            {item.part_name}
          </Text>
        </>
      ),
      confirmText: t('main_canvas.inspection_sheet.delete_item_modal.delete', { ns: 'projects' }),
      modalType: MODAL_TYPES.CONFIRMATION_CRITICAL,
      onConfirm: () => {
        void (async () => {
          if (!item.inspection_item_id || !project_id || !inspectionSheet.inspection_sheet_id) {
            return false
          }

          setIsLoading(true)
          const token = await getAccessToken()
          if (!token) {
            setIsLoading(false)
            return false
          }

          if (
            await deleteInspectionItem(
              token,
              project_id,
              inspectionSheet.inspection_sheet_id,
              item.inspection_item_id,
              showErrorModal
            )
          ) {
            onSheetUpdated()
            setIsLoading(false)

            // track with mixpanel
            mixpanel.track('Delete inspection item', {
              'Inspection area ID': project_id,
              'Inspection item ID': item.inspection_item_id,
            })

            return true
          }

          setIsLoading(false)
          return false
        })()
        return true
      },
      title: t('main_canvas.inspection_sheet.delete_item_modal.confirm', { ns: 'projects' }),
    })
  }

  const onGenerateXML = async () => {
    if (!project_id) {
      return false
    }

    setIsLoading(true)

    const token = await getAccessToken()
    if (!token) {
      setIsLoading(false)
      return false
    }

    await generateXML(
      token,
      project_id,
      inspectionSheet.inspection_sheet_id,
      `${getFileName()}.xml`,
      indexMeanDistanceCriterion !== 0,
      indexCoverDistanceCriterion !== 0,
      showErrorModal
    )

    // track event to mixpanel
    mixpanel.track('Export inspection sheet', {
      'Inspection area ID': project_id,
      'File format': 'xml',
      'Criterion unit (mean distance)': CRITERION_UNITS[indexMeanDistanceCriterion],
      'Criterion unit (cover distance)': CRITERION_UNITS[indexCoverDistanceCriterion],
    })

    setIsLoading(false)
    return true
  }

  /**
   * input form of text, mainly for inspection sheet.
   *
   * @param {string} label - label for input item.
   * @param {string} value - value for input item.
   * @param {(newValue: string | number) => void} setter - callback function to set new value.
   * @param {?boolean} [readonly] - whether to set the input readonly.
   * @param {?boolean} [hideLabel]- whether to set the label hidden.
   * @param {?string} [displayValue] - value to display (value is overridden if this is set).
   * @param {?boolean} [forDateTime] - whether the input is for date time.
   * @param {?boolean} [isNumber] - whether the input is for number.
   * @param {?number} [lowerConstraintValue] - lower constraint value (for number case).
   * @param {?string} [lowerConstraintMessage] - message to display when lower constraint is violated.
   * @param {?number} [upperConstraintValue] - upper constraint value (for number case).
   * @param {?string} [upperConstraintMessage] - message to display when upper constraint is violated.
   * @param {?string} [unit] - unit to display.
   */
  const renderTextInput = (
    label: string,
    value: string,
    setter: (newValue: string | number) => void,
    readonly?: boolean,
    hideLabel?: boolean,
    displayValue?: string,
    forDateTime?: boolean,
    isNumber?: boolean,
    lowerConstraintValue?: number,
    lowerConstraintMessage?: string,
    upperConstraintValue?: number,
    upperConstraintMessage?: string,
    unit?: string
  ) => {
    const suggestions = inputSuggestions[label] || []

    return (
      <FormControl flex={1}>
        <HStack
          alignItems="baseline"
          borderBottomWidth={hideLabel ? 0 : 1}
          borderBottomColor={LINE_COLOR}
          paddingBottom="1px"
        >
          {!hideLabel && (
            <FormLabel htmlFor={label} minWidth={16} marginBottom={0} fontSize="sm" color={VALUE_HEADER_COLOR}>
              {label}
            </FormLabel>
          )}
          <Box
            position="relative"
            flex={1}
            zIndex={forDateTime ? Z_INDEX.main_canvas.inspection_sheet.input_form_box_date : 'auto'}
            mx={1}
            backgroundColor="white"
          >
            {isAllowedModify && editingField === label && forDateTime && (
              <Box paddingLeft={2} paddingRight={2} flex={1} height={6}>
                <Box
                  fontSize="sm"
                  position="absolute"
                  boxShadow="md"
                  borderWidth={1}
                  borderColor="secondary.200"
                  backgroundColor="white"
                  rounded={8}
                  right={0}
                  top={0}
                  className="modely-date-picker"
                >
                  <DayPicker
                    mode="single"
                    locale={i18n.language === LANGUAGES.JA ? ja : enUS}
                    selected={editingValue as Date}
                    onSelect={(date) => setEditingValue(date || new Date())}
                  />
                </Box>
              </Box>
            )}
            {isAllowedModify && editingField === label && !forDateTime && !isNumber && (
              <Input
                id={label}
                value={editingValue as string}
                onChange={(e) => setEditingValue(e.target.value)}
                flex={1}
                paddingLeft={2}
                paddingRight={2}
                height={8}
                fontSize="sm"
                autoFocus
              />
            )}
            {isAllowedModify && editingField === label && !forDateTime && isNumber && (
              <>
                <NumberInput
                  min={lowerConstraintValue || 0}
                  max={upperConstraintValue || 9999}
                  flex={1}
                  value={(editingValue as string) || ''}
                  onChange={(valueAsString: string) => {
                    setEditingValue(valueAsString)
                  }}
                  isRequired
                  position="relative"
                  zIndex={Z_INDEX.main_canvas.inspection_sheet.input_form_number}
                >
                  <NumberInputField fontSize="sm" padding={2} height={8} />
                </NumberInput>
                <Text
                  position="absolute"
                  right={-16}
                  fontSize="80%"
                  whiteSpace="nowrap"
                  color={VALUE_HEADER_COLOR}
                  backgroundColor="white"
                  p={1}
                >
                  {lowerConstraintMessage || upperConstraintMessage || ''}
                </Text>
              </>
            )}
            {editingField !== label && (
              <Box textAlign={unit ? 'center' : 'unset'}>
                <Text
                  height={unit ? 4 : 8}
                  lineHeight={unit ? 4 : 8}
                  flex={1}
                  color={value ? 'secondary' : 'transparent'}
                >
                  {displayValue || value || '.'}
                </Text>
                {!!unit && (
                  <Text height={4} lineHeight={4} flex={1} color={value ? 'secondary' : 'transparent'}>
                    {unit}
                  </Text>
                )}
              </Box>
            )}
            <HStack
              position="absolute"
              right={0}
              top={0}
              transform="translateX(100%)"
              px={1.5}
              h="100%"
              backgroundColor="white"
              zIndex={Z_INDEX.main_canvas.inspection_sheet.icons_hstack}
            >
              {isAllowedModify && editingField === label && (
                <>
                  <IconButton
                    {...INPUT_EDITOR_ICON_PROPS}
                    aria-label="cancel"
                    icon={<InputEditorCancelIcon />}
                    color="secondary.500"
                    onClick={() => {
                      setEditingField('')
                      setEditingValue('')
                    }}
                  />
                  <IconButton
                    {...INPUT_EDITOR_ICON_PROPS}
                    disabled={
                      isNumber &&
                      (Number.isNaN(Number(editingValue)) ||
                        (!!lowerConstraintValue && Number(editingValue) < lowerConstraintValue) ||
                        (!!upperConstraintValue && Number(editingValue) > upperConstraintValue))
                    }
                    aria-label="confirm"
                    icon={<InputEditorConfirmIcon />}
                    color="white"
                    backgroundColor="secondary.500"
                    colorScheme="secondary"
                    onClick={() => {
                      if (forDateTime) {
                        setter(
                          editingValue
                            ? dayjs(editingValue as Date)
                                .utc()
                                .toISOString()
                            : ''
                        )
                      } else if (isNumber) {
                        setter(parseFloat(editingValue as string))
                      } else {
                        setter(editingValue as string)
                      }
                      setEditingField('')
                      setEditingValue('')
                    }}
                  />
                </>
              )}
              {isAllowedModify && !editingField && editingField !== label && !readonly && (
                <IconButton
                  {...INPUT_EDITOR_ICON_PROPS}
                  aria-label="edit"
                  icon={<InputEditorEditIcon />}
                  color="white"
                  borderColor="primary.500"
                  backgroundColor="primary.500"
                  colorScheme="primary"
                  onClick={() => {
                    setEditingField(label)
                    setEditingValue(forDateTime && value ? dayjs.utc(value).toDate() : value || '')
                  }}
                  disabled={isLoading || isLoadingSheet}
                />
              )}
            </HStack>
            {isAllowedModify && editingField === label && forDateTime && value && (
              <HStack
                position="absolute"
                right={0}
                top={9}
                transform="translateX(100%)"
                px={1.5}
                h="100%"
                backgroundColor="white"
                zIndex={Z_INDEX.main_canvas.inspection_sheet.icons_hstack}
              >
                <IconButton
                  {...INPUT_EDITOR_ICON_PROPS}
                  aria-label="delete"
                  icon={<DeleteIcon />}
                  color="white"
                  backgroundColor="secondary.500"
                  colorScheme="secondary"
                  onClick={() => {
                    setEditingField('')
                    setEditingValue('')
                    setter('')
                  }}
                />
              </HStack>
            )}
            {isAllowedModify && editingField === label && (
              <Menu isOpen={!editingValue && !!suggestions?.length}>
                {/* For positioning the menu */}
                <MenuButton left={0} bottom={2} position="absolute" />
                <MenuList zIndex={Z_INDEX.main_canvas.inspection_sheet.suggestion_menu}>
                  {suggestions?.map((suggestion) => (
                    <MenuItem key={suggestion} onClick={() => setEditingValue(suggestion)}>
                      {suggestion}
                    </MenuItem>
                  ))}
                </MenuList>
              </Menu>
            )}
          </Box>
        </HStack>
      </FormControl>
    )
  }

  /**
   * returns component for the header of the criterion columns.
   * It consists of 2 columns: criterion value for contractee and contractor.
   *
   * @param {string} header - header text
   * @param {number} COLUMN_WIDTH - minimum width of the columns.
   * @param {(number | undefined)} contracteeCriterionValue - contractee criterion value.
   * @param {(number | undefined)} contractorCriterionValue - contractor criterion value.
   * @param {string} unit - unit of the criteria to display in the 2 columns.
   * @param {?string} criterionUnit - unit of the criteria to display in the 2 columns.
   * @param {?number} criterionUnitIndex - index of the criterion unit.
   * @param {?() => void} onUpdateCriterionUnit - callback function
   * to update criterion unit.
   * @param {?(newValue: string | number) => void} contracteeCriterionValueSetter
   * - callback function to set new contractee criterion value.
   * @param {?(newValue: string | number) => void} contractorCriterionValueSetter
   * - callback function to set new contractor criterion value.
   */
  const renderCriterionHeadersColumn = (
    header: string,
    COLUMN_WIDTH: number,
    contracteeCriterionValue: number | undefined,
    contractorCriterionValue: number | undefined,
    unit: string,
    criterionUnit?: string,
    criterionUnitIndex?: number,
    onUpdateCriterionUnit?: () => void,
    contracteeCriterionValueSetter?: (newValue: string | number) => void,
    contractorCriterionValueSetter?: (newValue: string | number) => void
  ) => (
    <VStack minWidth={COLUMN_WIDTH} alignItems="stretch">
      <Center p={4} backgroundColor={LINE_COLOR} position="relative">
        <Text>{header}</Text>
        {!!onUpdateCriterionUnit && (
          <VStack
            position="absolute"
            right={1}
            cursor="pointer"
            backgroundColor="secondary.300"
            fontSize="0.8em"
            borderRadius={10}
            spacing={0}
            onClick={onUpdateCriterionUnit}
            userSelect="none"
          >
            <Box
              borderRadius={50}
              backgroundColor="secondary.400"
              w={8}
              h={5}
              position="absolute"
              zIndex={Z_INDEX.main_canvas.inspection_sheet.criterion_unit_switcher}
              top={(criterionUnitIndex || 0) * 5}
              left={0}
              className={`criterion-unit-switcher-${criterionUnitIndex || 0}`}
              transition="top 0.2s ease-out"
            />
            {CRITERION_UNITS.map((u) => (
              <Center
                w={8}
                h={5}
                color={u === criterionUnit ? 'white' : 'inherit'}
                transition="color 0.2s ease-out"
                position="relative"
                zIndex={Z_INDEX.main_canvas.inspection_sheet.criterion_units}
              >
                {u}
              </Center>
            ))}
          </VStack>
        )}
      </Center>
      <HStack height={84} alignItems="stretch">
        <VStack flex={1}>
          <Text color={VALUE_HEADER_COLOR}>
            {t('main_canvas.inspection_sheet.tolerances.tolerance', { ns: 'projects' })}
          </Text>
          <Stack
            spacing={0}
            direction={criterionUnit ? 'row' : 'column'}
            alignItems="center"
            flex={1}
            pb={criterionUnit ? 2 : 0}
          >
            {contracteeCriterionValueSetter &&
              renderTextInput(
                `${header}.規格値`,
                contracteeCriterionValue?.toString() || '-',
                contracteeCriterionValueSetter,
                false,
                true,
                contracteeCriterionValue !== undefined
                  ? `±${contracteeCriterionValue}${criterionUnitIndex === 0 && criterionUnit ? criterionUnit : ''}`
                  : '-',
                false,
                true,
                contractorCriterionValue,
                t('main_canvas.inspection_sheet.message_constraint_tolerance', { ns: 'projects' }),
                undefined,
                undefined,
                criterionUnitIndex === 0 ? undefined : criterionUnit
              )}
            {!contracteeCriterionValueSetter && <Text>±{contracteeCriterionValue}</Text>}
            {criterionUnit && !contracteeCriterionValueSetter && <Text>{criterionUnit}</Text>}
            {!criterionUnit && !contracteeCriterionValueSetter && <Text>{unit.length ? `（${unit}）` : `（-）`}</Text>}
          </Stack>
        </VStack>
        <VStack flex={1}>
          <Text color={VALUE_HEADER_COLOR}>
            {t('main_canvas.inspection_sheet.tolerances.tolerance_internal', { ns: 'projects' })}
          </Text>
          <Stack
            spacing={0}
            direction={criterionUnit ? 'row' : 'column'}
            alignItems="center"
            flex={1}
            pb={criterionUnit ? 2 : 0}
          >
            {contractorCriterionValueSetter &&
              renderTextInput(
                `${header}.社内規格値`,
                contractorCriterionValue?.toString() || '-',
                contractorCriterionValueSetter,
                false,
                true,
                contractorCriterionValue !== undefined
                  ? `±${contractorCriterionValue}${criterionUnitIndex === 0 && criterionUnit ? criterionUnit : ''}`
                  : '-',
                false,
                true,
                undefined,
                undefined,
                contracteeCriterionValue,
                t('main_canvas.inspection_sheet.message_constraint_internal', { ns: 'projects' }),
                criterionUnitIndex === 0 ? undefined : criterionUnit
              )}
            {!contractorCriterionValueSetter && <Text>±{contractorCriterionValue}</Text>}
            {criterionUnit && !contractorCriterionValueSetter && <Text>{criterionUnit}</Text>}
            {!criterionUnit && !contractorCriterionValueSetter && <Text>{unit.length ? `（${unit}）` : `（-）`}</Text>}
          </Stack>
        </VStack>
      </HStack>
    </VStack>
  )

  /**
   * returns component for the header of the value columns.
   * It consists of 3 columns: specification, actual, and difference.
   *
   * @param {number} COLUMN_WIDTH - minimum width of the columns.
   * @param {string} unit - unit to display in the 3 columns.
   */
  const renderValueHeadersColumn = (COLUMN_WIDTH: number, unit: string) => (
    <VStack
      minWidth={COLUMN_WIDTH}
      alignItems="stretch"
      borderColor={LINE_COLOR}
      borderTopWidth={1}
      borderRightWidth={0}
    >
      <HStack height={84} alignItems="flex-end" paddingBottom={1} spacing={0}>
        <>
          <VStack color={VALUE_HEADER_COLOR} flex={1}>
            <Text>{t('main_canvas.inspection_sheet.values.specification', { ns: 'projects' })}</Text>
            <Text>{unit.length ? `（${unit}）` : `（-）`}</Text>
          </VStack>
          <VStack color={VALUE_HEADER_COLOR} flex={1}>
            <Text>{t('main_canvas.inspection_sheet.values.actual', { ns: 'projects' })}</Text>
            <Text>{unit.length ? `（${unit}）` : `（-）`}</Text>
          </VStack>
          <VStack color={VALUE_HEADER_COLOR} flex={1}>
            <Text>{t('main_canvas.inspection_sheet.values.difference', { ns: 'projects' })}</Text>
            <Text>{unit.length ? `（${unit}）` : `（-）`}</Text>
          </VStack>
        </>
      </HStack>
    </VStack>
  )

  /**
   * returns component for the header of the result columns.
   * It consists of 2 columns: results judged with the criteria
   * (contractee and contractor).
   *
   * @param {number} COLUMN_WIDTH - minimum width of the columns.
   */
  const renderResultHeadersColumn = (COLUMN_WIDTH: number) => (
    <VStack
      minWidth={COLUMN_WIDTH}
      alignItems="stretch"
      borderColor={LINE_COLOR}
      borderTopWidth={1}
      borderRightWidth={1}
    >
      <HStack height={84} alignItems="flex-end" paddingBottom={1.5} spacing={0}>
        <>
          <VStack color={VALUE_HEADER_COLOR} flex={1}>
            <Text>{t('main_canvas.inspection_sheet.result.result', { ns: 'projects' })}</Text>
            <Text fontSize="xs">（{t('main_canvas.inspection_sheet.tolerances.tolerance', { ns: 'projects' })}）</Text>
          </VStack>
          <VStack color={VALUE_HEADER_COLOR} flex={1}>
            <Text>{t('main_canvas.inspection_sheet.result.result', { ns: 'projects' })}</Text>
            <Text fontSize="xs">
              （{t('main_canvas.inspection_sheet.tolerances.tolerance_internal', { ns: 'projects' })}）
            </Text>
          </VStack>
        </>
      </HStack>
    </VStack>
  )

  /**
   * returns component for the rows in inspection item name column.
   *
   * @param {string} name - name of the inspection item.
   * @param {boolean} needUpdate - whether the item needs to be updated.
   * If true, the row is highlighted and the tooltip appears.
   */
  const renderNameColumn = (name: string, needUpdate: boolean) => (
    <HStack
      minWidth={NAME_COLUMN_WIDTH - 1}
      maxWidth={NAME_COLUMN_WIDTH - 1}
      alignItems="stretch"
      borderColor={LINE_COLOR}
      borderBottomWidth={1}
      p={1}
      width="100%"
      backgroundColor={needUpdate ? ALERT_BACKGROUND_COLOR : 'transparent'}
    >
      {needUpdate && (
        <Tooltip
          hasArrow
          placement="right"
          label={t('main_canvas.inspection_sheet.message_rebar_deleted', { ns: 'projects' })}
          fontSize="xs"
          fontWeight="normal"
        >
          <Flex height="100%" alignItems="center" pb={1}>
            <AlertFillIcon color={ALERT_COLOR} />
          </Flex>
        </Tooltip>
      )}
      <Text px={1} flex={1} textAlign="right" title={name} isTruncated>
        {name}
      </Text>
    </HStack>
  )

  /**
   * returns component for the rows in values columns.
   * It consists of 3 columns: specified value, estimated value, and difference.
   *
   * @param {(InspectionItemNumberValues | null)} values - values of the inspection item.
   * @param {InspectionItemEvaluation} result - judgment result of the item (OK/NG).
   * @param {boolean} needUpdate - whether the item needs to be updated.
   * @param {?boolean} needScaleValueUp - whether to scale up the value.
   * @param {?boolean} forDiameter - whether the value is for diameter.
   */
  const renderValuesColumn = (
    values: InspectionItemNumberValues | null,
    result: InspectionItemEvaluation,
    needUpdate: boolean,
    needScaleValueUp?: boolean,
    forDiameter?: boolean
  ) => {
    let specifiedValue = '-'
    let estimatedValue = '-'
    if (values !== null) {
      if (values.specified_value !== undefined && values.specified_value !== null) {
        const value = needScaleValueUp ? meterToMillimeter(values.specified_value) : values.specified_value
        specifiedValue = forDiameter ? findDiameterKeyByValue(value) : value.toString()
      }
    }
    if (values !== null) {
      if (values.estimated_value !== undefined && values.estimated_value !== null) {
        const value = needScaleValueUp ? meterToMillimeter(values.estimated_value) : values.estimated_value
        estimatedValue = forDiameter ? findDiameterKeyByValue(value) : value.toString()
      }
    }

    return (
      <VStack
        minWidth={VALUE_COLUMN_WIDTH}
        alignItems="stretch"
        borderColor={LINE_COLOR}
        borderBottomWidth={1}
        py={1}
        backgroundColor={needUpdate ? ALERT_BACKGROUND_COLOR : 'transparent'}
      >
        <HStack alignItems="flex-end" paddingBottom={1}>
          <Text flex={1} textAlign="center">
            {specifiedValue}
          </Text>
          <Text flex={1} textAlign="center">
            {estimatedValue}
          </Text>
          <Text flex={1} textAlign="center" color={result.color} title={result.description}>
            {formatInspectionDifferentValue(values, needScaleValueUp)}
          </Text>
        </HStack>
      </VStack>
    )
  }

  /**
   * returns component for the rows in results columns.
   * It consists of 2 columns: results judged with the criteria
   * for contractee and contractor.
   *
   * @param {(InspectionItemEvaluation | undefined)} results - judgment result of the item (OK/NG).
   * @param {boolean} needUpdate - whether the item needs to be updated.
   */
  const renderResultsColumn = (results: InspectionItemEvaluation | undefined, needUpdate: boolean) => (
    <VStack
      minWidth={RESULT_COLUMN_WIDTH - 1}
      alignItems="stretch"
      borderColor={LINE_COLOR}
      borderBottomWidth={1}
      p={1}
      backgroundColor={needUpdate ? ALERT_BACKGROUND_COLOR : 'transparent'}
    >
      <HStack alignItems="flex-end" paddingBottom={1} spacing={0}>
        {/* contractee */}
        <Text
          flex={1}
          textAlign="center"
          color={results?.qualifiedByContractee ? INSPECTION_NORMAL_COLOR : INSPECTION_CONTRACTEE_ERROR_COLOR}
        >
          {results?.qualifiedByContractee
            ? t('main_canvas.inspection_sheet.result.ok', { ns: 'projects' })
            : t('main_canvas.inspection_sheet.result.ng', { ns: 'projects' })}
        </Text>
        {/* contractor */}
        <Text
          flex={1}
          textAlign="center"
          color={results?.qualifiedByContractor ? INSPECTION_NORMAL_COLOR : INSPECTION_CONTRACTOR_ERROR_COLOR}
        >
          {results?.qualifiedByContractor
            ? t('main_canvas.inspection_sheet.result.ok', { ns: 'projects' })
            : t('main_canvas.inspection_sheet.result.ng', { ns: 'projects' })}
        </Text>
      </HStack>
    </VStack>
  )

  const renderItemModifyButton = (
    item: InspectionItem,
    index: number,
    forDeleting?: boolean,
    forRefetching?: boolean
  ) => {
    let icon = <InputEditorEditIcon />
    let backgroundColor = 'primary'

    if (forDeleting) {
      icon = <InputEditorCancelIcon />
      backgroundColor = 'red'
    }
    if (forRefetching) {
      icon = <ResetIcon />
      backgroundColor = ALERT_COLOR
    }

    return (
      <Center
        position="relative"
        width={ICON_WIDTH}
        flex="1"
        key={item.inspection_item_id}
        transition="opacity 0.1s ease-in"
        opacity={selectedItemIndex === index || selectedItemIndex < 0 ? 1 : 0.2}
        pointerEvents="auto"
      >
        {isAllowedModify && (
          <IconButton
            {...INPUT_EDITOR_ICON_PROPS}
            aria-label={forDeleting ? 'delete' : 'edit'}
            icon={icon}
            color="white"
            backgroundColor={backgroundColor}
            colorScheme={backgroundColor}
            onClick={(e: React.MouseEvent<HTMLElement>) => {
              e.stopPropagation()
              if (!item.inspection_item_id) {
                return
              }
              if (forDeleting) {
                deleteItem(item)
              } else if (forRefetching) {
                onItemRefetch(item)
              } else {
                onOpenItem(item)
              }
            }}
            disabled={isLoading || isLoadingSheet}
          />
        )}
      </Center>
    )
  }

  const getStartIndexOfCurrentPage = (pageIndex: number) => {
    if (pageIndex === 0) {
      return 0
    }
    return ITEMS_ON_FIRST_PAGE + (pageIndex - 1) * ITEMS_PER_PAGE
  }
  const filterPaginatedItems = (items: InspectionItem[] | InspectionItemEvaluationCollection[], pageIndex: number) => {
    if (pageIndex === 0) {
      return [...items].slice(0, ITEMS_ON_FIRST_PAGE)
    }

    const startIndex = getStartIndexOfCurrentPage(pageIndex)
    const endIndex = startIndex + ITEMS_PER_PAGE
    return [...items].slice(startIndex, endIndex)
  }

  const getPaginationButtonBorderColor = (index: number) => {
    if (currentPage === index) {
      return 'secondary.100'
    }
    const results = filterPaginatedItems(itemResults, index) as InspectionItemEvaluationCollection[]

    if (results.some((result) => !result.overall.qualifiedByContractee)) {
      return INSPECTION_CONTRACTEE_ERROR_COLOR
    }
    if (results.some((result) => !result.overall.qualifiedByContractor)) {
      return INSPECTION_CONTRACTOR_ERROR_COLOR
    }
    return 'secondary.100'
  }

  const getPaginationButtonAlertIcon = (index: number) => {
    const items = filterPaginatedItems(inspectionItems, index) as InspectionItem[]

    if (items.some((item) => item.need_update)) {
      return <AlertFillIcon color={ALERT_COLOR} />
    }
    return null
  }

  const getFileName = () =>
    project?.project_name || t('main_canvas.inspection_sheet.rebar_inspection_sheet', { ns: 'projects' })

  const onGenerateXLSX = async () => {
    if (!inspectionSheet) {
      return
    }

    const filename = sanitizeFilename(`${getFileName()}.xlsx`)
    await generateXLSX(
      filename,
      inspectionSheet,
      inspectionItems,
      itemResults,
      project,
      CRITERION_UNITS[indexMeanDistanceCriterion],
      CRITERION_UNITS[indexCoverDistanceCriterion],
      meanDistanceContracteeCriterion,
      meanDistanceContractorCriterion,
      coverDistanceContracteeCriterion,
      coverDistanceContractorCriterion,
      t('main_canvas.inspection_sheet', { ns: 'projects', returnObjects: true })
    )
  }

  if (!inspectionSheet) {
    return null
  }

  if (itemResults.length !== inspectionItems.length) {
    return null
  }

  return (
    <>
      <ModalBody pt={MODAL_PADDING} pr={MODAL_PADDING} pl={MODAL_PADDING}>
        <VStack
          borderColor={selectedItemIndex >= 0 ? 'transparent' : LINE_COLOR}
          transition="border-color 0.1s ease-in"
          borderWidth={1}
          p={MODAL_PADDING}
        >
          <Text
            fontSize={20}
            fontWeight="bold"
            letterSpacing={i18n.language === LANGUAGES.JA ? 16 : 'auto'}
            textTransform="uppercase"
            textAlign="center"
            pt={4}
            pb={6}
            transition="opacity 0.1s ease-in"
            opacity={selectedItemIndex >= 0 ? 0 : 1}
          >
            {t('main_canvas.inspection_sheet.rebar_inspection_sheet', { ns: 'projects' })}
          </Text>
          {currentPage === 0 && (
            <HStack
              width="100%"
              justifyContent="space-around"
              alignItems="flex-start"
              pb={8}
              transition="opacity 0.1s ease-in"
              opacity={selectedItemIndex >= 0 ? 0 : 1}
            >
              <VStack spacing={2} minWidth={PROFILE_INPUT_WIDTH}>
                {renderTextInput(
                  t('main_canvas.inspection_sheet.sheet_property.construction_project_name', { ns: 'projects' }),
                  inspectionSheet.construction_properties.construction_project_name || '',
                  (newValue: string | number) => {
                    updateSheetValue('construction_project_name', newValue, 'construction_properties')
                  }
                )}
                {renderTextInput(
                  t('main_canvas.inspection_sheet.sheet_property.construction_type_name', { ns: 'projects' }),
                  inspectionSheet.construction_properties.construction_type || '',
                  (newValue: string | number) => {
                    updateSheetValue('construction_type', newValue, 'construction_properties')
                  }
                )}
                {renderTextInput(
                  t('main_canvas.inspection_sheet.sheet_property.construction_detail', { ns: 'projects' }),
                  inspectionSheet.construction_properties.construction_type_detailed || '',
                  (newValue: string | number) => {
                    updateSheetValue('construction_type_detailed', newValue, 'construction_properties')
                  }
                )}
              </VStack>
              <VStack spacing={2} minWidth={PROFILE_INPUT_WIDTH}>
                {renderTextInput(
                  t('main_canvas.inspection_sheet.sheet_property.inspection_area_name', { ns: 'projects' }),
                  project?.project_name || '',
                  () => null,
                  true
                )}
                {renderTextInput(
                  t('main_canvas.inspection_sheet.sheet_property.creator', { ns: 'projects' }),
                  inspectionSheet.creator_name || '',
                  (newValue: string | number) => {
                    updateSheetValue('creator_name', newValue)
                  }
                )}
                {renderTextInput(
                  t('main_canvas.inspection_sheet.sheet_property.creation_day', { ns: 'projects' }),
                  inspectionSheet.create_time_user_specified || '',
                  (newValue: string | number) => {
                    updateSheetValue('create_time_user_specified', newValue || null)
                  },
                  false,
                  false,
                  inspectionSheet.create_time_user_specified
                    ? dayjs(inspectionSheet.create_time_user_specified).format('YYYY/MM/DD')
                    : '',
                  true
                )}
              </VStack>
            </HStack>
          )}
          <Box position="relative" width={`calc(100% + ${MODAL_PADDING * 4 * 2}px)`} paddingX={MODAL_PADDING}>
            <Box width="100%" overflow="auto">
              <HStack
                minWidth={ROW_WIDTH}
                alignItems="flex-end"
                spacing={0}
                divider={<StackDivider borderColor={LINE_COLOR} />}
                transition="opacity 0.1s ease-in"
                opacity={selectedItemIndex >= 0 ? 0 : 1}
              >
                <Box minWidth={NAME_COLUMN_WIDTH} maxWidth={NAME_COLUMN_WIDTH} />
                {renderCriterionHeadersColumn(
                  t('main_canvas.inspection_sheet.inspection_quantity.diameter', { ns: 'projects' }),
                  VALUE_COLUMN_WIDTH,
                  inspectionSheet.criterion_contractee?.diameter,
                  inspectionSheet.criterion_contractor.diameter,
                  'mm'
                )}
                {renderCriterionHeadersColumn(
                  t('main_canvas.inspection_sheet.inspection_quantity.number', { ns: 'projects' }),
                  VALUE_COLUMN_WIDTH,
                  inspectionSheet.criterion_contractee?.number,
                  inspectionSheet.criterion_contractor.number,
                  t('main_canvas.inspection_sheet.unit_rebar', { ns: 'projects' })
                )}
                {renderCriterionHeadersColumn(
                  t('main_canvas.inspection_sheet.inspection_quantity.mean_spacing', { ns: 'projects' }),
                  VALUE_COLUMN_WIDTH,
                  meanDistanceContracteeCriterion,
                  meanDistanceContractorCriterion,
                  '',
                  CRITERION_UNITS[indexMeanDistanceCriterion],
                  indexMeanDistanceCriterion,
                  () => {
                    // switch criterion unit
                    setCookie(EDITOR_MEAN_DISTANCE_CRITERION_UNIT_INDEX_COOKIE_NAME, 1 - indexMeanDistanceCriterion, {
                      expires: COOKIE_EXPIRE,
                    })
                    // for safety, reset the editing state
                    setEditingField('')
                  },
                  (newValue: string | number) => {
                    if (indexMeanDistanceCriterion === 0) {
                      updateSheetValue('mean_distance_ratio', newValue, 'criterion_contractee')
                    } else {
                      updateSheetValue(
                        'mean_distance_abs',
                        millimeterToMeter(newValue as number),
                        'criterion_contractee'
                      )
                    }
                  },
                  (newValue: string | number) => {
                    if (indexMeanDistanceCriterion === 0) {
                      updateSheetValue('mean_distance_ratio', newValue, 'criterion_contractor')
                    } else {
                      updateSheetValue(
                        'mean_distance_abs',
                        millimeterToMeter(newValue as number),
                        'criterion_contractor'
                      )
                    }
                  }
                )}
                {renderCriterionHeadersColumn(
                  t('main_canvas.inspection_sheet.inspection_quantity.cover_thickness', { ns: 'projects' }),
                  VALUE_COLUMN_WIDTH,
                  coverDistanceContracteeCriterion,
                  coverDistanceContractorCriterion,
                  '',
                  CRITERION_UNITS[indexCoverDistanceCriterion],
                  indexCoverDistanceCriterion,
                  () => {
                    // switch criterion unit
                    setCookie(EDITOR_COVER_DISTANCE_CRITERION_UNIT_INDEX_COOKIE_NAME, 1 - indexCoverDistanceCriterion, {
                      expires: COOKIE_EXPIRE,
                    })
                    // for safety, reset the editing state
                    setEditingField('')
                  },
                  (newValue: string | number) => {
                    if (indexCoverDistanceCriterion === 0) {
                      updateSheetValue('cover_distance_ratio', newValue, 'criterion_contractee')
                    } else {
                      updateSheetValue(
                        'cover_distance_abs',
                        millimeterToMeter(newValue as number),
                        'criterion_contractee'
                      )
                    }
                  },
                  (newValue: string | number) => {
                    if (indexCoverDistanceCriterion === 0) {
                      updateSheetValue('cover_distance_ratio', newValue, 'criterion_contractor')
                    } else {
                      updateSheetValue(
                        'cover_distance_abs',
                        millimeterToMeter(newValue as number),
                        'criterion_contractor'
                      )
                    }
                  }
                )}
                <Box minWidth={RESULT_COLUMN_WIDTH} />
              </HStack>
              <HStack
                minWidth={ROW_WIDTH}
                alignItems="flex-end"
                spacing={0}
                divider={<StackDivider borderColor={LINE_COLOR} />}
                transition="opacity 0.1s ease-in"
                opacity={selectedItemIndex >= 0 ? 0 : 1}
              >
                <Box minWidth={NAME_COLUMN_WIDTH} maxWidth={NAME_COLUMN_WIDTH} />
                {renderValueHeadersColumn(VALUE_COLUMN_WIDTH, 'mm')}
                {renderValueHeadersColumn(
                  VALUE_COLUMN_WIDTH,
                  t('main_canvas.inspection_sheet.unit_rebar', { ns: 'projects' })
                )}
                {renderValueHeadersColumn(VALUE_COLUMN_WIDTH, 'mm')}
                {renderValueHeadersColumn(VALUE_COLUMN_WIDTH, 'mm')}
                {renderResultHeadersColumn(RESULT_COLUMN_WIDTH)}
              </HStack>
              <HStack
                minWidth={ROW_WIDTH}
                spacing={0}
                divider={<StackDivider borderColor={LINE_COLOR} />}
                borderColor={LINE_COLOR}
                borderTopWidth={1}
                transition="opacity 0.1s ease-in"
                opacity={selectedItemIndex >= 0 ? 0 : 1}
              >
                <Box width={0} />
                <Box minWidth={NAME_COLUMN_WIDTH - 1} maxWidth={NAME_COLUMN_WIDTH - 1}>
                  <Text px={1} pt={1} color={VALUE_HEADER_COLOR} textAlign="right">
                    {t('main_canvas.inspection_sheet.item', { ns: 'projects' })}
                  </Text>
                </Box>
                <Box minWidth={VALUE_COLUMN_WIDTH} />
                <Box minWidth={VALUE_COLUMN_WIDTH} />
                <Box minWidth={VALUE_COLUMN_WIDTH} />
                <Box minWidth={VALUE_COLUMN_WIDTH} />
                <Box minWidth={RESULT_COLUMN_WIDTH - 1} />
                <Box width={0} />
              </HStack>
              {(filterPaginatedItems(inspectionItems, currentPage) as InspectionItem[]).map((item, index) => {
                const currentIndex = getStartIndexOfCurrentPage(currentPage) + index
                return (
                  <HStack
                    cursor="pointer"
                    minWidth={ROW_WIDTH}
                    alignItems="stretch"
                    spacing={0}
                    divider={<StackDivider borderColor={LINE_COLOR} />}
                    key={item.inspection_item_id}
                    backgroundColor="white"
                    onClick={() => {
                      if (selectedItemIndex === index) {
                        onItemSelected(null)
                        setSelectedItemIndex(-1)
                      } else {
                        onItemSelected(item)
                        setSelectedItemIndex(index)
                      }
                    }}
                    transition="opacity 0.1s ease-in"
                    opacity={selectedItemIndex === index || selectedItemIndex < 0 ? 1 : 0.2}
                    position="relative"
                    zIndex={Z_INDEX.main_canvas.inspection_sheet.inspection_item_hstack}
                    pointerEvents="auto"
                  >
                    <Box />
                    {renderNameColumn(item.part_name, item.need_update)}
                    {renderValuesColumn(
                      item.diameter,
                      itemResults[currentIndex].diameter,
                      item.need_update,
                      true,
                      true
                    )}
                    {renderValuesColumn(item.number, itemResults[currentIndex].number, item.need_update)}
                    {renderValuesColumn(
                      item.mean_distance,
                      itemResults[currentIndex].meanDistance,
                      item.need_update,
                      true
                    )}
                    {renderValuesColumn(
                      item.cover_distance,
                      itemResults[currentIndex].coverDistance,
                      item.need_update,
                      true
                    )}
                    {renderResultsColumn(itemResults[currentIndex].overall, item.need_update)}
                    <Box />
                  </HStack>
                )
              })}
              {!filterPaginatedItems(inspectionItems, currentPage).length && (
                <Box w="100%" borderTopWidth={1} borderColor={LINE_COLOR} />
              )}
            </Box>
            {/* Delete buttons */}
            <VStack
              position="absolute"
              left={0}
              top={254}
              bottom={0}
              spacing={0}
              width={MODAL_PADDING}
              zIndex={Z_INDEX.main_canvas.inspection_sheet.inspection_item_icons}
            >
              {(filterPaginatedItems(inspectionItems, currentPage) as InspectionItem[]).map((item, index) =>
                renderItemModifyButton(item, index, true)
              )}
            </VStack>
            {/* Edit buttons */}
            <VStack
              position="absolute"
              right={0}
              top={254}
              bottom={0}
              spacing={0}
              width={MODAL_PADDING}
              zIndex={Z_INDEX.main_canvas.inspection_sheet.inspection_item_icons}
            >
              {(filterPaginatedItems(inspectionItems, currentPage) as InspectionItem[]).map((item, index) =>
                renderItemModifyButton(item, index, false, item.need_update)
              )}
            </VStack>
          </Box>
        </VStack>
        {isAllowedModify && selectedItemIndex < 0 && (
          <XMLImportButton
            props={{ ...INPUT_EDITOR_ICON_PROPS, 'aria-label': 'import-xml' }}
            position={MODAL_PADDING - ICON_WIDTH / 2}
            disabled={isLoading || isLoadingSheet}
            inspectionSheetId={inspectionSheet.inspection_sheet_id}
            onConfirm={(result) => {
              if (result) {
                onSheetUpdated()
              }
            }}
          />
        )}
        <HStack
          spacing={1}
          mt={1}
          justifyContent="flex-end"
          transition="opacity 0.1s ease-in"
          opacity={selectedItemIndex >= 0 ? 0 : 1}
        >
          {Array(totalPage)
            .fill(null)
            .map((emptyValue, index) => ({ id: `item-${index}` }))
            .map((item, index) => (
              <Button
                key={item.id}
                size="xs"
                backgroundColor={currentPage === index ? 'secondary.100' : 'transparent'}
                borderColor={getPaginationButtonBorderColor(index)}
                borderWidth={currentPage === index ? 0 : 1}
                onClick={() => setCurrentPage(index)}
                lineHeight={4}
                borderRadius={0}
                position="relative"
              >
                <Box position="absolute" opacity={0.5} pb={0.5} fontSize="md">
                  {getPaginationButtonAlertIcon(index)}
                </Box>
                <Text position="relative">{index + 1}</Text>
              </Button>
            ))}
        </HStack>
      </ModalBody>

      <ModalFooter justifyContent="center" transition="opacity 0.1s ease-in" opacity={selectedItemIndex >= 0 ? 0 : 1}>
        {canClose && (
          <>
            <Button disabled={isLoading || isLoadingSheet} me={3} py={2} minW="100px" onClick={() => onClose()}>
              {t('main_canvas.inspection_sheet.menu_bottom.close', { ns: 'projects' })}
            </Button>
            <Button
              disabled={isLoading || isLoadingSheet}
              me={3}
              py={2}
              minW="100px"
              onClick={() => onOpenBlackboard()}
            >
              {t('main_canvas.inspection_sheet.menu_bottom.show_blackboard', { ns: 'projects' })}
            </Button>
          </>
        )}
        {!canClose && (
          <>
            <Button
              as={RouterLink}
              to="/dashboard"
              disabled={isLoading || isLoadingSheet}
              me={3}
              py={2}
              minW="100px"
              onClick={() => onClose()}
            >
              {t('main_canvas.inspection_sheet.menu_bottom.back', { ns: 'projects' })}
            </Button>
            <Button
              as={RouterLink}
              to={`/projects/${project_id || ''}/blueprint`}
              disabled={isLoading || isLoadingSheet}
              me={3}
              py={2}
              minW="100px"
              onClick={() => onClose()}
            >
              {t('main_canvas.inspection_sheet.menu_bottom.move_to_blueprint', { ns: 'projects' })}
            </Button>
          </>
        )}
        {isAllowedModify && (
          <Button disabled={isLoading || isLoadingSheet} me={3} py={2} minW="100px" onClick={() => onItemAdded()}>
            {t('main_canvas.inspection_sheet.menu_bottom.add_item', { ns: 'projects' })}
          </Button>
        )}
        {isAllowedModify && !canClose && (
          <Button
            disabled={isLoading || isLoadingSheet}
            me={3}
            py={2}
            minW="100px"
            onClick={() => openUpdateProjectModal()}
          >
            {t('main_canvas.inspection_sheet.menu_bottom.upload_pcd', { ns: 'projects' })}
          </Button>
        )}
        {isAllowedDownload && (
          <Menu autoSelect={false}>
            <MenuButton
              as={Button}
              rightIcon={<ChevronDownIcon />}
              minW="100px"
              colorScheme="primary"
              disabled={isLoading || isLoadingSheet}
            >
              {t('main_canvas.inspection_sheet.menu_bottom.download', { ns: 'projects' })}
            </MenuButton>
            <MenuList zIndex={Z_INDEX.main_canvas.inspection_sheet.download_menu}>
              <MenuItem
                as={PDFDownloadLink}
                document={
                  <MainSheetPDF
                    project={project}
                    inspectionSheet={inspectionSheet}
                    inspectionItems={inspectionItems}
                    itemResults={itemResults}
                    meanDistanceCriterionUnit={CRITERION_UNITS[indexMeanDistanceCriterion]}
                    coverDistanceCriterionUnit={CRITERION_UNITS[indexCoverDistanceCriterion]}
                    meanDistanceContracteeCriterion={meanDistanceContracteeCriterion}
                    meanDistanceContractorCriterion={meanDistanceContractorCriterion}
                    coverDistanceContracteeCriterion={coverDistanceContracteeCriterion}
                    coverDistanceContractorCriterion={coverDistanceContractorCriterion}
                  />
                }
                fileName={`${getFileName()}.pdf`}
                _hover={{ backgroundColor: 'gray.100' }}
                onClick={() => {
                  // track event to mixpanel
                  mixpanel.track('Export inspection sheet', {
                    'Inspection area ID': project_id,
                    'File format': 'pdf',
                    'Criterion unit (mean distance)': CRITERION_UNITS[indexMeanDistanceCriterion],
                    'Criterion unit (cover distance)': CRITERION_UNITS[indexCoverDistanceCriterion],
                  })
                }}
              >
                <Text color="gray.600">PDF</Text>
              </MenuItem>
              <MenuItem onClick={onGenerateXLSX}>XLSX</MenuItem>
              {inspectionSheet.external_sheet_id !== 'n/a' && inspectionSheet.external_sheet_schema === 'musashi' && (
                <MenuItem onClick={() => onGenerateXML()}>XML</MenuItem>
              )}
            </MenuList>
          </Menu>
        )}
      </ModalFooter>
    </>
  )
}

export default MainSheetModal
