import { createContext, useCallback, useContext, useEffect, useState } from 'react'

import { useAuth0 } from '@auth0/auth0-react'
import { Text } from '@chakra-ui/react'
import mixpanel from 'mixpanel-browser'
import { useTranslation } from 'react-i18next'

import { MODAL_TYPES } from 'config/constants'

import { Organization, UserContextProps } from 'interfaces/interfaces'

import { ERROR_PROCESS, processErrorHandler } from 'services/ErrorHandler'
import { getUser } from 'services/Users'

import { GlobalModalContext } from './GlobalModal'

//* アプリ全体としてユーザー情報を管理するプロバイダーの生成
export const UserContext = createContext<UserContextProps>({
  userLoaded: false,
  userType: '',
  userTypeForOrganizations: {},
  subscriptionId: '',
  organizations: [],
  refreshAccessToken: () => null,
  getAccessToken: async () => Promise.resolve(''),
  fetchUserInfo: async () => Promise.resolve(null),
} as UserContextProps)

//* プロバイダーに渡すユーザー情報の項目や操作処理
export function useUserContext(): UserContextProps {
  const { showErrorModal, showModal } = useContext(GlobalModalContext)
  const { error, logout } = useAuth0()
  const { i18n } = useTranslation()

  const handleUserNotFound = () => {
    showModal({
      body: (
        <>
          <Text>Modelyのアカウントを持っていないため、ログアウトします。</Text>
          <Text>
            Modelyの閲覧用アカウントを作成するには、右上の
            <b>「新規登録」</b>
            ボタンを押してください。
          </Text>
          <Text>
            有償のアカウントを作成するには、弊社の営業
            <b>（sales@datalabs.jp）</b>
            に連絡してください。
          </Text>
        </>
      ),
      modalType: MODAL_TYPES.CONFIRMATION_CRITICAL,
      unClosable: true,
      size: '2xl',
      confirmText: 'ログアウト',
      onConfirm: () => {
        logout({ returnTo: window.location.origin })
        return false
      },
    })
  }

  useEffect(() => {
    if (error) {
      handleUserNotFound()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error])

  //* 初期ロード管理
  const [userLoggedIn, setUserLoggedIn] = useState(false)
  const [userLoaded, setUserLoaded] = useState(false)
  const [userType, setUserType] = useState('')
  const [userTypeForOrganizations, setUserTypeForOrganizations] = useState<{ [key: string]: string }>({})
  const [subscriptionId, setSubscriptionId] = useState('')
  const [organizations, setOrganizations] = useState<Organization[]>([])
  const { user, getAccessTokenSilently } = useAuth0()

  //* ユーザ情報の有無確認
  const refreshAccessToken = useCallback(async () => {
    await getAccessTokenSilently()
  }, [getAccessTokenSilently])

  //* アクセストークン取得
  const getAccessToken = useCallback(
    async (): Promise<string> =>
      getAccessTokenSilently().catch((err) => {
        processErrorHandler(err, ERROR_PROCESS.GET_ACCESS_TOKEN, showErrorModal)
        return ''
      }),
    [getAccessTokenSilently, showErrorModal]
  )

  //* 初回ロード
  useEffect(() => {
    if (user && !userLoggedIn) {
      refreshAccessToken()
        .then(() => {
          setUserLoggedIn(true)
        })
        .catch((err) => {
          processErrorHandler(err, ERROR_PROCESS.GET_ACCESS_TOKEN, showErrorModal)
        })
    }
  }, [userLoggedIn, refreshAccessToken, user, showErrorModal])

  //* Check user information
  const fetchUserInfo = useCallback(async () => {
    if (userLoggedIn && user?.sub) {
      const access_token = await getAccessTokenSilently().catch((err) => {
        processErrorHandler(err, ERROR_PROCESS.GET_ACCESS_TOKEN, showErrorModal)
        return ''
      })

      if (!access_token) {
        return false
      }

      const userInfo = await getUser(access_token, user.sub, (message: string) => {
        // user not found
        if (message === '40401') {
          handleUserNotFound()
        } else {
          showErrorModal(message, true)
        }
      })
      if (!userInfo?.user_id) {
        return false
      }

      setUserType(userInfo.user_type)
      setUserTypeForOrganizations((userInfo.organization_ids as { [key: string]: string }) || {})
      setSubscriptionId(userInfo.subscription_id || '')
      setOrganizations(Object.values(userInfo.organization_details || []))
      await i18n.changeLanguage(userInfo.language_preference || i18n.resolvedLanguage)
      setUserLoaded(true)

      // register user for mixpanel
      mixpanel.identify(userInfo.user_id)
      mixpanel.people.set({ 'User type': userInfo.user_type, $name: user.email, $email: user.email })

      return true
    }

    return false
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAccessTokenSilently, i18n, showErrorModal, user, userLoggedIn])

  useEffect(() => {
    void fetchUserInfo()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userLoggedIn, user])

  return {
    userLoaded,
    userType,
    userTypeForOrganizations,
    subscriptionId,
    organizations,
    refreshAccessToken,
    getAccessToken,
    fetchUserInfo,
  }
}
