import { FC, useContext } from 'react'

import { StackDivider, VStack } from '@chakra-ui/react'
import { isTablet } from 'react-device-detect'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { RootState } from 'store/app'

import {
  ToolbarCommentCuboidIcon,
  ToolbarCommentIcon,
  ToolbarCylinderCuboidIcon,
  ToolbarCylinderIcon,
  ToolbarDistanceIcon,
  ToolbarMoveIcon,
  ToolbarPlaneIcon,
  ToolbarPlaneVirtualIcon,
  ToolbarSpacerAnnotationIcon,
  ToolbarTorusCuboidIcon,
  ToolbarTorusIcon,
  ToolbarTrimPCDCuboidIcon,
} from 'assets/icons'

import { EditorContext } from 'contexts/Editor'

import { EDITOR_TOOLS } from 'config/constants'

import ToolbarButton from '../../components/ToolbarButton'
import HelpButton from './HelpButton'

const ButtonGroup: FC<{ buttonSize: number; showRightCorner?: boolean }> = ({
  buttonSize,
  showRightCorner,
  children,
}) => (
  <VStack
    backgroundColor="gray.800"
    overflow="hidden"
    borderRadius="md"
    divider={<StackDivider borderColor="whiteAlpha.200" />}
    spacing={0}
    w={buttonSize}
    borderBottomRightRadius={showRightCorner ? 0 : 'md'}
  >
    {children}
  </VStack>
)

const Toolbar: FC<{ isAllowedModify: boolean; isAllowedView: boolean }> = ({ isAllowedModify, isAllowedView }) => {
  const { t } = useTranslation(['projects'])
  const isJobRunning = useSelector((state: RootState) => state.editor.isJobRunning)
  const { selectedTool, changeTool } = useContext(EditorContext)

  const buttonSize = isTablet ? 16 : 8

  return (
    <VStack left={1} overflow="hidden" position="absolute" top={isTablet ? '78px' : '44px'} w={buttonSize} spacing={2}>
      <ButtonGroup buttonSize={buttonSize}>
        <ToolbarButton
          icon={<ToolbarMoveIcon width="50%" height="50%" />}
          label={t('main_canvas.toolbar.move_point', { ns: 'projects' })}
          testId="move"
          toolType={EDITOR_TOOLS.MOVE}
          selectedTool={selectedTool}
          changeTool={changeTool}
          buttonSize={buttonSize}
        />
      </ButtonGroup>
      {isAllowedModify && !isJobRunning && (
        <ButtonGroup buttonSize={buttonSize} showRightCorner>
          <ToolbarButton
            icon={<ToolbarCylinderIcon width="50%" height="50%" />}
            label={t('main_canvas.toolbar.detect_cylinder', { ns: 'projects' })}
            testId="detect-cylinder"
            toolType={EDITOR_TOOLS.CYLINDER}
            variants={[
              {
                icon: <ToolbarCylinderIcon width="50%" height="50%" />,
                toolType: EDITOR_TOOLS.CYLINDER,
                label: t('main_canvas.toolbar.detect_cylinder_manual', { ns: 'projects' }),
                testId: 'detect-cylinder-manual',
              },
              {
                icon: <ToolbarCylinderCuboidIcon width="50%" height="50%" />,
                toolType: EDITOR_TOOLS.CYLINDER_CUBOID,
                label: t('main_canvas.toolbar.detect_cylinder_auto', { ns: 'projects' }),
                testId: 'detect-cylinder-cuboid',
              },
            ]}
            selectedTool={selectedTool}
            changeTool={changeTool}
            buttonSize={buttonSize}
          />
          <ToolbarButton
            icon={<ToolbarTorusIcon width="50%" height="50%" />}
            label={t('main_canvas.toolbar.detect_torus', { ns: 'projects' })}
            testId="detect-torus"
            toolType={EDITOR_TOOLS.TORUS}
            variants={[
              {
                icon: <ToolbarTorusIcon width="50%" height="50%" />,
                toolType: EDITOR_TOOLS.TORUS,
                label: t('main_canvas.toolbar.detect_torus_manual', { ns: 'projects' }),
                testId: 'detect-torus-manual',
              },
              {
                icon: <ToolbarTorusCuboidIcon width="50%" height="50%" />,
                toolType: EDITOR_TOOLS.TORUS_CUBOID,
                label: t('main_canvas.toolbar.detect_torus_auto', { ns: 'projects' }),
                testId: 'detect-torus-cuboid',
              },
            ]}
            selectedTool={selectedTool}
            changeTool={changeTool}
            buttonSize={buttonSize}
          />
          <ToolbarButton
            icon={<ToolbarPlaneIcon width="50%" height="50%" />}
            label={t('main_canvas.toolbar.detect_plane', { ns: 'projects' })}
            testId="detect-plane"
            toolType={EDITOR_TOOLS.PLANE}
            variants={[
              {
                icon: <ToolbarPlaneIcon width="50%" height="50%" />,
                toolType: EDITOR_TOOLS.PLANE,
                label: t('main_canvas.toolbar.detect_plane_real', { ns: 'projects' }),
                testId: 'detect-plane-manual',
              },
              {
                icon: <ToolbarPlaneVirtualIcon width="50%" height="50%" />,
                toolType: EDITOR_TOOLS.PLANE_VIRTUAL,
                label: t('main_canvas.toolbar.detect_plane_virtual', { ns: 'projects' }),
                testId: 'detect-plane-virtual',
              },
            ]}
            selectedTool={selectedTool}
            changeTool={changeTool}
            buttonSize={buttonSize}
          />
        </ButtonGroup>
      )}
      {(isAllowedModify || isAllowedView) && (
        <ButtonGroup buttonSize={buttonSize}>
          {isAllowedModify && (
            <ToolbarButton
              icon={<ToolbarTrimPCDCuboidIcon size={isTablet ? 34 : 18} />}
              label={t('main_canvas.toolbar.trim_pcd', { ns: 'projects' })}
              testId="trim-pcd"
              toolType={EDITOR_TOOLS.PCD_TRIM_CUBOID}
              selectedTool={selectedTool}
              changeTool={changeTool}
              buttonSize={buttonSize}
            />
          )}
          {isAllowedModify && !isJobRunning && (
            <ToolbarButton
              icon={<ToolbarSpacerAnnotationIcon width="60%" height="60%" />}
              label={t('main_canvas.toolbar.annotate_spacer', { ns: 'projects' })}
              testId="detect-spacer-annotation"
              toolType={EDITOR_TOOLS.SPACER_ANNOTATION}
              selectedTool={selectedTool}
              changeTool={changeTool}
              buttonSize={buttonSize}
            />
          )}
          {isAllowedView && (
            <>
              <ToolbarButton
                icon={<ToolbarCommentIcon width="50%" height="50%" />}
                label={t('main_canvas.toolbar.comment', { ns: 'projects' })}
                testId="comment"
                toolType={EDITOR_TOOLS.COMMENT}
                selectedTool={selectedTool}
                changeTool={changeTool}
                variants={[
                  {
                    icon: <ToolbarCommentIcon width="50%" height="50%" />,
                    toolType: EDITOR_TOOLS.COMMENT,
                    label: t('main_canvas.toolbar.comment_point', { ns: 'projects' }),
                    testId: 'comment-on-point',
                  },
                  {
                    icon: <ToolbarCommentCuboidIcon width="50%" height="50%" />,
                    toolType: EDITOR_TOOLS.COMMENT_CUBOID,
                    label: t('main_canvas.toolbar.comment_cuboid', { ns: 'projects' }),
                    testId: 'comment-on-cuboid',
                  },
                ]}
                buttonSize={buttonSize}
              />
              <ToolbarButton
                icon={<ToolbarDistanceIcon width="60%" height="60%" />}
                label={t('main_canvas.toolbar.measure_distance', { ns: 'projects' })}
                testId="measure-distance"
                toolType={EDITOR_TOOLS.DISTANCE}
                selectedTool={selectedTool}
                changeTool={changeTool}
                buttonSize={buttonSize}
              />
            </>
          )}
        </ButtonGroup>
      )}
      <ButtonGroup buttonSize={buttonSize}>
        <HelpButton />
      </ButtonGroup>
    </VStack>
  )
}

export default Toolbar
