import { FC, useContext, useEffect, useState } from 'react'

import { useAuth0 } from '@auth0/auth0-react'
import {
  Button,
  Container,
  HStack,
  IconButton,
  Input,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Portal,
  Spacer,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react'
import Navbar from 'components/Navbar/Navbar'
import PageHeading from 'components/PageHeading'
import mixpanel from 'mixpanel-browser'
import { Trans, useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import { AddIcon, HDotsIcon, InputEditorCancelIcon, InputEditorConfirmIcon, InputEditorEditIcon } from 'assets/icons'

import { GlobalModalContext } from 'contexts/GlobalModal'
import { UserContext } from 'contexts/Users'

import { MODAL_TYPES } from 'config/constants'
import { CONTAINER_MAX_WIDTH } from 'config/styles'

import { removeUserFromOrganization, renameOrganization } from 'services/Organizations'
import { decideOrganizationManagePermission } from 'services/Validation'

import InviteUserModal from './InviteUserModal'

const Organization: FC = () => {
  const { t } = useTranslation(['organization'])
  const { user } = useAuth0()
  const { organizations, fetchUserInfo } = useContext(UserContext)
  const { organization_id } = useParams<{ organization_id: string }>()
  const { showModal, showErrorModal } = useContext(GlobalModalContext)
  const { getAccessToken } = useContext(UserContext)

  const currentOrganization = organizations.find((organization) => organization.organization_id === organization_id)
  const isOrganizationOwner = !!user?.sub && currentOrganization?.owner_users.includes(user.sub)
  const isOrganizationMember = !!user?.sub && currentOrganization?.organization_members.includes(user.sub)

  const permissions = decideOrganizationManagePermission(isOrganizationOwner, isOrganizationMember)
  const isAllowedBrowse = permissions.BROWSE
  const isAllowedModify = permissions.MODIFY
  const isAllowedManageUsers = permissions.MANAGE_USERS

  const [isInviteModalOpen, setIsInviteModalOpen] = useState(false)
  const [organizationName, setOrganizationName] = useState(currentOrganization?.organization_name || '')
  const [editingValue, setEditingValue] = useState('')
  const [editingField, setEditingField] = useState('')

  useEffect(() => {
    setOrganizationName(currentOrganization?.organization_name || '')
  }, [currentOrganization])

  const showRemoveUserConfirmation = (userId: string) => {
    showModal({
      body: <Trans i18nKey="remove_modal.text" ns="organization" />,
      confirmText: t('remove_modal.confirm'),
      modalType: MODAL_TYPES.CONFIRMATION_CRITICAL,
      onConfirm: () => {
        void (async () => {
          if (!currentOrganization) {
            return false
          }

          const token = await getAccessToken()
          if (!token) return false

          if (await removeUserFromOrganization(token, currentOrganization.organization_id, userId, showErrorModal)) {
            // track with mixpanel
            mixpanel.track('Remove user from organization', {
              'Organization ID': currentOrganization.organization_id,
              'Target user ID': userId,
              'Target user email': currentOrganization.organization_members_email[userId],
            })

            fetchUserInfo()
            return true
          }
          return false
        })()
        return true
      },
      title: t('remove_modal.title'),
    })
  }

  const handleUpdateOrganization = async () => {
    if (!currentOrganization) {
      return false
    }

    const token = await getAccessToken()
    if (!token) return false

    if (await renameOrganization(token, currentOrganization.organization_id, editingValue, showErrorModal)) {
      // track with mixpanel
      mixpanel.track('Rename organization', {
        'Organization ID': currentOrganization.organization_id,
        'Organization name (old)': currentOrganization.organization_name,
        'Organization name (new)': editingValue,
      })

      setOrganizationName(editingValue)
      return true
    }

    return false
  }

  return (
    <>
      <Navbar />

      {isAllowedBrowse && (
        <>
          <Container maxW={CONTAINER_MAX_WIDTH}>
            <PageHeading>{t('organization')}</PageHeading>
            <HStack fontSize="sm" fontWeight="semibold">
              <Text color="secondary.400" minW="92px">
                {t('organization_name')}：
              </Text>
              {!!editingField && (
                <>
                  <Input
                    value={editingValue}
                    maxW={200}
                    style={{
                      marginTop: -8,
                      marginBottom: -8,
                    }}
                    size="sm"
                    onChange={(e) => setEditingValue(e.target.value)}
                  />
                  <IconButton
                    aria-label="edit"
                    icon={<InputEditorCancelIcon />}
                    color="secondary.500"
                    backgroundColor="secondary.200"
                    borderColor="secondary.00"
                    borderWidth={1}
                    onClick={() => {
                      setEditingField('')
                    }}
                    width={6}
                    height={6}
                    minW={6}
                    minH={6}
                    borderRadius="full"
                  />
                  <IconButton
                    aria-label="edit"
                    icon={<InputEditorConfirmIcon />}
                    color="white"
                    backgroundColor="secondary.500"
                    colorScheme="secondary"
                    borderColor="secondary.00"
                    borderWidth={1}
                    onClick={async () => {
                      setEditingField('')
                      setEditingValue('')
                      await handleUpdateOrganization()
                    }}
                    width={6}
                    height={6}
                    minW={6}
                    minH={6}
                    borderRadius="full"
                    disabled={!editingValue}
                  />
                </>
              )}
              {!editingField && (
                <>
                  <Text>{organizationName}</Text>
                  {isAllowedModify && (
                    <IconButton
                      aria-label="edit"
                      icon={<InputEditorEditIcon />}
                      color="white"
                      borderColor="primary.500"
                      backgroundColor="primary.500"
                      colorScheme="primary"
                      onClick={() => {
                        setEditingField('name')
                        setEditingValue(organizationName)
                      }}
                      width={6}
                      height={6}
                      minW={6}
                      minH={6}
                      borderRadius="full"
                    />
                  )}
                </>
              )}
            </HStack>
            <Spacer />
            {isAllowedManageUsers && (
              <Button
                variant="outlinePrimary"
                size="md"
                rightIcon={<AddIcon />}
                mt={10}
                mb={7}
                onClick={() => setIsInviteModalOpen(true)}
              >
                {t('add_user')}
              </Button>
            )}
            <TableContainer mb={10}>
              <Table w="100%" size="sm" className="striped">
                <Thead fontSize="sm">
                  <Tr>
                    <Th>{t('user')}</Th>
                    {/* <Th>{t('license_assignment.status')}</Th> */}
                    <Th>{t('role.role')}</Th>
                    <Th w="1%" />
                  </Tr>
                </Thead>
                <Tbody>
                  {currentOrganization?.organization_members.map((member_id) => (
                    <Tr key={member_id}>
                      <Td>{currentOrganization?.organization_members_email[member_id] || ''}</Td>
                      {/* <Td>-</Td> */}
                      <Td>
                        {currentOrganization?.owner_users.includes(member_id) ? t('role.owner') : t('role.member')}
                      </Td>
                      <Td>
                        <Menu autoSelect={false}>
                          <MenuButton
                            variant="ghost"
                            as={IconButton}
                            aria-label="Actions"
                            fontSize="lg"
                            icon={<HDotsIcon />}
                            disabled={
                              currentOrganization?.owner_users.includes(member_id) || // can not remove owner
                              (!isAllowedManageUsers && member_id !== user?.sub) // can remove yourself even if you are not owner
                              // TODO: update the logic above once the email invitation is implemented, or license management is implemented
                            }
                          />
                          <Portal>
                            <MenuList>
                              <MenuItem
                                isDisabled={
                                  currentOrganization?.owner_users.includes(member_id) || // can not remove owner
                                  (!isAllowedManageUsers && member_id !== user?.sub) // can remove yourself even if you are not owner
                                  // TODO: update the logic above once the email invitation is implemented, or license management is implemented
                                }
                                onClick={() => showRemoveUserConfirmation(member_id)}
                              >
                                {t('remove')}
                              </MenuItem>
                            </MenuList>
                          </Portal>
                        </Menu>
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </TableContainer>
          </Container>
          {isAllowedManageUsers && (
            <InviteUserModal
              isOpen={isInviteModalOpen}
              onConfirm={(result) => {
                setIsInviteModalOpen(false)
                if (result) {
                  fetchUserInfo()
                }
              }}
              organizationId={currentOrganization?.organization_id || ''}
            />
          )}
        </>
      )}
    </>
  )
}
export default Organization
