import { FC, useContext } from 'react'

import { User } from '@auth0/auth0-react'
import mixpanel from 'mixpanel-browser'
import CommentPopup from 'pages/projects/components/CommentPopup'

import { EditorContext } from 'contexts/Editor'

import useComment from 'hooks/Comment'

import { EDITOR_TOOLS } from 'config/constants'

import { Comment, CuboidAnchor, ModalProps, Position } from 'interfaces/interfaces'

import CommentIcon from './CommentIcon'
import UnplacedCommentIcons from './UnplacedCommentIcons'

const Comments: FC<{
  user: User | undefined
  project_id: string | undefined
  project_group_id: string | undefined
  commentPopupPosition: Position | undefined
  setCommentPopupPosition: (position: Position | undefined) => void
  selectedComment: Comment | undefined
  setSelectedComment: (comment: Comment | undefined) => void
  showModal: (props: ModalProps) => void
  showErrorModal: (message: string) => void
  setCuboidAnchor: (anchor?: CuboidAnchor) => void
}> = ({
  user,
  project_id,
  project_group_id,
  commentPopupPosition,
  setCommentPopupPosition,
  selectedComment,
  setSelectedComment,
  showModal,
  showErrorModal,
  setCuboidAnchor,
}) => {
  const {
    commentBody,
    commentDeletingImageIndexes,
    commentEditingCaptions,
    commentImages,
    commentName,
    commentOpeningImageIds,
    commentImageHandlingIndex,
    commentUploadedImages,
    cuboid,
    isMovingComment,
    movingCommentCartesianPosition,
    selectedTool,
    changeCuboid,
    changeTool,
    changeIsMovingComment,
    updateCommentBody,
    updateCommentDeletingImageIndexes,
    updateCommentEditingCaptions,
    updateCommentImages,
    updateCommentName,
    toggleCommentOpeningImageId,
    updateCommentImageHandlingIndex,
    updateCommentUploadedImages,
    updateMovingCommentCartesianPosition,
  } = useContext(EditorContext)

  const resetCommentStates = () => {
    setCommentPopupPosition(undefined)
    setSelectedComment(undefined)
    updateMovingCommentCartesianPosition(undefined)
    changeIsMovingComment(false)
    changeCuboid(undefined)
    setCuboidAnchor(undefined)
  }

  const {
    comments,
    isLoading,
    fetchComments,
    openDeleteCommentConfirmModal,
    onConfirmMoveCartesianPosition,
    onConfirmMoveCuboid,
  } = useComment(project_id, selectedComment, setSelectedComment, resetCommentStates, showModal)

  const filteredComments = comments.filter(
    (comment) => comment.blueprint_position && !comment.cartesian_position && !comment.cuboid_position
  )
  const itemsPerRow = Math.ceil(Math.sqrt(filteredComments.length))
  const itemsPerCol = Math.ceil(filteredComments.length / itemsPerRow)
  const unplacedComments = filteredComments.map((comment, index) => {
    const unplacedIndex: [number, number] = [
      (index % itemsPerRow) - (itemsPerRow - 1) / 2,
      Math.floor(index / itemsPerRow) - (itemsPerCol - 1) / 2,
    ]
    return {
      ...comment,
      unplacedIndex,
    }
  })

  const onConfirmMove = async () => {
    if (movingCommentCartesianPosition) {
      await onConfirmMoveCartesianPosition(movingCommentCartesianPosition)
    } else if (cuboid) {
      await onConfirmMoveCuboid(cuboid)
    }

    resetCommentStates()
    return false
  }

  return (
    <>
      {comments.map((comment) =>
        comment.cartesian_position || comment.cuboid_position ? (
          <CommentIcon
            key={comment.thread_id}
            cartesianPosition={
              isMovingComment && comment.thread_id === selectedComment?.thread_id
                ? movingCommentCartesianPosition
                : comment.cartesian_position
            }
            cuboidPosition={
              isMovingComment && comment.thread_id === selectedComment?.thread_id
                ? cuboid?.center
                : comment.cuboid_position?.center
            }
            disabled={isLoading || (isMovingComment && selectedComment?.thread_id !== comment.thread_id)}
            visible={isMovingComment || selectedComment !== comment}
            onClick={() => {
              setSelectedComment(comment)
              changeCuboid(comment.cuboid_position)
              setCuboidAnchor(undefined)
            }}
            isMoving={isMovingComment && comment.thread_id === selectedComment?.thread_id}
            onCancelMove={resetCommentStates}
            onConfirmMove={onConfirmMove}
          />
        ) : null
      )}
      <UnplacedCommentIcons
        comments={unplacedComments}
        disabled={isLoading}
        selectedComment={selectedComment}
        setSelectedComment={setSelectedComment}
        changeCuboid={changeCuboid}
        setCuboidAnchor={setCuboidAnchor}
        isMovingComment={isMovingComment}
        onCancelMove={resetCommentStates}
        onConfirmMove={onConfirmMove}
      />
      {!isMovingComment &&
        (commentPopupPosition ||
          selectedComment?.cartesian_position ||
          selectedComment?.cuboid_position ||
          selectedComment?.blueprint_position ||
          (selectedTool === EDITOR_TOOLS.COMMENT_CUBOID && cuboid)) && (
          <CommentPopup
            user={user}
            project_id={project_id}
            project_group_id={project_group_id}
            cartesianPosition={commentPopupPosition || selectedComment?.cartesian_position}
            cuboidPosition={selectedComment?.cuboid_position || cuboid}
            blueprintPosition={selectedComment?.blueprint_position}
            blueprintId={selectedComment?.blueprint_id}
            closePopup={() => {
              setCommentPopupPosition(undefined)
              setSelectedComment(undefined)
              changeCuboid(undefined)
              setCuboidAnchor(undefined)
              updateCommentImages([])
              updateCommentEditingCaptions([])
              updateCommentDeletingImageIndexes([])
              updateCommentUploadedImages([])
            }}
            comment={selectedComment}
            onCommentModified={() => fetchComments()}
            deleteComment={() => {
              openDeleteCommentConfirmModal()

              // track with mixpanel
              mixpanel.track('Delete comment', {
                'Inspection area ID': project_id,
                'Thread ID': selectedComment?.thread_id,
                Situation: '3D GUI',
              })
            }}
            disabled={isLoading}
            commentBody={commentBody}
            commentImages={commentImages}
            commentName={commentName}
            deletingImageIndexes={commentDeletingImageIndexes}
            editingCaptions={commentEditingCaptions}
            updateCommentBody={updateCommentBody}
            updateCommentImages={updateCommentImages}
            updateCommentName={updateCommentName}
            openingImageIds={commentOpeningImageIds}
            handlingImageIndex={commentImageHandlingIndex}
            uploadedImages={commentUploadedImages}
            toggleOpeningImageId={toggleCommentOpeningImageId}
            updateDeletingImageIndexes={updateCommentDeletingImageIndexes}
            updateEditingCaptions={updateCommentEditingCaptions}
            updateUploadedImages={updateCommentUploadedImages}
            updateHandlingImageIndex={updateCommentImageHandlingIndex}
            showErrorModal={showErrorModal}
            onMoveComment={() => {
              if (selectedComment?.cartesian_position) {
                updateMovingCommentCartesianPosition(selectedComment.cartesian_position)
                changeTool(EDITOR_TOOLS.COMMENT)
              } else if (selectedComment?.cuboid_position) {
                changeCuboid(selectedComment.cuboid_position)
                changeTool(EDITOR_TOOLS.COMMENT_CUBOID)
              } else {
                changeTool(EDITOR_TOOLS.COMMENT)
              }
              setCuboidAnchor(undefined)
              changeIsMovingComment(true)
            }}
            forEditor
          />
        )}
      {selectedComment &&
        unplacedComments.some((comment) => comment.thread_id === selectedComment.thread_id) &&
        isMovingComment && (
          <CommentIcon
            cartesianPosition={selectedTool === EDITOR_TOOLS.COMMENT ? movingCommentCartesianPosition : undefined}
            cuboidPosition={selectedTool === EDITOR_TOOLS.COMMENT_CUBOID ? cuboid?.center : undefined}
            disabled={isLoading}
            visible
            isMoving
            onCancelMove={resetCommentStates}
            onConfirmMove={onConfirmMove}
          />
        )}
    </>
  )
}

export default Comments
