import { EDITOR_DIAMETER_INPUT_MAX, EDITOR_DIAMETER_INPUT_MIN, USER_TYPES, USER_TYPES_VALUE } from 'config/constants'

const regex = /^(1?[0-9]{0,2}|0)(\.[0-9]{1,2})?$/
/**
 * 直径フォーマットチェック、正数かつ小数点2桁かつ0.01~100mmであること
 * @param {string} diameter 直径
 * @return {boolean} 適合: true, 不適合:false
 */
export const checkDiameterFormat = (diameter: string) => {
  //* 必須チェック
  if (!diameter) return false
  //* 桁数チェック
  if (!regex.test(diameter)) return false
  //* 境界値チェック
  const floatDiameter = parseFloat(diameter)
  if (floatDiameter < EDITOR_DIAMETER_INPUT_MIN || floatDiameter > EDITOR_DIAMETER_INPUT_MAX) return false
  return true
}

/**
 * judge whether the input string is a valid as an email address
 *
 * @param {string} email - email address to validate
 * @returns {boolean} true if the email is valid
 */
export const isValidEmail = (email: string) => {
  const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)+$/
  return emailRegex.test(email)
}

/**
 * Decide the permission for some actions based on the user type and the other properties
 * @param userType user type
 * @param isProjectOwned is the project owned by the organization to which the user belongs
 * @param isProjectInvited is invited to the project/project group
 * @param isCommentOwner is the owner of the comment
 * @returns permission for some actions
 */
export const decideActionPermission = (
  userType: USER_TYPES_VALUE,
  isProjectOwned?: boolean,
  isProjectInvited?: boolean,
  isCommentOwner?: boolean
): { [key: string]: { [key: string]: boolean } } => {
  // beta user has the same access pattern as paying user
  const isPayingUser = !!userType && [USER_TYPES.PAYING_USER, USER_TYPES.BETA_USER].includes(userType)
  const isBaseUser = userType === USER_TYPES.BASE_USER
  const isAdminUser = userType === USER_TYPES.ADMIN

  // pattern 1 (view/comment)
  // - admin user can view all projects
  // - invited user can view the project
  // - user of project owner organization can view the project
  //   - we do not restrict access by user type for now,
  //     as the access for non - paying user should be allowed
  const viewPattern = isAdminUser || !!isProjectInvited || !!isProjectOwned

  // pattern 2 (edit)
  // - user of project owner organization can edit the project, as long as the status is paying/admin user
  const modifyPattern = !!isProjectOwned && (isPayingUser || isAdminUser)

  // pattern 3 (edit comment)
  // - comment owner can edit the comment, as long as one of the following conditions is met:
  //   - invited to the project
  //   - user belongs to the project owner organization, and the status is paying
  //     - we do not restrict access by user type for now,
  //       as the access for non - paying user should be allowed
  const modifyCommentPattern = !!isCommentOwner && (!!isProjectInvited || !!isProjectOwned)

  return {
    PROJECT_DASHBOARD: {
      CREATE_PROJECT_GROUP: isPayingUser,
      INVITE_USER_TO_PROJECT: modifyPattern,
      INVITE_TO_CREATE_ACCOUNT: isPayingUser,
      MODIFY: modifyPattern,
      ACTIVATE_PREMIUM_FEATURES: isBaseUser,
      SHOW_USER_EMAIL: isAdminUser,
    },
    MAIN_CANVAS: {
      BROWSE: viewPattern,
      MEASURE: viewPattern,
      DETECT: modifyPattern,
      MODIFY: modifyPattern,
    },
    INSPECTION_SHEET: {
      BROWSE: viewPattern,
      DOWNLOAD: viewPattern,
      MODIFY: modifyPattern,
    },
    BLUEPRINT_VIEWER: {
      BROWSE: viewPattern,
      MODIFY: modifyPattern,
    },
    IFC_REGISTER: {
      BROWSE: viewPattern,
      MODIFY: modifyPattern,
    },
    COMMENT_THREAD: {
      BROWSE: viewPattern,
      CREATE: viewPattern,
      EDIT: modifyCommentPattern,
    },
  }
}

/**
 * Decide the permission for actions for organization management based on the user type
 * @param organizationOwner is the owner of the organization
 * @param organizationMember is a member of the organization
 * @returns permission for actions for organization management
 */
export const decideOrganizationManagePermission = (
  organizationOwner?: boolean,
  organizationMember?: boolean
): { [key: string]: boolean } => ({
  BROWSE: !!organizationOwner || !!organizationMember,
  MODIFY: !!organizationOwner,
  MANAGE_USERS: !!organizationOwner,
})
