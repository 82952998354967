import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { EDITOR_COLLAPSE_TYPES } from 'config/constants'

// TODO: this should not be exported?
export interface EditorState {
  // -------- Property Panel -------- //

  // Flag to show/hide diameter labels
  diameterLabelVisible: boolean

  // Whether a job is running
  isJobRunning: boolean

  // Used for update the property panels expand/collapse state
  expandedPanels: string[]
}

const initialState: EditorState = {
  diameterLabelVisible: false,
  isJobRunning: false,
  expandedPanels: Object.values(EDITOR_COLLAPSE_TYPES),
}

const editorSlice = createSlice({
  name: 'editor',
  initialState,
  reducers: {
    setDiameterLabelVisible: (state, action: PayloadAction<boolean>) => ({
      ...state,
      diameterLabelVisible: action.payload,
    }),

    setIsJobRunning: (state, action: PayloadAction<boolean>) => ({
      ...state,
      isJobRunning: action.payload,
    }),

    // Used for updating each property panel collapse state manually
    setExpandedPanels: (state, action: PayloadAction<string[]>) => ({
      ...state,
      expandedPanels: action.payload,
    }),
    // flip the expand/collapse state of the specified panel
    panelToggled: (state, action: PayloadAction<string>) => ({
      ...state,
      expandedPanels: state.expandedPanels.includes(action.payload)
        ? // remove the specified panel from the expandedPanels array if it is already expanded
          state.expandedPanels.filter((panelType) => panelType !== action.payload)
        : // add the specified panel to the expandedPanels array if it is not expanded
          [...state.expandedPanels, action.payload],
    }),
    // reducers for updating the collapse state for various panels
    // when action is taken/finished.
    // It would complement the reducer for individual panel above.
    cuboidAnchorAdded: (state) => ({
      ...state,
      // TODO: add updates of other states (ex: cuboidAnchor)
      expandedPanels: [EDITOR_COLLAPSE_TYPES.diameter],
    }),
    shapeDetectionFinished: (state, action: PayloadAction<number>) => ({
      ...state,
      // TODO: add updates of other states (ex: isLoading)
      expandedPanels: [EDITOR_COLLAPSE_TYPES.detected, action.payload ? EDITOR_COLLAPSE_TYPES.detecting : ''],
    }),
    shapeInDetectionSelected: (state) => ({
      ...state,
      // TODO: add updates of other states (ex: selectedPoint)
      expandedPanels: [EDITOR_COLLAPSE_TYPES.detecting, EDITOR_COLLAPSE_TYPES.diameter],
    }),
    shapeDetectedSelected: (state) => ({
      ...state,
      // TODO: add updates of other states (ex: selectedShapeIds)
      expandedPanels: [EDITOR_COLLAPSE_TYPES.detected],
    }),
    distanceComputed: (state) => ({
      ...state,
      // TODO: add updates of other states (ex: distanceAnchors)
      expandedPanels: [EDITOR_COLLAPSE_TYPES.distance],
    }),
    distanceSelected: (state) => ({
      ...state,
      // TODO: add updates of other states (ex: selectedPoint)
      expandedPanels: [EDITOR_COLLAPSE_TYPES.distance],
    }),
    overlapComputationStarted: (state) => ({
      ...state,
      expandedPanels: [EDITOR_COLLAPSE_TYPES.detected, EDITOR_COLLAPSE_TYPES.overlap],
    }),
    overlapSelected: (state) => ({
      ...state,
      // TODO: add updates of other states (ex: selectedPoint)
      expandedPanels: [EDITOR_COLLAPSE_TYPES.overlap],
    }),
    spacerAnchorAdded: (state) => ({
      ...state,
      // TODO: add updates of other states (ex: spacerAnnotationAnchors)
      expandedPanels: [EDITOR_COLLAPSE_TYPES.spacerAnchor],
    }),
    spacerGridFormationFinished: (state) => ({
      ...state,
      // TODO: add updates of other states (ex: spacerAnnotationAnchors)
      expandedPanels: [EDITOR_COLLAPSE_TYPES.spacerAnnotation],
    }),
    spacerInFormationSelected: (state) => ({
      ...state,
      expandedPanels: [EDITOR_COLLAPSE_TYPES.spacerAnchor],
    }),
    spacerGridFormedSelected: (state) => ({
      ...state,
      // TODO: add updates of other states (ex: selectedShapeIds)
      expandedPanels: [EDITOR_COLLAPSE_TYPES.spacerAnnotation],
    }),
    virtualPlaneCreated: (state) => ({
      ...state,
      // TODO: add updates of other states (ex: anchors)
      expandedPanels: [EDITOR_COLLAPSE_TYPES.detected],
    }),
    maskRegionCreated: (state) => ({
      ...state,
      // TODO: add updates of other states (ex: cuboidAnchor)
      expandedPanels: [EDITOR_COLLAPSE_TYPES.maskRegion],
    }),
    reset: () => initialState,
  },
})

export const {
  setDiameterLabelVisible,
  setIsJobRunning,
  overlapComputationStarted,
  overlapSelected,
  setExpandedPanels,
  panelToggled,
  cuboidAnchorAdded,
  distanceComputed,
  distanceSelected,
  maskRegionCreated,
  shapeDetectionFinished,
  shapeInDetectionSelected,
  shapeDetectedSelected,
  virtualPlaneCreated,
  spacerAnchorAdded,
  spacerGridFormationFinished,
  spacerInFormationSelected,
  spacerGridFormedSelected,
  reset,
} = editorSlice.actions

export default editorSlice.reducer
