import { FC, RefObject, useContext, useEffect, useState } from 'react'

import { Box } from '@chakra-ui/react'
import { Html, Line } from '@react-three/drei'
import { Camera, Matrix4, Mesh, Vector3 } from 'three'
import { ArcballControls } from 'three-stdlib'

import { EditorContext } from 'contexts/Editor'

import usePointScale from 'hooks/PointScale'
import useShape from 'hooks/Shape'

import {
  EDITOR_DECIMAL_BASE,
  EDITOR_EXTRA_SHAPE_KEYS,
  EDITOR_MEASURE_KEYS,
  EDITOR_SHAPE_KEYS,
  Z_INDEX_RANGE,
} from 'config/constants'
import { EDITOR_FRAME_COLORS } from 'config/styles'

import { PointArray, SpacerAnnotation } from 'interfaces/interfaces'

import { getMidPoint, pointsToVector3s } from 'services/Points'
import { getDistanceLabel, roundNumber } from 'services/Util'

import PointMesh from './PointMesh'

export const SpacerAnnotationMesh: FC<{
  cameraRef: RefObject<Camera> | null
  spacerAnnotation: SpacerAnnotation
  spacerIndex: number
  arcballControls: ArcballControls | null
  invisible?: boolean
}> = ({ cameraRef, spacerAnnotation, spacerIndex, arcballControls, invisible }) => {
  const { selectedShapeIds } = useContext(EditorContext)
  const { onPointerDown, onPointerUp, onPointerOver, onPointerOut, onPointerMove, isFocus } = useShape(
    spacerAnnotation,
    cameraRef
  )

  const isSelected = selectedShapeIds.includes(spacerAnnotation.shape_id)

  const { scale } = usePointScale(arcballControls)
  const [mesh, setMesh] = useState<Mesh>(new Mesh())

  useEffect(() => {
    const { transformation } = spacerAnnotation
    if (transformation.length !== 16) {
      return
    }
    const matrix = new Matrix4().set(
      transformation[0],
      transformation[1],
      transformation[2],
      transformation[3],
      transformation[4],
      transformation[5],
      transformation[6],
      transformation[7],
      transformation[8],
      transformation[9],
      transformation[10],
      transformation[11],
      transformation[12],
      transformation[13],
      transformation[14],
      transformation[15]
    )

    const spacerMesh = new Mesh()
    spacerMesh.applyMatrix4(matrix)

    setMesh(spacerMesh)
  }, [spacerAnnotation])

  const connectionPoints =
    spacerAnnotation.shape_type === EDITOR_EXTRA_SHAPE_KEYS.RHOMBI
      ? pointsToVector3s([
          spacerAnnotation.points[0],
          spacerAnnotation.points[2],
          spacerAnnotation.points[3],
          spacerAnnotation.points[1],
        ])
      : [
          getMidPoint(new Vector3(...spacerAnnotation.points[0]), new Vector3(...spacerAnnotation.points[1])),
          getMidPoint(new Vector3(...spacerAnnotation.points[2]), new Vector3(...spacerAnnotation.points[3])),
          new Vector3(...spacerAnnotation.points[3]),
          getMidPoint(new Vector3(...spacerAnnotation.points[3]), new Vector3(...spacerAnnotation.points[0])),
          getMidPoint(new Vector3(...spacerAnnotation.points[1]), new Vector3(...spacerAnnotation.points[2])),
        ]

  const getPointKey = (pointIndex: number) => `spacer-${spacerIndex}-${pointIndex}`
  const distanceLabel = (
    center: PointArray | undefined,
    distance: number | undefined,
    focused: boolean,
    area?: number
  ) => (
    <Html
      position={center || 0}
      style={{ transform: 'translateX(-50%) translateY(-50%)' }}
      zIndexRange={Z_INDEX_RANGE.default}
    >
      <Box
        backgroundColor={focused ? 'yellow' : EDITOR_FRAME_COLORS.spacerAnnotation}
        px={2}
        fontSize="80%"
        fontWeight="bold"
        color="black"
      >
        {distance !== undefined && getDistanceLabel(distance || 0)}
        {area !== undefined && `${area}m^2`}
      </Box>
    </Html>
  )

  if (!spacerAnnotation || invisible) return null

  return (
    <group position={mesh.position} quaternion={mesh.quaternion}>
      <Line
        onPointerDown={onPointerDown}
        onPointerUp={onPointerUp}
        onPointerOver={onPointerOver}
        onPointerOut={onPointerOut}
        onPointerMove={onPointerMove}
        points={pointsToVector3s([...spacerAnnotation.points, spacerAnnotation.points[0]])}
        color={isFocus ? 'yellow' : EDITOR_FRAME_COLORS.spacerAnnotation}
        lineWidth={1}
        renderOrder={2}
      />
      {isSelected && (
        <Line
          points={pointsToVector3s([...spacerAnnotation.points, spacerAnnotation.points[0]])}
          color="white"
          lineWidth={2}
          renderOrder={0}
        />
      )}
      <Line
        points={connectionPoints}
        color={isFocus ? 'yellow' : EDITOR_FRAME_COLORS.spacerAnnotation}
        lineWidth={0.25}
        renderOrder={1}
        dashed
        dashSize={1}
        dashScale={100}
      />
      {isSelected &&
        spacerAnnotation.points.map((point, pointIndex) => (
          <PointMesh
            key={getPointKey(pointIndex)}
            point={point}
            scale={scale}
            anchorIndex={spacerIndex}
            pointIndex={pointIndex}
            shapeKey={EDITOR_MEASURE_KEYS.SPACER_ANNOTATION}
          />
        ))}
      {isSelected && distanceLabel(spacerAnnotation.centers?.[0], spacerAnnotation.distances?.[0], isFocus)}
      {isSelected &&
        spacerAnnotation.shape_type === EDITOR_SHAPE_KEYS.PLANES &&
        distanceLabel(spacerAnnotation.centers?.[1], spacerAnnotation.distances?.[1], isFocus)}
      {isSelected &&
        distanceLabel(
          getMidPoint(new Vector3(...spacerAnnotation.points[0]), new Vector3(...spacerAnnotation.points[2])).toArray(),
          undefined,
          isFocus,
          roundNumber(spacerAnnotation.area || 0, EDITOR_DECIMAL_BASE)
        )}
    </group>
  )
}
