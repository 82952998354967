import { FC, useContext } from 'react'

import { Box, Flex, HStack, Text, VStack } from '@chakra-ui/react'
import mixpanel from 'mixpanel-browser'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { RootState, useAppDispatch } from 'store/app'

import { EditorContext } from 'contexts/Editor'

import { EDITOR_COLLAPSE_TYPES, EDITOR_SHAPE_TEMP_ID_PREFIX, MAX_EDITOR_LAYERS } from 'config/constants'
import { INFO_PANEL_PADDING } from 'config/styles'

import { zeroPad } from 'services/Util'

import { spacerGridFormedSelected } from '../store/editor'
import CollapsePanel from './components/CollapsePanel'
import LayerItem from './components/LayerItem'

const SpacerAnnotationPanel: FC<{ isAllowedModify: boolean }> = ({ isAllowedModify }) => {
  const { t } = useTranslation(['projects'])
  const dispatch = useAppDispatch()
  const {
    selectedShapeIds,
    spacerAnnotations,
    isLayerModifying,
    selectedInspectionItem,
    updateSpacerAnnotationStatus,
    updateAllSpacerAnnotationsStatus,
    changeSelectedShapeIds,
    changeHoveredShapeId,
    deleteSpacerAnnotations,
  } = useContext(EditorContext)

  const isExpanded = useSelector((state: RootState) =>
    state.editor.expandedPanels.includes(EDITOR_COLLAPSE_TYPES.spacerAnnotation)
  )

  const { project_id } = useParams<{ project_id: string }>()

  const updateLayerVisibility = (invisible: boolean, index: number) => {
    updateSpacerAnnotationStatus({ invisible }, index)

    // track with mixpanel
    mixpanel.track('Change visibility of spacer annotation', {
      'Inspection area ID': project_id,
      Granuarity: 'individual',
      'Visibility (new)': !invisible,
      'Visibility (old)': invisible,
      'Spacer annotation number': 1,
    })
  }

  const zeroPlaces = MAX_EDITOR_LAYERS.toString().length
  const isSomeOverlapsVisible = spacerAnnotations.some((spacer) => !spacer.invisible)

  if (!spacerAnnotations?.length || selectedInspectionItem) {
    return null
  }

  const getLabel = (index: number, isTemporary: boolean) => {
    const prefix = isTemporary ? '! ' : t('main_canvas.panels.spacer.grid', { ns: 'projects' })
    // note that we put a whitespace between the prefix and the index
    return `${prefix} ${zeroPad(index + 1, zeroPlaces)}`
  }

  const allShapeIds = spacerAnnotations.map((shape) => shape.shape_id)
  const selectedSpacerIds = spacerAnnotations
    .filter((spacer) => selectedShapeIds.includes(spacer.shape_id))
    .map((shape) => shape.shape_id)

  const isSomeSelectedShapesVisible = () =>
    spacerAnnotations.some((spacer) => !spacer.invisible && selectedSpacerIds.includes(spacer.shape_id))

  return (
    <Flex
      backgroundColor="gray.800"
      borderBottomLeftRadius="md"
      borderTopLeftRadius="md"
      w="100%"
      flex={1}
      minH={isExpanded ? 20 : 10}
    >
      <CollapsePanel
        title={t('main_canvas.panels.spacer.spacer_grid', { ns: 'projects' })}
        type={EDITOR_COLLAPSE_TYPES.spacerAnnotation}
      >
        <VStack w="100%" spacing={0} pb={INFO_PANEL_PADDING - 1} overflowY="auto">
          <LayerItem
            disabled={isLayerModifying || !isAllowedModify}
            selected={!!allShapeIds.length && !allShapeIds.some((id) => !selectedSpacerIds.includes(id))}
            invisible={!isSomeOverlapsVisible}
            label={`${t('main_canvas.panels.spacer.grid', { ns: 'projects' })} (${spacerAnnotations.length})`}
            updateVisibility={(invisible) => updateAllSpacerAnnotationsStatus({ invisible })}
            onClick={() => {
              // if some of the shapes are not selected, select all the shapes
              if (allShapeIds.some((id) => !selectedSpacerIds.includes(id))) {
                changeSelectedShapeIds(Array.from(new Set([...selectedShapeIds, ...allShapeIds])))
              } else {
                // if all of the shapes are selected, unselect all the shapes
                changeSelectedShapeIds(selectedShapeIds.filter((id) => !allShapeIds.includes(id)))
              }
              dispatch(spacerGridFormedSelected())
            }}
          />
          {spacerAnnotations.map(
            (spacer, spacerIndex) =>
              !spacer.deleted && (
                <LayerItem
                  disabled={isLayerModifying || !isAllowedModify}
                  selected={selectedShapeIds.includes(spacer.shape_id)}
                  deleteLayer={() => deleteSpacerAnnotations(false, spacerIndex)}
                  invisible={spacer.invisible}
                  key={getLabel(spacerIndex, !!spacer.shape_id?.startsWith(EDITOR_SHAPE_TEMP_ID_PREFIX))}
                  label={
                    <HStack fontSize={10} alignItems="baseline" spacing={1}>
                      <Text>{getLabel(spacerIndex, !!spacer.shape_id?.startsWith(EDITOR_SHAPE_TEMP_ID_PREFIX))}</Text>
                    </HStack>
                  }
                  updateVisibility={(invisible) => updateLayerVisibility(invisible, spacerIndex)}
                  onMouseOver={() => changeHoveredShapeId(spacer.shape_id)}
                  onMouseOut={() => changeHoveredShapeId('')}
                  onClick={() => {
                    const newShapeIds = [...selectedShapeIds]
                    const idIndex = newShapeIds.indexOf(spacer.shape_id)
                    if (idIndex >= 0) {
                      newShapeIds.splice(idIndex, 1)
                    } else {
                      newShapeIds.push(spacer.shape_id)
                    }

                    changeSelectedShapeIds(newShapeIds)
                    dispatch(spacerGridFormedSelected())
                  }}
                  childLevel={1}
                />
              )
          )}
          {!!selectedSpacerIds.length && (
            <Box fontWeight="bold" w="100%">
              <LayerItem
                onClick={() => changeSelectedShapeIds([])}
                disabled={isLayerModifying || !isAllowedModify}
                invisible={!isSomeSelectedShapesVisible()}
                label={`${t('main_canvas.panels.spacer.selected_grid', { ns: 'projects' })}(${
                  selectedSpacerIds.length
                })`}
                updateVisibility={(invisible) => updateAllSpacerAnnotationsStatus({ invisible })}
                deleteLayer={() => deleteSpacerAnnotations(true)}
              />
            </Box>
          )}
        </VStack>
      </CollapsePanel>
    </Flex>
  )
}

export default SpacerAnnotationPanel
