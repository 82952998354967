import { FC } from 'react'

import { Box, Center, IconButton, Tooltip } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'

import {
  AlertFillIcon,
  CircleFillIcon,
  CommentDisplayIcon,
  CommentMoveIcon,
  InputEditorCancelIcon,
  InputEditorConfirmIcon,
} from 'assets/icons'

import { BLUEPRINT_COMMENT_AREA_MIN_SIZE, Z_INDEX } from 'config/constants'

import { FlatPosition } from 'interfaces/interfaces'

const ICON_BUTTON_PROPS = {
  size: 'xs',
  variant: 'outline',
  fontSize: 'sm',
  borderWidth: '1px',
  borderRadius: '50%',
  borderColor: 'gray.500',
  padding: '4px',
  backgroundColor: 'white',
  boxShadow: '0 0 3px white',
}

const CommentFrame: FC<{
  frameId?: string
  unplaced?: boolean
  position?: FlatPosition
  width: number
  height: number
  blueprintScale: number
  isWarning: boolean
  forOverview?: boolean
  disabled?: boolean
  onClick?: (target: HTMLDivElement) => void
  onConfirmMove?: () => void
  onCancelMove?: () => void
  onDragMove?: (index: number, start?: FlatPosition) => void
  isMoving?: boolean
  isHoldingPlace?: boolean
  scale: number
}> = ({
  frameId,
  unplaced,
  position,
  width,
  height,
  blueprintScale,
  isWarning,
  forOverview,
  disabled,
  onClick,
  onConfirmMove,
  onCancelMove,
  onDragMove,
  isMoving,
  isHoldingPlace,
  scale,
}) => {
  const { t } = useTranslation(['projects'])
  if (!position) return null

  return (
    <Tooltip
      hasArrow
      placement="bottom"
      label={isWarning && !isMoving ? t('blueprint.comment_frame.tooltip', { ns: 'projects' }) : ''}
      p={2}
      fontSize="xs"
      fontWeight="normal"
    >
      <Box
        position={unplaced && !isMoving ? 'static' : 'absolute'}
        top={`${(position.y || 0) * blueprintScale}px`}
        left={`${(position.x || 0) * blueprintScale}px`}
        padding={unplaced && !isMoving ? 1 : 0}
        zIndex={isMoving ? Z_INDEX.blueprint_viewer.comment_moving : Z_INDEX.blueprint_viewer.comment_static}
        pointerEvents={isHoldingPlace ? 'none' : 'auto'}
        visibility={isHoldingPlace ? 'hidden' : 'visible'}
        id={frameId || 'temp-comment'}
      >
        <Center
          w={`${width * blueprintScale}px`}
          h={`${height * blueprintScale}px`}
          borderWidth={Math.min(3, Math.ceil(3 * scale))}
          borderColor={isMoving ? 'yellow.500' : 'primary.500'}
          backgroundColor="blackAlpha.100"
          cursor={disabled ? 'inherit' : 'pointer'}
          onClick={(e) => {
            e.stopPropagation()
            if (onClick && !disabled) onClick(e.currentTarget)
          }}
          onMouseDown={(e) => {
            if (!isMoving) {
              return
            }
            e.stopPropagation()
            if (onDragMove) {
              onDragMove(2, { x: e.clientX, y: e.clientY })
            }
          }}
          onMouseUp={(e) => {
            if (!isMoving) {
              return
            }
            e.stopPropagation()
            if (onDragMove) {
              onDragMove(-1)
            }
          }}
          onTouchStart={(e) => {
            if (!isMoving) {
              return
            }
            e.stopPropagation()
            if (onDragMove) {
              onDragMove(2, { x: e.touches[0].clientX, y: e.touches[0].clientY })
            }
          }}
          onTouchEnd={(e) => {
            if (!isMoving) {
              return
            }
            e.stopPropagation()
            if (onDragMove) {
              onDragMove(-1)
            }
          }}
          opacity={disabled ? '0.6' : '1'}
        >
          {!isMoving && !forOverview && (
            <Center
              transform={`scale(${scale})`}
              minW={BLUEPRINT_COMMENT_AREA_MIN_SIZE - 2}
              minH={BLUEPRINT_COMMENT_AREA_MIN_SIZE - 2}
            >
              <CommentDisplayIcon data-testid="comment-icon-blueprint" />
              {isWarning && (
                <Center
                  position="absolute"
                  color="white"
                  marginBottom={1}
                  zIndex={Z_INDEX.blueprint_viewer.comment_icon}
                  top="6px"
                  left="8px"
                >
                  <AlertFillIcon />
                </Center>
              )}
            </Center>
          )}
          {isMoving && !!onCancelMove && !!onConfirmMove && !!onDragMove && (
            <>
              <CommentMoveIcon />
              <IconButton
                {...ICON_BUTTON_PROPS}
                borderColor="red.500"
                color="red.500"
                aria-label="Cancel Move"
                icon={<InputEditorCancelIcon />}
                disabled={disabled}
                onClick={onCancelMove}
                position="absolute"
                bottom={-10}
                left={0}
              />
              <IconButton
                {...ICON_BUTTON_PROPS}
                borderColor="primary.500"
                color="primary.500"
                aria-label="Confirm Move"
                icon={<InputEditorConfirmIcon />}
                disabled={disabled}
                onClick={onConfirmMove}
                position="absolute"
                bottom={-10}
                left={8}
              />
              <IconButton
                {...ICON_BUTTON_PROPS}
                borderColor="yellow.500"
                fontSize="xs"
                aria-label="Move Top Left"
                icon={<CircleFillIcon />}
                disabled={disabled}
                onMouseDown={(e) => {
                  e.stopPropagation()
                  onDragMove(0)
                }}
                onMouseUp={(e) => {
                  e.stopPropagation()
                  onDragMove(-1)
                }}
                onTouchStart={(e) => {
                  e.stopPropagation()
                  onDragMove(0)
                }}
                onTouchEnd={(e) => {
                  e.stopPropagation()
                  onDragMove(-1)
                }}
                onClick={(e) => e.stopPropagation()}
                position="absolute"
                top={-3}
                left={-3}
              />
              <IconButton
                {...ICON_BUTTON_PROPS}
                borderColor="yellow.500"
                fontSize="xs"
                aria-label="Move Bottom Right"
                icon={<CircleFillIcon />}
                disabled={disabled}
                onMouseDown={(e) => {
                  e.stopPropagation()
                  onDragMove(1)
                }}
                onMouseUp={(e) => {
                  e.stopPropagation()
                  onDragMove(-1)
                }}
                onTouchStart={(e) => {
                  e.stopPropagation()
                  onDragMove(1)
                }}
                onTouchEnd={(e) => {
                  e.stopPropagation()
                  onDragMove(-1)
                }}
                onClick={(e) => e.stopPropagation()}
                position="absolute"
                bottom={-3}
                right={-3}
              />
            </>
          )}
        </Center>
      </Box>
    </Tooltip>
  )
}

export default CommentFrame
