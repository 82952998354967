import { extendTheme } from '@chakra-ui/react'

import { INFO_PANEL_PADDING } from './styles'

const Button = {
  sizes: {
    md: {
      fontSize: 'inherit',
    },
  },
  variants: {
    outlinePrimary: {
      borderWidth: '1px',
      borderColor: 'primary.500',
      color: 'primary.500',
      _hover: {
        backgroundColor: 'gray.50',
      },
    },
    outlineStatic: {
      borderWidth: '1px',
      borderColor: 'white',
      color: 'white',
      _hover: {
        backgroundColor: 'white',
        color: 'primary.500',
      },
    },
    outlineStaticInvert: {
      borderWidth: '1px',
      borderColor: 'gray.200',
      color: 'inherit',
      _hover: {
        backgroundColor: 'white',
        color: 'primary.500',
        borderColor: 'primary.200',
      },
    },
    toolbar: {
      backgroundColor: 'gray.600',
      color: 'white',
      _hover: {
        backgroundColor: 'gray.700',
      },
    },
    toolbarIcon: {
      backgroundColor: 'transparent',
      color: 'gray.400',
      _hover: {
        backgroundColor: 'gray.700',
      },
    },
    layer: {
      borderRadius: '0',
      backgroundColor: 'transparent',
      color: 'white',
      fontSize: 'sm',
      justifyContent: 'space-between',
      padding: INFO_PANEL_PADDING,
      _hover: {
        backgroundColor: 'whiteAlpha.50',
      },
    },
  },
}

const Divider = {
  variants: {
    layer: {
      border: 0,
      borderColor: 'whiteAlpha.200',
      borderBottomStyle: 'solid',
    },
  },
}

const FormLabel = {
  baseStyle: {
    color: 'secondary.500',
  },
}

const Link = {
  variants: {
    button: {
      fontWeight: 'semibold',
      padding: '4',
      _hover: {
        textDecoration: 'none',
        backgroundColor: 'blackAlpha.100',
      },
    },
    plain: {
      _hover: {
        textDecoration: 'none',
      },
    },
    underline: {
      textDecoration: 'underline',
      color: 'primary.500',
    },
    neutral: {
      color: 'primary.500',
    },
    toolbar: {
      backgroundColor: 'gray.800',
      color: 'white',
      _hover: {
        backgroundColor: 'gray.800',
      },
      _active: {
        backgroundColor: 'gray.800',
      },
      _focus: {
        backgroundColor: 'gray.800',
      },
    },
  },
}

const Menu = {
  baseStyle: {
    item: {
      paddingBottom: '2.5',
      paddingTop: '2.5',
    },
    list: {
      minW: '40',
      boxShadow: 'md',
      border: '0',
    },
    divider: {
      borderColor: 'blackAlpha.200',
    },
    groupTitle: {
      color: 'gray.400',
      fontSize: 'xs',
      margin: '0.2rem 0.8rem 0.3rem',
    },
  },
  variants: {
    toolbar: {
      button: {
        backgroundColor: 'gray.700',
        color: 'white',
        _hover: {
          backgroundColor: 'gray.600',
        },
        _active: {
          backgroundColor: 'gray.600',
        },
        _focus: {
          backgroundColor: 'gray.600',
        },
      },
      item: {
        backgroundColor: 'gray.700',
        color: 'white',
        _hover: {
          backgroundColor: 'gray.600',
        },
        _active: {
          backgroundColor: 'gray.600',
        },
        _focus: {
          backgroundColor: 'gray.600',
        },
      },
      list: {
        backgroundColor: 'gray.700',
      },
    },
  },
}

const Table = {
  variants: {
    bordered: {
      td: {
        border: '1px',
        borderColor: 'whiteAlpha.500',
        padding: '2px',
      },
    },
  },
}

const theme = extendTheme({
  colors: {
    primary: {
      50: '#E5F3FF',
      100: '#BEE1FF',
      200: '#90CCFF',
      300: '#77C0FF',
      400: '#52B0FF',
      500: '#2699FB',
      600: '#248CE4',
      700: '#186FB9',
      800: '#11538B',
      900: '#0E3B62',
    },
    // gray
    secondary: {
      50: '#F7FAFC',
      100: '#EDF2F7',
      200: '#E2E8F0',
      300: '#CBD5E0',
      400: '#A0AEC0',
      500: '#718096',
      600: '#4A5568',
      700: '#2D3748',
      800: '#1A202C',
      900: '#171923,',
    },
  },
  components: {
    Button,
    Divider,
    FormLabel,
    Link,
    Menu,
    Table,
  },
  styles: {
    global: {
      body: {
        color: 'secondary.600',
        fontSize: 'sm',
      },
      a: {
        color: 'primary.600',
      },
    },
  },
})

export default theme
