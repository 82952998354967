import { FC, useCallback, useContext, useEffect, useState } from 'react'

import { useAuth0 } from '@auth0/auth0-react'
import GlobalModal from 'components/GlobalModal'
import axios from 'extends/axios'
import mixpanel from 'mixpanel-browser'
import { NotFoundPage } from 'pages/_error/NotFound'
import Dashboard from 'pages/dashboard/Dashboard'
import IFCFilesTable from 'pages/dashboard/IFCFiles'
import InvitedUsers from 'pages/dashboard/InvitedUsers'
import AppTitle from 'pages/dashboard/components/AppTitle'
import LandingPage from 'pages/landingPage/LandingPage'
import MaintenancePage from 'pages/maintenancePage/MaintenancePage'
import Organization from 'pages/organizations/Organization'
import IFCRegister from 'pages/projects/IFCRegister/IFCRegister'
import BlueprintViewer from 'pages/projects/blueprint/BlueprintViewer'
import Editor from 'pages/projects/editor/Editor'
import Register from 'pages/register/Register'
import UsageDashboard from 'pages/usageDashboard/UsageDashboard'
import UserList from 'pages/userList/UserList'
import { BrowserRouter, Route, Routes } from 'react-router-dom'

import { Protected } from 'contexts/Auth'
import { GlobalModalContext } from 'contexts/GlobalModal'
import { ProjectsContext, useProjectsContext } from 'contexts/Projects'
import { ServiceStatusContext, useServiceStatusContext } from 'contexts/ServiceStatus'
import { UserContext, useUserContext } from 'contexts/Users'

import { SERVICE_STATUS_API_CALLING_THRESHOLD } from 'config/constants'
import { MIXPANEL_TOKEN } from 'config/environments'
import { initSentry } from 'config/sentry'

import { Timeout } from 'interfaces/interfaces'

import { getServiceStatus } from 'services/App'

// Initialize Sentry
initSentry()

const AuthWrapper: FC = ({ children }) => {
  const projectsContextVal = useProjectsContext()

  return <ProjectsContext.Provider value={projectsContextVal}>{children}</ProjectsContext.Provider>
}

const AppWrapper: FC = ({ children }) => {
  const userContextVal = useUserContext()

  return (
    <UserContext.Provider value={userContextVal}>
      <ServiceStatusWrapper />
      <AuthWrapper>{children}</AuthWrapper>
    </UserContext.Provider>
  )
}

const AppContent: FC = () => {
  const { isLoading } = useAuth0()

  if (isLoading) {
    return <p />
  }

  return (
    <AppWrapper>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/register" element={<Register />} />
          <Route path="/register?:email_address" element={<Register />} />
          <Route element={<Protected />}>
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/dashboard?:user_id&:user_email" element={<Dashboard />} />
            <Route path="/project-groups/:project_group_id/users" element={<InvitedUsers />} />
            <Route path="/project-groups/:project_group_id/ifc-files" element={<IFCFilesTable />} />
            <Route path="/projects/:project_id" element={<Editor />} />
            <Route path="/projects/:project_id/blueprint" element={<BlueprintViewer />} />
            <Route path="/projects/:project_id/ifc-register" element={<IFCRegister />} />
            <Route path="/user-list" element={<UserList />} />
            <Route path="/usage" element={<UsageDashboard />} />
            <Route path="/organizations/:organization_id" element={<Organization />} />
          </Route>
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </BrowserRouter>
    </AppWrapper>
  )
}

// This is for calling the Service status API when user not logged in yet
const ServiceStatusInit: FC = () => {
  const { serviceStatus, setServiceStatus } = useContext(ServiceStatusContext)

  const { showErrorModal } = useContext(GlobalModalContext)

  const fetchStatus = useCallback(async () => {
    const status = await getServiceStatus('', showErrorModal)
    if (status && JSON.stringify(status) !== JSON.stringify(serviceStatus)) {
      setServiceStatus(status)
    }
  }, [showErrorModal, serviceStatus, setServiceStatus])

  useEffect(() => {
    // Get the service status for the first time
    void fetchStatus()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return null
}

// This is for calling the Service status API when user has logged in
const ServiceStatusWrapper: FC = () => {
  const { serviceStatus, setServiceStatus } = useContext(ServiceStatusContext)

  const { getAccessToken } = useContext(UserContext)
  const { showErrorModal } = useContext(GlobalModalContext)

  const [isAllowedFetchingStatus, setIsAllowedFetchingStatus] = useState(true)
  const [fetchingTimeout, setFetchingTimeout] = useState<Timeout>()

  const fetchStatus = useCallback(async () => {
    if (!isAllowedFetchingStatus) {
      return
    }

    const token = await getAccessToken()

    setIsAllowedFetchingStatus(false)

    const status = await getServiceStatus(token || '', showErrorModal)
    if (status && JSON.stringify(status) !== JSON.stringify(serviceStatus)) {
      setServiceStatus(status)
    }
  }, [isAllowedFetchingStatus, getAccessToken, showErrorModal, serviceStatus, setServiceStatus])

  useEffect(() => {
    if (fetchingTimeout) {
      clearTimeout(fetchingTimeout)
    }
    // Prevent calling the Service Status API too many times
    if (!isAllowedFetchingStatus) {
      const timeout = setTimeout(() => {
        setIsAllowedFetchingStatus(true)
      }, SERVICE_STATUS_API_CALLING_THRESHOLD)
      setFetchingTimeout(timeout)
    }
    return () => {
      if (fetchingTimeout) {
        clearTimeout(fetchingTimeout)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAllowedFetchingStatus])

  useEffect(() => {
    axios.interceptors.request.clear()
    axios.interceptors.request.use((config) => {
      // Before calling any API, check the service status
      void fetchStatus()
      return config
    })
  }, [fetchStatus])

  return null
}

const MaintenanceWrapper: FC = () => {
  const { serviceStatus } = useContext(ServiceStatusContext)

  if (serviceStatus?.is_down === null) {
    return <p />
  }

  if (serviceStatus?.is_down?.enabled) {
    return <MaintenancePage />
  }

  return <AppContent />
}

const App: FC = () => {
  const serviceStatusContextVal = useServiceStatusContext()
  // create (the only) instance for mixpanel
  mixpanel.init(MIXPANEL_TOKEN, { debug: false, track_pageview: true, persistence: 'localStorage' })

  return (
    <GlobalModal>
      <ServiceStatusContext.Provider value={serviceStatusContextVal}>
        <ServiceStatusInit />
        <MaintenanceWrapper />
      </ServiceStatusContext.Provider>
      <AppTitle />
    </GlobalModal>
  )
}

export default App
