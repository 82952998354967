import { FC, useCallback, useContext, useEffect, useState } from 'react'

import { TriangleDownIcon, TriangleUpIcon } from '@chakra-ui/icons'
import {
  Button,
  IconButton,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Portal,
  Skeleton,
  Stack,
  Td,
  Text,
  Th,
  Tr,
} from '@chakra-ui/react'
import {
  SortingState,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table'
import bytes from 'bytes'
import dayjs from 'dayjs'
import mixpanel from 'mixpanel-browser'
import { useTranslation } from 'react-i18next'
import { Link as RouterLink } from 'react-router-dom'

import { AddIcon, HDotsIcon } from 'assets/icons'

import { GlobalModalContext } from 'contexts/GlobalModal'
import { UserContext } from 'contexts/Users'

import { MODAL_TYPES } from 'config/constants'

import { Project, ProjectGroup } from 'interfaces/interfaces'

import { deleteProject, getProjects } from 'services/Projects'

const ProjectRow: FC<{
  projectGroup: ProjectGroup
  isExpanded: boolean
  isAllowedModify: boolean
  setEditingNameProject: (project: Project) => void
  setUpdatingFileProject: (project: Project) => void
  onCreateProject: () => void
}> = ({
  projectGroup,
  isExpanded,
  isAllowedModify,
  setEditingNameProject,
  setUpdatingFileProject,
  onCreateProject,
}) => {
  const { t } = useTranslation(['dashboard'])
  const { showModal, showErrorModal } = useContext(GlobalModalContext)
  const { getAccessToken } = useContext(UserContext)

  const [projects, setProjects] = useState<Project[]>([])
  const [isLoading, setIsLoading] = useState(false)

  const fetchData = useCallback(async () => {
    const token = await getAccessToken()
    if (!token) return Promise.resolve()

    setIsLoading(true)

    const result = await getProjects(token, projectGroup.project_group_id, showErrorModal)
    if (result) setProjects(result)

    setIsLoading(false)

    return Promise.resolve()
  }, [getAccessToken, projectGroup, showErrorModal])

  useEffect(() => {
    if (isExpanded) void fetchData()
  }, [isExpanded, projectGroup, fetchData])

  const confirmProjectDelete = (projectId: string, projectName: string) => {
    showModal({
      body: (
        <>
          <Text>
            {' '}
            {t('components.project_row.delete_warning', {
              ns: 'dashboard',
            })}
          </Text>
          <Text mt="1" fontWeight="semibold">
            {projectName}
          </Text>
        </>
      ),
      confirmText: t('components.project_row.delete', {
        ns: 'dashboard',
      }),
      modalType: MODAL_TYPES.CONFIRMATION_CRITICAL,
      onConfirm: () => {
        const deleteProjectApi = async () => {
          const token = await getAccessToken()
          if (!token) {
            return false
          }

          const deleteResult = await deleteProject(token, projectId, showErrorModal)
          if (!deleteResult) {
            return false
          }

          await fetchData()
          return true
        }

        void deleteProjectApi()

        // track with mixpanel
        mixpanel.track('Delete inspection area', {
          'Inspection area ID': projectId,
          'Project ID': projectGroup.project_group_id,
        })

        return true
      },
      title: t('components.project_row.confirm_delete_inspection_area', {
        ns: 'dashboard',
      }),
    })
  }

  const columnHelper = createColumnHelper<Project>()
  const columns = [
    columnHelper.display({ id: 'expander', meta: { style: { borderBottom: 0 } } }),
    columnHelper.accessor('project_name', {
      // eslint-disable-next-line react/no-unstable-nested-components
      cell: (info) => (
        <Link as={RouterLink} to={`/projects/${info.row.original.project_id}`} variant="underline">
          {info.getValue()}
        </Link>
      ),
      header: t('components.project_row.inspection_area_name', {
        ns: 'dashboard',
      }),
      meta: {
        style: {
          textAlign: 'left',
          paddingLeft: '30px',
        },
      },
    }),
    columnHelper.accessor('origin_file.size', {
      // eslint-disable-next-line react/no-unstable-nested-components
      cell: (info) =>
        info.row.original.origin_file.name ? (
          bytes(info.getValue(), {
            decimalPlaces: 1,
            unit: 'MB',
          })
        ) : (
          <Text color="secondary.400">
            {' '}
            {t('components.project_row.no_pcd', {
              ns: 'dashboard',
            })}
          </Text>
        ),
      header: t('components.project_row.size', {
        ns: 'dashboard',
      }),
      meta: {
        style: {
          textAlign: 'right',
        },
      },
    }),
    columnHelper.display({ id: 'invitees' }),
    columnHelper.accessor('updated_at', {
      cell: (info) => dayjs(info.getValue()).format('YYYY/MM/DD'),
      header: t('components.project_row.update_date', {
        ns: 'dashboard',
      }),
      meta: {
        style: {
          textAlign: 'right',
        },
      },
    }),
    columnHelper.accessor('created_at', {
      cell: (info) => dayjs(info.getValue()).format('YYYY/MM/DD'),
      header: t('components.project_row.create_date', {
        ns: 'dashboard',
      }),
      meta: {
        style: {
          textAlign: 'right',
        },
      },
    }),
    columnHelper.accessor('creator_email_address', {
      cell: (info) => info.getValue(),
      header: t('components.project_row.creator', {
        ns: 'dashboard',
      }),
    }),
    columnHelper.display({
      id: 'actions',
      // eslint-disable-next-line react/no-unstable-nested-components
      cell: (info) => (
        <Menu autoSelect={false}>
          <MenuButton
            disabled={!isAllowedModify}
            variant="ghost"
            as={IconButton}
            aria-label={`actions.${info.row.original.project_id}`}
            fontSize="lg"
            icon={<HDotsIcon />}
          />
          <Portal>
            <MenuList aria-label={`${isAllowedModify ? 'owner' : 'invited'}-project-menu`}>
              {isAllowedModify && (
                <>
                  <MenuItem
                    aria-label={`change-name.${info.row.original.project_id}`}
                    onClick={() => setEditingNameProject(info.row.original)}
                  >
                    {t('components.project_row.change_inspection_area_name', {
                      ns: 'dashboard',
                    })}
                  </MenuItem>
                  {!info.row.original.down_sampled_file.name && (
                    <MenuItem
                      aria-label={`add-file.${info.row.original.project_id}`}
                      onClick={() => setUpdatingFileProject(info.row.original)}
                    >
                      {t('components.project_row.upload_pcd_file', {
                        ns: 'dashboard',
                      })}
                    </MenuItem>
                  )}
                  {!!info.row.original.down_sampled_file.name && (
                    <MenuItem
                      aria-label={`change-file.${info.row.original.project_id}`}
                      onClick={() => setUpdatingFileProject(info.row.original)}
                    >
                      {t('components.project_row.change_pcd_file', {
                        ns: 'dashboard',
                      })}
                    </MenuItem>
                  )}
                  <MenuItem
                    aria-label={`delete.${info.row.original.project_id}`}
                    onClick={() => confirmProjectDelete(info.row.original.project_id, info.row.original.project_name)}
                  >
                    {t('components.project_row.delete', {
                      ns: 'dashboard',
                    })}
                  </MenuItem>
                </>
              )}
            </MenuList>
          </Portal>
        </Menu>
      ),
    }),
  ]
  const [sorting, setSorting] = useState<SortingState>([
    {
      id: 'updated_at',
      desc: true,
    },
  ])
  const table = useReactTable({
    columns,
    data: projects || [],
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    state: {
      sorting,
    },
  })

  return (
    <>
      {isLoading && (
        <Tr>
          <Td />
          <Td colSpan={6}>
            <Stack>
              <Skeleton height="20px" />
            </Stack>
          </Td>
        </Tr>
      )}
      {!isLoading &&
        table.getHeaderGroups().map((headerGroup) => (
          <Tr key={`project-${headerGroup.id}`} display={isExpanded ? 'table-row' : 'none'}>
            {headerGroup.headers.map((header) => (
              <Th
                key={header.id}
                onClick={header.column.getToggleSortingHandler()}
                style={header.column.columnDef.meta?.style}
                cursor={header.column.getCanSort() ? 'pointer' : ''}
              >
                {flexRender(header.column.columnDef.header, header.getContext())}

                {/* eslint-disable-next-line no-nested-ternary */}
                {header.column.getIsSorted() ? (
                  header.column.getIsSorted() === 'desc' ? (
                    <TriangleDownIcon aria-label="sorted descending" />
                  ) : (
                    <TriangleUpIcon aria-label="sorted ascending" />
                  )
                ) : null}
              </Th>
            ))}
          </Tr>
        ))}
      {table.getRowModel().rows.map((row) => (
        <Tr key={row.id} display={isExpanded ? 'table-row' : 'none'}>
          {row.getVisibleCells().map((cell) => (
            <Td
              key={cell.id}
              style={cell.column.columnDef.meta?.style}
              height="40px"
              wordBreak="break-word"
              whiteSpace="normal"
            >
              {flexRender(cell.column.columnDef.cell, cell.getContext())}
            </Td>
          ))}
        </Tr>
      ))}
      {isExpanded && isAllowedModify && (
        <Tr>
          <Td borderBottomColor="secondary.300" />
          <Td borderBottomColor="secondary.300">
            <Button
              leftIcon={<AddIcon />}
              colorScheme="primary"
              variant="ghost"
              size="sm"
              onClick={() => onCreateProject()}
            >
              {t('components.project_row.create_inspection_area', {
                ns: 'dashboard',
              })}
            </Button>
          </Td>
          <Td borderBottomColor="secondary.300" />
          <Td borderBottomColor="secondary.300" />
          <Td borderBottomColor="secondary.300" />
          <Td borderBottomColor="secondary.300" />
          <Td borderBottomColor="secondary.300" />
        </Tr>
      )}
    </>
  )
}

export default ProjectRow
