import { FC, useCallback, useContext, useEffect, useState } from 'react'

import { TriangleDownIcon, TriangleUpIcon } from '@chakra-ui/icons'
import { Box, Table, TableContainer, Tbody, Text, Th, Thead, Tr, useToast } from '@chakra-ui/react'
import {
  Table as ReactTable,
  SortingState,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table'
import { useCookies } from 'react-cookie'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

import { ProjectsContext } from 'contexts/Projects'
import { UserContext } from 'contexts/Users'

import { SELECTED_ORGANIZATION_COOKIE_NAME, USER_TYPES } from 'config/constants'
import { TOAST_CONFIG } from 'config/styles'

import { Project, ProjectGroup } from 'interfaces/interfaces'

import { decideActionPermission } from 'services/Validation'

import CreateProjectModal from './components/CreateProjectModal'
import InviteUserModal from './components/InviteUserModal'
import ProjectsTableRow from './components/ProjectGroupRow'
import UpdateProjectNameModal from './components/UpdateProjectNameModal'

const ProjectsTable: FC = () => {
  const { t } = useTranslation(['dashboard'])
  const toast = useToast()
  const { search } = useLocation()

  const parameters = new URLSearchParams(search)
  const user_id = parameters.get('user_id')

  const { projectGroups, invitedProjectGroups, projectGroupsLoaded, getProjectGroups } = useContext(ProjectsContext)
  const { userType, userTypeForOrganizations } = useContext(UserContext)
  const [cookies] = useCookies([SELECTED_ORGANIZATION_COOKIE_NAME])
  const [invitingProjectGroup, setInvitingProjectGroup] = useState<ProjectGroup>()
  const [projectGroupForCreatingProject, setProjectGroupForCreatingProject] = useState<ProjectGroup>()
  const [projectGroupForUpdatingProject, setProjectGroupForUpdatingProject] = useState<ProjectGroup>()
  const [editingNameProject, setEditingNameProject] =
    useState<{ projectId: string; projectName: string; forProjectGroup: boolean }>()
  const [updatingFileProject, setUpdatingFileProject] = useState<Project>()
  const [projectGroupsByUser, setProjectGroupsByUser] = useState<ProjectGroup[]>([])
  const [invitedProjectGroupsByUser, setInvitedProjectGroupsByUser] = useState<ProjectGroup[]>([])

  const fetchProjectGroups = useCallback(async () => {
    if (user_id && userType === USER_TYPES.ADMIN) {
      const projectResult = await getProjectGroups(user_id)
      if (projectResult) {
        setProjectGroupsByUser(projectResult.projectGroups)
        setInvitedProjectGroupsByUser(projectResult.invitedProjectGroups)
      }
    } else {
      setProjectGroupsByUser([])
      setInvitedProjectGroupsByUser([])
    }
  }, [getProjectGroups, user_id, userType])

  useEffect(() => {
    void fetchProjectGroups()
  }, [fetchProjectGroups, user_id])

  const onProjectUpdated = async (result?: boolean) => {
    setUpdatingFileProject(undefined)
    setProjectGroupForUpdatingProject(undefined)
    if (result) {
      // Refetch project groups also refetch projects inside expanding project groups
      await getProjectGroups()
      toast({
        ...TOAST_CONFIG,
        title: t('projects_table.inspection_area_changed', {
          ns: 'dashboard',
        }),
      })
    }
  }

  const onProjectCreated = async (result?: boolean) => {
    setProjectGroupForCreatingProject(undefined)
    if (result) {
      // Refetch project groups also refetch projects inside expanding project groups
      await getProjectGroups()
      toast({
        ...TOAST_CONFIG,
        title: t('projects_table.inspection_area_created', {
          ns: 'dashboard',
        }),
      })
    }
  }

  const columnHelper = createColumnHelper<ProjectGroup>()
  const columns = [
    columnHelper.display({ id: 'blank', meta: { style: { width: '20px' } } }),
    columnHelper.accessor('project_group_name', {
      cell: (info) => info.getValue(),
      header: t('projects_table.name', {
        ns: 'dashboard',
      }),
      meta: {
        style: {
          textAlign: 'left',
        },
      },
    }),
    columnHelper.accessor('total_size', {
      cell: (info) => info.getValue(),
      header: t('projects_table.size', {
        ns: 'dashboard',
      }),
      meta: {
        style: {
          textAlign: 'right',
          width: '10%',
        },
      },
    }),
    columnHelper.display({
      id: 'shared_user',
      cell: (info) => info.getValue(),
      header: t('projects_table.invitee', {
        ns: 'dashboard',
      }),
      meta: {
        style: {
          width: '10%',
        },
      },
    }),
    columnHelper.accessor('updated_at', {
      cell: (info) => info.getValue(),
      header: t('projects_table.update_date', {
        ns: 'dashboard',
      }),
      meta: {
        style: {
          textAlign: 'right',
          width: '10%',
        },
      },
    }),
    columnHelper.accessor('created_at', {
      cell: (info) => info.getValue(),
      header: t('projects_table.create_date', {
        ns: 'dashboard',
      }),
      meta: {
        style: {
          textAlign: 'right',
          width: '10%',
        },
      },
    }),
    columnHelper.accessor('creator_email_address', {
      cell: (info) => info.getValue(),
      header: t('projects_table.creator', {
        ns: 'dashboard',
      }),
      meta: {
        style: {
          width: '10%',
        },
      },
    }),
    columnHelper.display({ id: 'actions', meta: { style: { width: '1%' } } }),
  ]
  const [sortingProjects, setSortingProjects] = useState<SortingState>([
    {
      id: 'updated_at',
      desc: true,
    },
  ])
  const projectGroupTable = useReactTable({
    columns,
    data: projectGroups || [],
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSortingProjects,
    getSortedRowModel: getSortedRowModel(),
    state: {
      sorting: sortingProjects,
    },
  })

  const [sortingInvitedProjects, setSortingInvitedProjects] = useState<SortingState>([
    {
      id: 'updated_at',
      desc: true,
    },
  ])
  const invitedProjectGroupTable = useReactTable({
    columns,
    data: invitedProjectGroups || [],
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSortingInvitedProjects,
    getSortedRowModel: getSortedRowModel(),
    state: {
      sorting: sortingInvitedProjects,
    },
  })

  if (!projectGroupsLoaded) {
    return (
      <p>
        {t('projects_table.loading', {
          ns: 'dashboard',
        })}
      </p>
    )
  }

  const renderGroupHeader = (header: string) => (
    <Text fontWeight="bold" mb={4} color="secondary.400">
      {header}
    </Text>
  )

  const renderProjectsTable = (table: ReactTable<ProjectGroup>, isOwner: boolean) => {
    if (!table) {
      return <Box height={4} />
    }

    // userTypeForOrganizations should be used if the user belongs to any organization.
    let userTypeForPermission = userType
    if (cookies) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const organizationId = cookies[SELECTED_ORGANIZATION_COOKIE_NAME]
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      userTypeForPermission = userTypeForOrganizations[organizationId] || userType
    }
    const isAllowedModify = decideActionPermission(userTypeForPermission, isOwner).PROJECT_DASHBOARD.MODIFY

    return (
      <TableContainer mb={10}>
        <Table w="100%" variant="simple" size="sm" data-testid={isOwner ? 'owner-table' : 'invited-table'}>
          <Thead fontSize="sm">
            {table.getHeaderGroups().map((headerGroup) => (
              <Tr key={`project-group-${headerGroup.id}`}>
                {headerGroup.headers.map((header) => (
                  <Th
                    key={header.id}
                    onClick={header.column.getToggleSortingHandler()}
                    style={header.column.columnDef.meta?.style}
                    cursor={header.column.getCanSort() ? 'pointer' : ''}
                  >
                    {flexRender(header.column.columnDef.header, header.getContext())}

                    {/* eslint-disable-next-line no-nested-ternary */}
                    {header.column.getIsSorted() ? (
                      header.column.getIsSorted() === 'desc' ? (
                        <TriangleDownIcon aria-label="sorted descending" />
                      ) : (
                        <TriangleUpIcon aria-label="sorted ascending" />
                      )
                    ) : null}
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>
          <Tbody>
            {table
              .getRowModel()
              .rows.map((row) => row.original)
              .map((projectGroup: ProjectGroup) => (
                <ProjectsTableRow
                  key={projectGroup.project_group_id}
                  projectGroup={projectGroup}
                  isAllowedModify={isAllowedModify && !user_id}
                  setInvitingProjectGroup={setInvitingProjectGroup}
                  setEditingNameProjectGroup={(editingProjectGroup) =>
                    setEditingNameProject({
                      projectName: editingProjectGroup.project_group_name,
                      projectId: editingProjectGroup.project_group_id,
                      forProjectGroup: true,
                    })
                  }
                  setEditingNameProject={(editingProject) =>
                    setEditingNameProject({
                      projectName: editingProject.project_name,
                      projectId: editingProject.project_id,
                      forProjectGroup: false,
                    })
                  }
                  setUpdatingFileProject={(project) => {
                    setUpdatingFileProject(project)
                    setProjectGroupForUpdatingProject(projectGroup)
                  }}
                  onCreateProject={() => setProjectGroupForCreatingProject(projectGroup)}
                />
              ))}
          </Tbody>
        </Table>
      </TableContainer>
    )
  }

  const displayProjectGroups = user_id ? projectGroupsByUser : projectGroups
  const displayInvitedProjectGroups = user_id ? invitedProjectGroupsByUser : invitedProjectGroups

  return (
    <>
      {renderGroupHeader(
        `${t('projects_table.created_project', {
          ns: 'dashboard',
        })}${!displayProjectGroups.length ? ' (0)' : ''}`
      )}
      {renderProjectsTable(projectGroupTable, true)}
      {renderGroupHeader(
        `${t('projects_table.invited_project', {
          ns: 'dashboard',
        })}${!displayInvitedProjectGroups.length ? ' (0)' : ''}`
      )}
      {renderProjectsTable(invitedProjectGroupTable, false)}
      <InviteUserModal
        isOpen={!!invitingProjectGroup}
        invitingProjectGroup={invitingProjectGroup}
        onConfirm={() => setInvitingProjectGroup(undefined)}
      />
      <UpdateProjectNameModal
        isOpen={!!editingNameProject}
        targetId={editingNameProject?.projectId || ''}
        currentName={editingNameProject?.projectName || ''}
        targetType={editingNameProject?.forProjectGroup ? 'projectGroup' : 'project'}
        onConfirm={() => setEditingNameProject(undefined)}
      />
      {/* Update file in project */}
      <CreateProjectModal
        isOpen={!!updatingFileProject && !!projectGroupForUpdatingProject}
        onConfirm={onProjectUpdated}
        selectedProject={updatingFileProject}
        projectGroup={projectGroupForUpdatingProject}
      />
      {/* Create project */}
      <CreateProjectModal
        projectGroup={projectGroupForCreatingProject}
        isOpen={!!projectGroupForCreatingProject}
        onConfirm={onProjectCreated}
      />
    </>
  )
}

export default ProjectsTable
