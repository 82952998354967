/* eslint-disable @typescript-eslint/no-explicit-any */

/* eslint-disable @typescript-eslint/no-unsafe-assignment */

/* eslint-disable react/no-array-index-key */
import { FC, Fragment, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'

import { Box, Button, Flex, HStack, IconButton, Image, Text, Tooltip, VStack } from '@chakra-ui/react'
import { ArcballControls, PerspectiveCamera } from '@react-three/drei'
import { Canvas, RootState, ThreeEvent } from '@react-three/fiber'
import mixpanel from 'mixpanel-browser'
import AppTitle from 'pages/dashboard/components/AppTitle'
import { useCookies } from 'react-cookie'
import { isTablet } from 'react-device-detect'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import {
  AxesHelper,
  BufferGeometry,
  Cache,
  Camera,
  Group,
  LineSegments,
  Matrix4,
  MeshPhongMaterial,
  NoToneMapping,
  Points,
  Vector3,
} from 'three'
import { ArcballControls as ArcballControlsImpl } from 'three-stdlib'
import { PCDLoader } from 'three/examples/jsm/loaders/PCDLoader'

import { CancelIcon, CheckCircleIcon, UndoIcon } from 'assets/icons'
import LoadingAnimation from 'assets/imgs/loading-animation-black.gif'

import { GlobalModalContext } from 'contexts/GlobalModal'
import { IFCRegisterContext } from 'contexts/IFCRegister'
import { ProjectsContext } from 'contexts/Projects'
import { UserContext } from 'contexts/Users'

import usePointScale from 'hooks/PointScale'

import {
  DEFAULT_BLUEPRINT_TOOL,
  EDITOR_BACKGROUND_COOKIE_NAME,
  IFC_REGISTER_TOOLS,
  THRESHOLD_REGISTRATION_ERROR,
  Z_INDEX,
} from 'config/constants'
import { EDITOR_DEFAULT_BACKGROUND } from 'config/styles'

import { IFCFile, PointArray, Project } from 'interfaces/interfaces'

import { ERROR_PROCESS, processErrorHandler } from 'services/ErrorHandler'
import { convertIFCToMeshes, getIFCFiles } from 'services/IFC'
import { getSelectedPointOnMesh, getSelectedPointOnPCD } from 'services/PointPicker'
import { computeTransformFromPairedPoints, isValidRegistration, pointsToVector3s } from 'services/Points'
import { getProject, getSignedUrlForGetDownSampledFile, updateProjectIFC } from 'services/Projects'
import { millimeterToMeter } from 'services/Util'
import { decideActionPermission } from 'services/Validation'

import TopNav, { NAV_TYPES } from '../components/TopNav'
import FileListButton from './FileListButton'
import InstructionBar from './InstructionBar'

Cache.enabled = true

const POINT_COLORS = ['red', '#00cc00', '#0077ff']

const PointMeshes: FC<{ points: PointArray[]; arcballControls?: ArcballControlsImpl }> = ({
  points,
  arcballControls,
}) => {
  const { scale } = usePointScale(arcballControls)

  return (
    <>
      {points.map((point, index) => (
        <Fragment key={index}>
          <mesh position={new Vector3(...point)} scale={scale} renderOrder={1}>
            <sphereGeometry args={[0.0035, 32, 16]} />
            <meshStandardMaterial color="white" depthTest={false} depthWrite={false} transparent />
          </mesh>
          <mesh position={new Vector3(...point)} scale={scale} renderOrder={1}>
            <sphereGeometry args={[0.0025, 32, 16]} />
            <meshStandardMaterial color={POINT_COLORS[index]} depthTest={false} depthWrite={false} transparent />
          </mesh>
        </Fragment>
      ))}
    </>
  )
}

const IFCRegister: FC = () => {
  const { project_id } = useParams<{ project_id: string }>()
  const { userLoaded, userType, userTypeForOrganizations, organizations, getAccessToken } = useContext(UserContext)
  const { projectGroupsLoaded, projectGroups, invitedProjectGroups } = useContext(ProjectsContext)
  const { showErrorModal } = useContext(GlobalModalContext)
  const { t } = useTranslation(['projects'])
  const [selectedTool, setSelectedTool] = useState(DEFAULT_BLUEPRINT_TOOL)
  const [cookies] = useCookies([EDITOR_BACKGROUND_COOKIE_NAME])
  const navigate = useNavigate()

  const backgroundColor =
    (cookies as Record<string, string>)[EDITOR_BACKGROUND_COOKIE_NAME] || EDITOR_DEFAULT_BACKGROUND

  const [project, setProject] = useState<Project | null>(null)
  const [pointCloud, setPointCloud] = useState<Points>()
  const [pointsLoadingText, setPointCloudLoadingText] = useState<string>('Loading...')
  const [pcdPoints, setPCDPoints] = useState<PointArray[]>([])
  const [ifcPoints, setIFCPoints] = useState<PointArray[]>([])
  const [isLoading, setIsLoading] = useState(true)
  const [isRegistering, setIsRegistering] = useState(false)
  const [isShowingResult, setIsShowingResult] = useState(false)
  const [selectedIFCFile, setSelectedIFCFile] = useState<IFCFile | null>(null)
  const [IFCFiles, setIFCFiles] = useState<IFCFile[] | null>()
  //* Smooth mouse movement
  const [isMouseDown, setIsMouseDown] = useState(false)
  const [isMouseDragging, setIsMouseDragging] = useState(false)

  const [alignedTransform, setAlignedTransform] = useState<Matrix4 | null>(null)

  const [allMeshes, setAllMeshes] = useState<{
    meshArray: { combinedGeometry: BufferGeometry; mat: MeshPhongMaterial }[]
    transMeshArray: { combinedGeometryTransp: BufferGeometry; matTransp: MeshPhongMaterial }[]
  }>({ meshArray: [], transMeshArray: [] })

  const ifcRef = useRef<LineSegments>()
  const transformedIFCGroupRef = useRef<Group>()
  const pcdCameraRef = useRef<Camera>()
  const ifcCameraRef = useRef<Camera>()
  const pcdArcballRef = useRef<ArcballControlsImpl>()
  const ifcArcballRef = useRef<ArcballControlsImpl>()

  const projectGroup = projectGroups
    .concat(invitedProjectGroups)
    .find((proj) => proj.project_group_id === project?.project_group_id)

  // Check if the project is owned by any organization the user belongs to
  const orgOwningProject = organizations.find((org) => org.organization_id === projectGroup?.organization_id)
  const isOwner = orgOwningProject !== undefined
  // userTypeForOrganizations should be used if the user belongs to the organization owning the project.
  // otherwise, use userType.
  let userTypeForPermission = userType
  if (isOwner) {
    const organizationId = orgOwningProject.organization_id
    userTypeForPermission = userTypeForOrganizations[organizationId]
  }

  const isAllowedModify = useMemo(
    () => decideActionPermission(userTypeForPermission, isOwner).IFC_REGISTER.MODIFY,
    [isOwner, userTypeForPermission]
  )

  const fetchData = useCallback(async () => {
    if (!projectGroup?.project_group_id) {
      return Promise.resolve()
    }

    setIsLoading(true)

    const token = await getAccessToken()
    if (!token) return Promise.resolve()

    const results = await getIFCFiles(token, projectGroup.project_group_id, showErrorModal)
    if (results) setIFCFiles(results)

    setIsLoading(false)

    return Promise.resolve()
  }, [getAccessToken, projectGroup, showErrorModal])

  useEffect(() => {
    void fetchData()
  }, [fetchData])

  const onMouseDown = () => {
    setIsMouseDown(true)
    setIsMouseDragging(false)
  }

  const onMoveMouse = () => {
    if (isMouseDown) {
      setIsMouseDragging(true)
    }
  }

  const onPCDMouseUp = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (isMouseDragging || pcdPoints.length === 3) {
      return
    }
    const selectedPoint = getSelectedPointOnPCD(
      document.getElementById('pcdCanvasBox'),
      pcdCameraRef.current,
      pointCloud,
      undefined,
      e
    )
    if (selectedPoint) {
      setPCDPoints((prev) => [...prev, selectedPoint])
    }
    setIsMouseDown(false)
    setIsMouseDragging(false)
  }

  const onIFCMouseUp = (e: ThreeEvent<PointerEvent>) => {
    if (isMouseDragging || ifcPoints.length === 3) {
      return
    }
    const selectedPoint = getSelectedPointOnMesh(e.sourceEvent.target as HTMLElement, ifcCameraRef.current, e)
    if (selectedPoint) {
      setIFCPoints((prev) => [...prev, selectedPoint])
    }
    setIsMouseDown(false)
    setIsMouseDragging(false)
  }

  useEffect(() => {
    if (userLoaded && projectGroupsLoaded && project_id && project === null) {
      void (async () => {
        const token = await getAccessToken()
        if (!token) {
          return false
        }

        const requestedProject = await getProject(token, project_id, showErrorModal)
        setProject(requestedProject)

        return false
      })()
    }
  }, [getAccessToken, project_id, projectGroupsLoaded, showErrorModal, userLoaded, project])

  useEffect(() => {
    void (async () => {
      if (project_id && !pointCloud) {
        if (userLoaded && !Cache.get(project_id)) {
          const token = await getAccessToken()
          if (!token) {
            return false
          }

          const url = await getSignedUrlForGetDownSampledFile(token, project_id, showErrorModal)
          if (!url) {
            return false
          }

          try {
            const loader = new PCDLoader()
            const loadPoints = await loader.loadAsync(url, (e) => {
              const loadingPercent = (e.loaded / e.total) * 100

              if (loadingPercent === 100) {
                setPointCloudLoadingText(`Loading...`)
              } else {
                setPointCloudLoadingText(`Download...${Math.floor((loadingPercent * 10) / 10)}%`)
              }
            })

            Cache.add(project_id, loadPoints)
            setPointCloud(loadPoints)

            return true
          } catch (err) {
            return processErrorHandler(err, ERROR_PROCESS.GET_DOWN_SAMPLED_FILE, showErrorModal)
          }
        }

        if (Cache.get(project_id)) {
          //* すでにダウンロード済みの場合、キャッシュから点群表示
          const loadPoints = Cache.get(project_id) as Points
          setPointCloud(loadPoints)
        }
      }
      return {}
    })()
  }, [getAccessToken, pointCloud, project_id, userLoaded, showErrorModal])

  useEffect(() => {
    void (async () => {
      if (!selectedIFCFile?.original_file_url || allMeshes.meshArray.length || allMeshes.transMeshArray.length) {
        return
      }

      setAllMeshes(await convertIFCToMeshes(selectedIFCFile.original_file_url))
    })()
  }, [allMeshes, selectedIFCFile])

  useEffect(() => {
    if (!allMeshes.meshArray.length && !allMeshes.transMeshArray.length) {
      return
    }
    setTimeout(() => {
      ifcCameraRef.current?.position.set(
        (ifcRef.current?.geometry?.boundingSphere?.center.x || 0) +
          (ifcRef.current?.geometry?.boundingSphere?.radius || 1),
        (ifcRef.current?.geometry?.boundingSphere?.center.y || 0) +
          (ifcRef.current?.geometry?.boundingSphere?.radius || 1),
        (ifcRef.current?.geometry?.boundingSphere?.center.z || 0) + 1
      )
      const target = ifcRef.current?.geometry.boundingSphere?.center || new Vector3()
      ifcArcballRef.current?.setTarget(target.x, target.y, target.z)
    }, 500)

    setSelectedTool(IFC_REGISTER_TOOLS.REGISTER)
  }, [allMeshes])

  const changeTool = useCallback((tool: string) => {
    setSelectedTool(tool)
  }, [])

  const contextValue = useMemo(
    () => ({
      selectedTool,
      changeTool,
      pcdPoints,
      ifcPoints,
    }),
    [selectedTool, changeTool, pcdPoints, ifcPoints]
  )

  useEffect(() => {
    if (isShowingResult && alignedTransform && transformedIFCGroupRef.current) {
      transformedIFCGroupRef.current.applyMatrix4(alignedTransform.clone().invert())
    }
  }, [isShowingResult, alignedTransform])

  const onAlign = () => {
    const pcdVectors = pointsToVector3s(pcdPoints)
    const ifcVectors = pointsToVector3s(ifcPoints)
    const transform = computeTransformFromPairedPoints(pointsToVector3s(pcdPoints), pointsToVector3s(ifcPoints))

    if (isValidRegistration(pcdVectors, ifcVectors, transform, millimeterToMeter(THRESHOLD_REGISTRATION_ERROR))) {
      setAlignedTransform(transform)
      setIsShowingResult(true)

      // track with mixpanel
      mixpanel.track('Finish registering pcd with ifc file (with no error)', {
        'Project ID': projectGroup?.project_group_id,
        'Inspection area ID': project?.project_id,
      })
    } else {
      // track with mixpanel
      mixpanel.track('Finish registering pcd with ifc file (with registration error)', {
        'Project ID': projectGroup?.project_group_id,
        'Inspection area ID': project?.project_id,
      })

      showErrorModal(t('ifc_registration.registration.error_message', { ns: 'projects' }))
    }
  }

  const onRegister = async () => {
    if (!project?.project_id || !selectedIFCFile || !alignedTransform) {
      return false
    }

    setIsRegistering(true)

    const token = await getAccessToken()
    if (!token) {
      return false
    }

    // transpose the transform matrix to match the backend convention
    if (await updateProjectIFC(token, project.project_id, alignedTransform.transpose().toArray(), showErrorModal)) {
      // track with mixpanel
      mixpanel.track('Confirm registering pcd with ifc file', {
        'Project ID': projectGroup?.project_group_id,
        'Inspection area ID': project.project_id,
      })

      navigate(`/projects/${project_id || ''}`)
    } else {
      setIsRegistering(false)
    }

    return true
  }

  return (
    <>
      <AppTitle project={project} projectGroup={projectGroup} />
      <IFCRegisterContext.Provider value={contextValue}>
        <Flex position="relative" onMouseDown={onMouseDown} onMouseMove={onMoveMouse} onTouchMove={onMoveMouse}>
          <Box
            w={isShowingResult ? '100svw' : '50svw'}
            h="100svh"
            id="pcdCanvasBox"
            onMouseUp={onPCDMouseUp}
            position="relative"
          >
            {pointCloud && (
              <Canvas
                style={{ width: isShowingResult ? '100svw' : '50svw', height: '100svh' }}
                onCreated={(state: RootState) => {
                  // eslint-disable-next-line no-param-reassign
                  state.gl.toneMapping = NoToneMapping
                  // eslint-disable-next-line no-param-reassign
                  state.gl.outputEncoding = -1
                }}
              >
                {pointCloud && (
                  <group>
                    <primitive object={pointCloud} />
                  </group>
                )}
                {isShowingResult && (
                  <group ref={transformedIFCGroupRef}>
                    {allMeshes.meshArray.map(({ combinedGeometry, mat }, index) => (
                      <mesh ref={ifcRef} args={[combinedGeometry, mat]} key={index} onPointerUp={onIFCMouseUp} />
                    ))}
                    {allMeshes.transMeshArray.map(({ combinedGeometryTransp, matTransp }, index) => (
                      <mesh args={[combinedGeometryTransp, matTransp]} key={index} />
                    ))}
                    <PointMeshes points={ifcPoints} arcballControls={pcdArcballRef.current} />
                  </group>
                )}
                {pointCloud && (
                  <>
                    {/* Camera */}
                    <PerspectiveCamera
                      makeDefault
                      fov={90}
                      near={0.01}
                      far={10000}
                      position={[
                        (pointCloud?.geometry?.boundingSphere?.center.x || 0) +
                          (pointCloud?.geometry?.boundingSphere?.radius || 1),
                        (pointCloud?.geometry?.boundingSphere?.center.y || 0) +
                          (pointCloud?.geometry?.boundingSphere?.radius || 1),
                        (pointCloud?.geometry?.boundingSphere?.center.z || 0) + 1,
                      ]}
                      ref={pcdCameraRef}
                    />
                    {/* Controls */}
                    <ArcballControls
                      makeDefault
                      target={pointCloud?.geometry.boundingSphere?.center || [0, 0, 0]}
                      enableAnimations={false}
                      cursorZoom
                      ref={pcdArcballRef as any}
                    />
                  </>
                )}
                <PointMeshes points={pcdPoints} arcballControls={pcdArcballRef.current} />
                <primitive
                  object={new AxesHelper(0.2)}
                  position={[
                    (pointCloud?.geometry?.boundingSphere?.center.x || 0) -
                      (pointCloud?.geometry?.boundingSphere?.radius || 1),
                    (pointCloud?.geometry?.boundingSphere?.center.y || 0) +
                      (pointCloud?.geometry?.boundingSphere?.radius || 1),
                    (pointCloud?.geometry?.boundingSphere?.center.z || 0) -
                      (pointCloud?.geometry?.boundingSphere?.radius || 1),
                  ]}
                />
                <color attach="background" args={[backgroundColor]} />
                <ambientLight />
              </Canvas>
            )}
            {!pointCloud && (
              <Box
                w="100%"
                h="100%"
                position="absolute"
                top={0}
                left={0}
                backgroundColor="black"
                zIndex={Z_INDEX.ifc_register.loading_pcd}
              >
                <VStack position="absolute" left="50%" top="50%" transform="translate(-50%, -50%)" alignItems="center">
                  <Image src={LoadingAnimation} w={160} />
                  <Text color="whiteAlpha.700" fontWeight="bold">
                    {pointsLoadingText}
                  </Text>
                </VStack>
              </Box>
            )}
            {!isShowingResult && (
              <Box position="absolute" bottom={1} left={1} userSelect="none">
                <Tooltip
                  hasArrow
                  placement="right"
                  label={t('ifc_registration.registration.tooltip_reselect_points', { ns: 'projects' })}
                  p={2}
                  fontSize="xs"
                  fontWeight="normal"
                  isDisabled={isTablet}
                >
                  <IconButton
                    aria-label="undo"
                    backgroundColor="gray.700"
                    color="white"
                    fontSize="xl"
                    size={isTablet ? 'lg' : 'sm'}
                    variant="toolbarIcon"
                    icon={<UndoIcon width="50%" height="50%" />}
                    onClick={() => {
                      setPCDPoints((prev) => prev.slice(0, -1))
                    }}
                    disabled={!pcdPoints.length}
                  />
                </Tooltip>
              </Box>
            )}
          </Box>
          {!isShowingResult && (
            <>
              <Box w="50svw" h="100svh" id="ifcCanvasBox" position="relative">
                {(allMeshes.meshArray.length || allMeshes.transMeshArray.length) && (
                  <Canvas
                    style={{ width: '50svw', height: '100svh' }}
                    onCreated={(state: RootState) => {
                      // eslint-disable-next-line no-param-reassign
                      state.gl.toneMapping = NoToneMapping
                      // eslint-disable-next-line no-param-reassign
                      state.gl.outputEncoding = -1
                    }}
                  >
                    {allMeshes.meshArray.map(({ combinedGeometry, mat }, index) => (
                      <mesh ref={ifcRef} args={[combinedGeometry, mat]} key={index} onPointerUp={onIFCMouseUp} />
                    ))}
                    {allMeshes.transMeshArray.map(({ combinedGeometryTransp, matTransp }, index) => (
                      <mesh args={[combinedGeometryTransp, matTransp]} key={index} />
                    ))}
                    <PointMeshes points={ifcPoints} arcballControls={ifcArcballRef.current} />
                    <primitive
                      object={new AxesHelper(0.2)}
                      position={[
                        (ifcRef.current?.geometry?.boundingSphere?.center.x || 0) -
                          (ifcRef.current?.geometry?.boundingSphere?.radius || 1),
                        (ifcRef.current?.geometry?.boundingSphere?.center.y || 0) +
                          (ifcRef.current?.geometry?.boundingSphere?.radius || 1),
                        (ifcRef.current?.geometry?.boundingSphere?.center.z || 0) -
                          (ifcRef.current?.geometry?.boundingSphere?.radius || 1),
                      ]}
                    />
                    {/* Camera */}
                    <PerspectiveCamera
                      makeDefault
                      fov={90}
                      near={0.01}
                      far={10000}
                      position={[
                        (ifcRef.current?.geometry?.boundingSphere?.center.x || 0) +
                          (ifcRef.current?.geometry?.boundingSphere?.radius || 1),
                        (ifcRef.current?.geometry?.boundingSphere?.center.y || 0) +
                          (ifcRef.current?.geometry?.boundingSphere?.radius || 1),
                        (ifcRef.current?.geometry?.boundingSphere?.center.z || 0) + 1,
                      ]}
                      ref={ifcCameraRef}
                    />
                    {/* Controls */}
                    <ArcballControls
                      makeDefault
                      target={ifcRef.current?.geometry.boundingSphere?.center || [0, 0, 0]}
                      enableAnimations={false}
                      cursorZoom
                      ref={ifcArcballRef as any}
                    />
                    <color attach="background" args={[backgroundColor]} />
                    <ambientLight />
                  </Canvas>
                )}
                {!selectedIFCFile && !isLoading && (
                  <FileListButton
                    ifcFiles={IFCFiles || []}
                    selectedIFCFile={selectedIFCFile}
                    setSelectedIFCFile={setSelectedIFCFile}
                    onFileChanged={async () => {
                      await fetchData()
                      setSelectedIFCFile(null)
                    }}
                    isAllowedModify={isAllowedModify}
                    projectGroup={projectGroup}
                    backgroundColor={backgroundColor}
                  />
                )}
                <Box position="absolute" bottom={1} right={1} userSelect="none">
                  <Tooltip
                    hasArrow
                    placement="left"
                    label={t('ifc_registration.registration.tooltip_reselect_points', { ns: 'projects' })}
                    p={2}
                    fontSize="xs"
                    fontWeight="normal"
                    isDisabled={isTablet}
                  >
                    <IconButton
                      aria-label="undo"
                      backgroundColor="gray.700"
                      color="white"
                      fontSize="xl"
                      size={isTablet ? 'lg' : 'sm'}
                      variant="toolbarIcon"
                      icon={<UndoIcon width="50%" height="50%" />}
                      onClick={() => {
                        setIFCPoints((prev) => prev.slice(0, -1))
                      }}
                      disabled={!ifcPoints.length}
                    />
                  </Tooltip>
                </Box>
                {!allMeshes.meshArray.length && !allMeshes.transMeshArray.length && (
                  <Box
                    w="100%"
                    h="100%"
                    position="absolute"
                    top={0}
                    left={0}
                    backgroundColor="black"
                    zIndex={Z_INDEX.ifc_register.loading_ifc}
                  >
                    <VStack
                      position="absolute"
                      left="50%"
                      top="50%"
                      transform="translate(-50%, -50%)"
                      alignItems="center"
                    >
                      <Image src={LoadingAnimation} w={160} />
                      <Text color="whiteAlpha.700" fontWeight="bold">
                        Loading...
                      </Text>
                    </VStack>
                  </Box>
                )}
              </Box>
              <Box
                backgroundColor="white"
                width="1px"
                height="100%"
                position="absolute"
                left="50%"
                zIndex={Z_INDEX.ifc_register.box_ifc}
              />
            </>
          )}
          {!isShowingResult && (
            <Box
              position="absolute"
              bottom={1}
              left="50%"
              transform="translate(-50%, 0)"
              zIndex={Z_INDEX.ifc_register.register_button}
              backgroundColor={backgroundColor}
            >
              <Button
                colorScheme="secondary"
                rightIcon={<CheckCircleIcon width="100%" />}
                size={isTablet ? 'lg' : 'sm'}
                fontSize={isTablet ? 'lg' : 'xs'}
                variant="toolbar"
                onClick={onAlign}
                disabled={pcdPoints.length !== 3 || ifcPoints.length !== 3}
                spinnerPlacement="end"
                justifyContent="space-between"
              >
                {t('ifc_registration.registration.register', { ns: 'projects' })}
              </Button>
            </Box>
          )}
          {!!isShowingResult && (
            <HStack
              position="absolute"
              bottom={1}
              left="50%"
              transform="translate(-50%, 0)"
              zIndex={Z_INDEX.ifc_register.buttons_after_registration}
            >
              <Button
                colorScheme="secondary"
                rightIcon={<CancelIcon width="100%" />}
                size={isTablet ? 'lg' : 'sm'}
                fontSize={isTablet ? 'lg' : 'xs'}
                variant="toolbar"
                onClick={() => {
                  setIsShowingResult(false)

                  // track with mixpanel
                  mixpanel.track('Redo registering pcd with ifc file', {
                    'Project ID': projectGroup?.project_group_id,
                    'Inspection area ID': project?.project_id,
                  })
                }}
                justifyContent="space-between"
              >
                {t('ifc_registration.registration.redo', { ns: 'projects' })}
              </Button>
              <Button
                colorScheme="secondary"
                rightIcon={<CheckCircleIcon width="100%" />}
                size={isTablet ? 'lg' : 'sm'}
                fontSize={isTablet ? 'lg' : 'xs'}
                variant="toolbar"
                isLoading={isRegistering}
                loadingText={t('ifc_registration.registration.progressing', { ns: 'projects' })}
                justifyContent="space-between"
                onClick={onRegister}
              >
                {t('ifc_registration.registration.confirm', { ns: 'projects' })}
              </Button>
            </HStack>
          )}
          <TopNav type={NAV_TYPES.IFC_REGISTER} canModify={isAllowedModify} />
        </Flex>
        <InstructionBar />
      </IFCRegisterContext.Provider>
    </>
  )
}

export default IFCRegister
